import React, { memo } from 'react';
import {
  ModalBody,
  Modal,
  ModalTitle,
  ModalFooter,
  Button,
} from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import PropTypes from 'prop-types';

const DialogMessage = props => {
  const {
    show,
    title,
    message,
    primaryBtnClick,
    primaryBtnText,
    secondryBtnClick,
    secondryBtnText,
    cancelBtnClick,
    cancelBtnText,
  } = props;
  const messageParsed = message && message.split(`\n\r`);
  return (
    <Modal data-testid="DialogMessage" show={show} centered onHide = {cancelBtnClick}>
      <ModalHeader closeButton>
        <ModalTitle>{title}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        {messageParsed?.map(text => (
          <p key={text.substring(0, 10)}>{text}</p>
        ))}
      </ModalBody>
      <ModalFooter>
        <Button variant="primary" onClick={primaryBtnClick}>
          {primaryBtnText}
        </Button>
        {secondryBtnText && (
          <Button variant="light" onClick={secondryBtnClick}>
            {secondryBtnText}
          </Button>
        )}
        {cancelBtnText && (
          <Button variant="light" onClick={cancelBtnClick}>
            {cancelBtnText}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

DialogMessage.defaultProps = {
  secondryBtnText: '',
  secondryBtnClick: () => false,
  cancelBtnClick: () => false,
  cancelBtnText: '',
};

DialogMessage.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  primaryBtnClick: PropTypes.func.isRequired,
  primaryBtnText: PropTypes.string.isRequired,
  secondryBtnText: PropTypes.string,
  secondryBtnClick: PropTypes.func,
  cancelBtnClick: PropTypes.func,
  cancelBtnText: PropTypes.string,
};
export default memo(DialogMessage);
