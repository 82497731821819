/* eslint-disable no-nested-ternary */
import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _, { isEmpty, isNull, isUndefined } from 'lodash';
import moment from 'moment-timezone';
import QueryString from 'query-string';
import Cookies from 'universal-cookie';
import useGoogleAnalytics from 'hooks/useGoogleAnalytics';
import { createVisit } from 'api/visitApi';
import { getEndpoint, getHeaderInfo } from 'api/apiUtils';
import { getNextTime } from 'api/availableTimesApi';
import { camelCase } from 'utils/helpers';
import { useQueryString } from 'hooks/useQueryString';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { payLater } from 'api/patientIntakeApi';

export const RegistrationFormContext = createContext();

const RegistrationFormProvider = ({ children }) => {
  const cookies = new Cookies();
  const healthSystem = useSelector(state => state.healthSystem);
  const GA = useGoogleAnalytics();
  const { history, isDischargePage, urlParams } = useQueryString();
  const [t] = useTranslation();
  const [form, setForm] = useState({
    address: {
      value: '',
    },
    address2: {
      value: '',
    },
    birthdate: {
      value: null,
    },
    city: {
      value: '',
    },
    comments: {
      value: '',
    },
    contactName: {
      value: '',
    },
    customTerms: {
      text: '',
      value: false,
    },
    email: {
      value: '',
    },
    employerAuthorizer: {
      value: '',
    },
    employerCompanyName: {
      value: '',
    },
    employerOrPatient: {
      value: '',
    },
    firstName: {
      value: '',
    },
    gender: {
      value: '',
    },
    hasPhysician: {
      value: '',
    },
    height: {
      value: '',
    },
    insuranceGroupNumber: {
      value: '',
    },
    insuranceMemberNumber: {
      value: '',
    },
    insurancePlans: {
      value: '',
    },
    insuranceSubscriberBirthdate: {
      value: '',
    },
    insuranceSubscriberId: {
      value: '',
    },
    insuranceSubscriberName: {
      value: '',
    },
    lastName: {
      value: '',
    },
    lastTestDate: {
      value: '',
    },
    lastTestLocation: {
      value: '',
    },
    middleInitial: {
      value: '',
    },
    newPatient: {
      value: true,
    },
    payment: {
      value: '',
    },
    phoneNumber: {
      value: '',
    },
    pregnant: {
      value: false,
    },
    primaryCarePhysician: {
      value: '',
    },
    referringPhysicianName: {
      value: '',
    },
    requiresStandingAssistance: {
      value: '',
    },
    sourceSystem: {
      value: 'IQR',
    },
    weeksPregnant: {
      value: '',
    },
    weight: {
      value: '',
    },
    referringPhysician: {
      value: '',
    },
    reason: {
      value: '',
    },
    requiresTranslator: {
      value: '',
    },
    state: {
      value: '',
    },
    tocAgreeToReceive: {
      value: false,
    },
    tocTerms: {
      value: false,
    },
    tocNon911: {
      value: false,
    },
    translatorLanguage: {
      value: '',
    },
    zip: {
      value: '',
    },
    paymentTransactionId: {
      value: '',
    },
    paymentSource: {
      value: '',
    },
    paymentChoice: {
      value: '',
    },
    urlPermalink: {
      value: '',
    },
    'referring-facility': {
      value: '',
    },
    'ssn-last-4': {
      value: '',
    },
    'sms-notifications': {
      value: false,
    },
    couponCode: {
      value: '',
    },
    hasCouponCode: {
      value: false,
    },
  });
  const [enabledFields, setEnabledFields] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  const [requiredFields, setRequiredFields] = useState([]);
  const [screeningQuestions, setScreeningQuestions] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [copayInfo, setCopayInfo] = useState({});
  const [secureId, setSecureId] = useState("");
  const [secureToken, setSecureToken] = useState("");
  const [showCoPayDialog, setShowCoPayDialog] = useState(false);

  useEffect(() => {
    let totalValidFields = 0;
    const tempRequiredFields = requiredFields.map(field => camelCase(field));

    tempRequiredFields.forEach(valid => {
      if (
        form[valid] &&
        form[valid].value &&
        form[valid].value.toString().trim().length
      ) {
        totalValidFields += 1;
      }

      if (
        valid === 'heightAndWeight' &&
        form.height.value.trim().length &&
        form.weight.value.trim().length
      ) {
        totalValidFields += 1;
      }

      if (
        valid === 'insurancePlanName' &&
        form.insurancePlans.value.trim().length
      ) {
        totalValidFields += 1;
      }

      if (valid === 'patientComplaint' && form.reason.value.trim().length) {
        totalValidFields += 1;
      }

      if (valid === 'termsTos' && form.tocTerms.value) {
        totalValidFields += 1;
      }

      if (valid === 'terms-911' && form.tocNon911.value) {
        totalValidFields += 1;
      }

      if (
        valid === 'pregnant' &&
        (form.gender.value === 'male' ||
          (form.gender.value === 'female' && form.pregnant.value))
      ) {
        totalValidFields += 1;
      }

      if (
        valid === 'employerOrPatient' &&
        form.employerOrPatient.value.trim().length
      ) {
        totalValidFields += 1;
      }

      if (
        valid === 'employerAuthorizer' &&
        (form.employerOrPatient.value === 'patient' ||
          (form.employerOrPatient.value === 'employer' &&
            form.employerAuthorizer.value.trim().length))
      ) {
        totalValidFields += 1;
      }

      if (
        valid === 'employerCompanyName' &&
        (form.employerOrPatient.value === 'patient' ||
          (form.employerOrPatient.value === 'employer' &&
            form.employerCompanyName.value.trim().length))
      ) {
        totalValidFields += 1;
      }

      if (
        [
          'paymentChoice',
          'paymentTransactionId',
          'urlPermalink',
          'payment',
        ].includes(valid)
      ) {
        totalValidFields += 1;
      }
      if (['couponCode', 'hasCouponCode'].includes(valid)) {
        totalValidFields += 1;
      }
    });

    setIsFormValid(totalValidFields === tempRequiredFields.length);
  }, [requiredFields, form]);

  const locationHistory = QueryString.parse(history.location.search);
  const dateTime = locationHistory.date;

  const setTermsText = text => {
    const reg = /(?<first_bit>.*)\[(?<link_text>.*)\|(?<link_url>.*)\](?<last_bit>.*)/.exec(
      text,
    );

    if (!reg || !reg.groups) {
      return null;
    }

    return (
      <>
        {reg.groups.first_bit}
        <a href={reg.groups.link_url} target="_blank" rel="noopener noreferrer">
          {reg.groups.link_text}
        </a>
      </>
    );
  };

  const getVisitSettings = async (appointmentType, scheduleId) => {
    const response = await fetch(
      getEndpoint(`visit-settings/${scheduleId}`, {
        appointment_type_template_id: appointmentType.id,
      }),
      getHeaderInfo(),
    );
    const visitSettings = await response.json();

    if (!isEmpty(visitSettings)) {
      // 'appointment-at' and 'appointment-type' are required fields that
      // are not managed by Visit Settings, thus they are hardcoded into
      // the enabledFields state.
      let enabledFields = ['appointment-at', 'appointment-type'];
      let requiredFields = [];
      let screeningQuestionsObj = {};

      visitSettings.data.forEach(obj => {
        if (obj.type === 'custom-terms') {
          enabledFields.push('custom-terms');
          requiredFields.push('custom-terms');
          setForm({
            ...form,
            customTerms: {
              ...form.customTerms,
              text: setTermsText(obj.attributes.text),
            },
          });
        }
        const extraFields = [
          'payment-transaction-id',
          'source-system',
          'payment-choice',
          'url-permalink',
        ];
        if (obj.type === 'enabled-fields') {
          enabledFields = [
            ...enabledFields,
            ...obj.attributes.fields,
            ...extraFields,
          ];
        }

        if (obj.type === 'required-fields') {
          requiredFields = [
            ...requiredFields,
            ...obj.attributes.fields,
            ...extraFields,
          ];
        }

        if (obj.type === 'screening-questions') {
          screeningQuestionsObj = { ...obj.attributes.data };
        }
      });

      if (enabledFields.length) {
        setEnabledFields(enabledFields);
      }

      if (requiredFields.length) {
        setRequiredFields(requiredFields);
      }

      if (Object.keys(screeningQuestionsObj).length) {
        setScreeningQuestions(screeningQuestionsObj);
      }
    }
  };

  const isFieldEnabled = field => enabledFields.includes(field);
  const isFieldRequired = field => requiredFields.includes(field);
  const handleSetFormState = e => {
    setForm({
      ...form,
      ...e,
    });
  };

  const hasError = fieldName => {
    return formErrors.filter(e => e.title === fieldName).length > 0;
  };

  const getError = fieldName => {
    const errorObj = formErrors.find(obj => obj.title === fieldName);
    return errorObj && errorObj.message ? errorObj.message : '';
  };

  const getEnabledFormValues = formObject => {
    let enabledForm = {};

    // Check RegistrationForm.js for how enabled fields are set.
    enabledFields.forEach(field => {
      if (typeof formObject[field] !== 'undefined') {
        enabledForm = {
          ...enabledForm,
          [field]: formObject[field],
        };
      }
    });

    return enabledForm;
  };

  const sortFormObject = (
    appointmentTypeId,
    screeningQuestionsAnswers,
    paymentChoice,
    paymentTransactionId,
    urlPermalink,
    hasCouponCode,
    couponCode,
  ) => {
    /**
     * Notes:
     * - insurance-plan-name & insurance-plan-permalink are both the same value.
     *   - currently this is the permalink value
     *   - this is due to a limitation of how CustomSelect returns values
     */
    return {
      address: form.address.value,
      'appointment-at': dateTime,
      'appointment-type': appointmentTypeId,
      birthdate: form.birthdate.value,
      'caregiver-name': form.contactName.value,
      city: form.city.value,
      // 'custom-terms': form.customTerms.value ? 1 : 0,
      email: form.email.value,
      'employer-or-patient': form.employerOrPatient.value,
      'first-name': form.firstName.value,
      gender: form.gender.value,
      height: form.height.value,
      'has-physician': form.hasPhysician.value,
      'insurance-group-number': form.insuranceGroupNumber.value,
      'insurance-member-number': form.insuranceMemberNumber.value,
      'insurance-plan-name': form.insurancePlans.value,
      'insurance-plan-permalink': form.insurancePlans.value,
      'insurance-subscriber-birthdate': form.insuranceSubscriberBirthdate.value,
      'insurance-subscriber-id': form.insuranceSubscriberId.value,
      'insurance-subscriber-name': form.insuranceSubscriberName.value,
      'last-name': form.lastName.value,
      'last-test-date': form.lastTestDate.value,
      'last-test-location': form.lastTestLocation.value,
      'middle-initial': form.middleInitial.value,
      'new-patient': form.newPatient.value,
      'patient-complaint': form.reason.value,
      'phone-number': form.phoneNumber.value,
      pregnant: form.pregnant.value,
      questions: screeningQuestionsAnswers,
      'referring-facility': form['referring-facility'].value,
      'referring-physician-name': form.referringPhysicianName.value,
      'referring-provider-name': form.referringPhysician.value,
      'requires-standing-assistance': form.requiresStandingAssistance.value,
      'requires-translator': form.requiresTranslator.value,
      'sms-notifications': form['sms-notifications'].value ? '1' : '0',
      'ssn-last-4': form['ssn-last-4'].value,
      state: form.state.value,
      'terms-tos': form.tocTerms.value ? '1' : '0',
      'terms-911': form.tocNon911.value ? '1' : '0',
      'custom-terms': form.customTerms.value ? '1' : '0',
      'translator-language': form.translatorLanguage.value,
      'weeks-pregnant': form.weeksPregnant.value,
      zip: form.zip.value,
      'payment-transaction-id': paymentTransactionId,
      'source-system': form.sourceSystem.value,
      'payment-choice': paymentChoice,
      'url-permalink': urlPermalink,
      weight: form.weight.value,
      'has-coupon-code': hasCouponCode,
      'coupon-code': couponCode,
    };
  };

  const sortFormIOObject = (
    form,
    paymentChoice,
    paymentTransactionId,
    urlPermalink,
    hasCouponCode,
    couponCode,
  ) => {
    let bday = '';
    let insurancebday = '';
    const tempForm = JSON.parse(JSON.stringify(form));

    if (tempForm.birthdate) {
      const [month, day, year] = tempForm.birthdate.split('/');
      bday = `${day}/${month}/${year}`;
    }

    if (tempForm.insuranceSubscriberBirthdate) {
      const [
        insurancemonth,
        insuranceday,
        insuranceyear,
      ] = tempForm.insuranceSubscriberBirthdate.split('/');
      insurancebday = `${insuranceday}/${insurancemonth}/${insuranceyear}`;
    }

    if (tempForm.month && tempForm.day && tempForm.year) {
      bday = `${tempForm.day}/${tempForm.month}/${tempForm.year}`;
      delete tempForm.day;
      delete tempForm.month;
      delete tempForm.year;
    }

    if (
      tempForm.insurancemonth &&
      tempForm.insuranceday &&
      tempForm.insuranceyear
    ) {
      insurancebday = `${tempForm.insuranceday}/${tempForm.insurancemonth}/${tempForm.insuranceyear}`;
      delete tempForm.insuranceday;
      delete tempForm.insurancemonth;
      delete tempForm.insuranceyear;
    }

    // Note
    // For some reason, email becomes email1 and errors on form submit.
    // This fixes that.
    if (tempForm.email1) {
      tempForm.email = tempForm.email1;
      delete tempForm.email1;
    }

    /**
     * Notes:
     * - insurance-plan-name & insurance-plan-permalink are both the same value.
     *   - currently this is the permalink value
     *   - this is due to a limitation of how CustomSelect returns values
     */
    const defaultForm = {
      address: '',
      'appointment-at': dateTime,
      // appointment-context should be one of:
      // 'self', 'discharge', 'internal', or 'queue'
      // Maps to 'creation_method' of Visit model in IQApp
      'appointment-context': 'self',
      'appointment-type': '',
      birthdate: '',
      'caregiver-name': '',
      city: '',
      // 'custom-terms': form.customTerms.value ? 1 : 0,
      email: '',
      'employer-or-patient': '',
      'first-name': '',
      gender: '',
      'has-physician': '',
      'insurance-group-number': '',
      'insurance-member-number': '',
      'insurance-plan-name': '',
      'insurance-plan-permalink': '',
      'insurance-subscriber-birthdate': '',
      'insurance-subscriber-id': '',
      'insurance-subscriber-name': '',
      'last-name': '',
      'last-test-date': '',
      'last-test-location': '',
      'middle-initial': '',
      'new-patient': '',
      'patient-complaint': '',
      'phone-number': '',
      pregnant: false,
      questions: '',
      'referring-facility': '',
      'referring-physician-name': '',
      'referring-provider-name': '',
      'requires-standing-assistance': '',
      'requires-translator': '',
      state: '',
      'sms-notifications': '0',
      'terms-tos': '0',
      'terms-911': '0',
      'translator-language': '',
      'weeks-pregnant': '',
      zip: '',
      'payment-transaction-id': paymentTransactionId,
      'source-system': 'IQR',
      'payment-choice': paymentChoice,
      'url-permalink': urlPermalink,
      'has-coupon-code': hasCouponCode,
      'coupon-code': couponCode,
    };

    return {
      ...defaultForm,
      ...tempForm,
      birthdate: bday,
      'new-patient': _.isUndefined(tempForm['new-patient'])
        ? null
        : tempForm['new-patient'] === 'no',
      'insurance-subscriber-birthdate': insurancebday,
      'has-physician': _.isUndefined(tempForm['has-physician'])
        ? null
        : tempForm['has-physician'] === 'yes',
      'insurance-plan-permalink': tempForm['insurance-plan-name'],
      pregnant: tempForm.pregnant === 'yes',
      'sms-notifications': tempForm['sms-notifications'] ? '1' : '0',
      'terms-tos': tempForm['terms-tos'] ? '1' : '0',
      'terms-911': tempForm['terms-911'] ? '1' : '0',
    };
  };

  const getFormParams = (scheduleId, formObject) => {
    return {
      type: 'visits',
      attributes: formObject,
      relationships: {
        schedule: {
          data: {
            type: 'schedules',
            id: scheduleId,
          },
        },
      },
    };
  };

  const generateUri = (time, timeZone) => {
    const formatDateTime = moment(time)
      .tz(timeZone)
      .format();
    const uri = `${history.location.pathname}?${QueryString.stringify({
      ...locationHistory,
      date: formatDateTime,
    })}`;

    history.push(uri);
  };

  const getNextAvailableTime = async (
    appointmentTypeId,
    scheduleId,
    timeZone,
    currentAppointmentTime,
  ) => {
    console.log('LOG::', healthSystem['ux-selection']);
    const response = await getNextTime(
      scheduleId,
      appointmentTypeId,
      moment(dateTime).tz(timeZone),
      isDischargePage
        ? 'discharge'
        : healthSystem['ux-selection'] === 'Educational'
          ? 'patient_now'
          : 'patient',
    );

    if (response.length) {
      const { times } = response[0];
      if (times.length) {
        return times.filter(t => t !== currentAppointmentTime)[0];
      }
    }

    return false;
  };

  const updateDateToNextAvailable = async (
    appointmentTypeId,
    scheduleId,
    { name, timeZone },
    errorsList,
    currentAppointmentTime,
  ) => {
    const errorAppointmentTaken = errorsList.findIndex(
      obj =>
        (obj.title === 'appointment-at' || obj.title === 'is taken') &&
        ([
          'appointment-at - is taken',
          'appointment-at - is no longer available',
        ].includes(obj?.message) ||
          [
            'appointment-at - is taken',
            'appointment-at - is no longer available',
          ].includes(obj?.detail)),
    );
    const time = await getNextAvailableTime(
      appointmentTypeId,
      scheduleId,
      timeZone,
      currentAppointmentTime,
    );

    if (errorAppointmentTaken > -1 && time) {
      generateUri(time, timeZone);
      const msg = t('NextAvailableMessage', {
        name,
        time: moment(time)
          .tz(timeZone)
          .format('LLLL'),
      });
      errorsList.splice(errorAppointmentTaken, 1);
      return [
        ...errorsList,
        { message: msg, title: 'appointment-at - is taken' },
      ];
    }

    return errorsList;
  };

  const resetFields = () => {
    setEnabledFields([]);
    setRequiredFields([]);
  };

  const setFormKeys = form => {
    const result = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(form)) {
      if (value !== '') {
        if (key !== 'submit')
          result[key.toLowerCase().replace(/[\s_]/g, '-')] = value;
      }
    }

    return result;
  };

  const updateQueryStringParameter = (uri, key, value) => {
    const re = new RegExp(`([?|&])${key}=.*?(&|$)`, 'i');
    const separator = uri.indexOf('?') !== -1 ? '&' : '?';
    if (uri.match(re)) {
      return uri.replace(re, `$1${key}=${encodeURIComponent(value)}$2`);
    }

    return `${uri + separator + key}=${encodeURIComponent(value)}`;
  };


  const fixedHeaders = {
    'Content-Type': 'application/vnd.api+json',
    accept: '*/*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Request-Headers': 'authorization',
    'Access-Control-Request-Method': 'POST',
  };

  const fetchConversionTrackingAPI = async (apiURL) => {

    const payload = {
      "conversion_partner_id": process.env.REACT_APP_CONVERSTION_TRACKING_ID,
      "rwg_token": process.env.REACT_APP_RWG_TOKEN,
      "merchant_changed": process.env.REACT_APP_MERCHANT_ID

    };
    const params = {
      method: 'POST',
      //  headers: fixedHeaders,
      body: JSON.stringify(payload),
    };

    const response = await fetch(
      apiURL, params
    );

    try {
      if (response.ok) {
        const results = await response.text();
        console.log("Response from Conversion tracking API", results)
      }
      else {
        console.log("Error response from conversion tracking API")
      }

    }
    catch (e) {
      console.log("Exception in Conversion tracking API ", e)
    }
  };

  const submitForm = async (
    form,
    appointmentTypeId,
    scheduleId,
    scheduleLocation,
  ) => {
    const nullCheck = value =>
      isNull(value) ||
      isUndefined(value) ||
      isEmpty(value) ||
      value === 'null' ||
      value === 'undefined';
    let urlWithUtm = window.location.href;
    if (!nullCheck(localStorage.getItem('utm_source')))
      urlWithUtm = updateQueryStringParameter(
        urlWithUtm,
        'utm_source',
        localStorage.getItem('utm_source'),
      );
    if (!nullCheck(localStorage.getItem('utm_medium')))
      urlWithUtm = updateQueryStringParameter(
        urlWithUtm,
        'utm_medium',
        localStorage.getItem('utm_medium'),
      );
    if (!nullCheck(localStorage.getItem('utm_campaign')))
      urlWithUtm = updateQueryStringParameter(
        urlWithUtm,
        'utm_campaign',
        localStorage.getItem('utm_campaign'),
      );

    // The following is required for Snowplow collection to work.
    // API V4 is expecting these in the request body rather than headers.
    // url: the URL of the registration page the user is on.
    // eslint-disable-next-line no-param-reassign
    form.attributes.url = urlWithUtm;
    // referer: the URL of the page the user came from into the app.
    // Navigating within our app will not change the referer.
    // Only include if not blank.
    if (document.referrer !== '') {
      // eslint-disable-next-line no-param-reassign
      form.attributes.referer = document.referrer;
    }


    console.log("Form Attributes ", form)


    //Insurance Card Front
    if (form.attributes.uploadinsurancecardfront?.length > 0) {
      //form.attributes.document = [];
      if (form.attributes.uploadinsurancecardback?.length > 0) {
        if (isUndefined(form.attributes.document))
          form.attributes.document = [];
        form.attributes.document.push({
          "filename": form.attributes.uploadinsurancecardfront[0].originalName,
          "document_type": 'insurance',
          "document_content": form.attributes.uploadinsurancecardfront[0].url,
          "page": 1
        });
      }
      else {
        if (isUndefined(form.attributes.document))
          form.attributes.document = [];
        form.attributes.document.push({
          "filename": form.attributes.uploadinsurancecardfront[0].originalName,
          "document_type": 'insurance',
          "document_content": form.attributes.uploadinsurancecardfront[0].url,
        });
      }
    }

    //Insurance Card Back
    if (form.attributes.uploadinsurancecardback?.length > 0) {
      if (form.attributes.uploadinsurancecardfront?.length > 0) {
        if (isUndefined(form.attributes.document))
          form.attributes.document = [];
        form.attributes.document.push({
          "filename": form.attributes.uploadinsurancecardback[0].originalName,
          "document_type": 'insurance',
          "document_content": form.attributes.uploadinsurancecardback[0].url,
          "page": 2
        });
      }
      else {
        if (isUndefined(form.attributes.document))
          form.attributes.document = [];
        form.attributes.document.push({
          "filename": form.attributes.uploadinsurancecardback[0].originalName,
          "document_type": 'insurance',
          "document_content": form.attributes.uploadinsurancecardback[0].url,

        });
      }
    }

    //Drivers license
    if (form.attributes.uploaddriverslicensefront?.length > 0) {
      if (form.attributes.uploaddriverslicenseback?.length > 0) {
        if (isUndefined(form.attributes.document))
          form.attributes.document = [];
        form.attributes.document.push({
          "filename": form.attributes.uploaddriverslicensefront[0].originalName,
          "document_type": 'drivers-license',
          "document_content": form.attributes.uploaddriverslicensefront[0].url,
          "page": 1
        });
      }
      else {
        if (isUndefined(form.attributes.document))
          form.attributes.document = [];

        form.attributes.document.push({
          "filename": form.attributes.uploaddriverslicensefront[0].originalName,
          "document_type": 'drivers-license',
          "document_content": form.attributes.uploaddriverslicensefront[0].url,
        });
      }
    }

    if (form.attributes.uploaddriverslicenseback?.length > 0) {
      if (form.attributes.uploaddriverslicensefront?.length > 0) {
        if (isUndefined(form.attributes.document))
          form.attributes.document = [];
        form.attributes.document.push({
          "filename": form.attributes.uploaddriverslicenseback[0].originalName,
          "document_type": 'drivers-license',
          "document_content": form.attributes.uploaddriverslicenseback[0].url,
          "page": 2
        });
      }
      else {
        if (isUndefined(form.attributes.document))
          form.attributes.document = [];
        form.attributes.document.push({
          "filename": form.attributes.uploaddriverslicenseback[0].originalName,
          "document_type": 'drivers-license',
          "document_content": form.attributes.uploaddriverslicenseback[0].url,
        });
      }
    }

    if (form.attributes.uploadlabresults?.length > 0) {
      if (isUndefined(form.attributes.document))
        form.attributes.document = [];
      form.attributes.document.push({
        "filename": form.attributes.uploadlabresults[0].originalName,
        "document_type": 'lab',
        "document_content": form.attributes.uploadlabresults[0].url,

      });
    }

    if (form.attributes.uploadotherdocuments?.length > 0) {
      if (isUndefined(form.attributes.document))
        form.attributes.document = [];
      form.attributes.document.push({
        "filename": form.attributes.uploadotherdocuments[0].originalName,
        "document_type": 'other',
        "document_content": form.attributes.uploadotherdocuments[0].url,

      });
    }
    if (!isUndefined(form.attributes.uploadinsurancecardfront))
      delete form.attributes.uploadinsurancecardfront;
    if (!isUndefined(form.attributes.uploadinsurancecardback))
      delete form.attributes.uploadinsurancecardback;
    if (!isUndefined(form.attributes.uploaddriverslicensefront))
      delete form.attributes.uploaddriverslicensefront;
    if (!isUndefined(form.attributes.uploaddriverslicenseback))
      delete form.attributes.uploaddriverslicenseback;
    if (!isUndefined(form.attributes.uploadlabresults))
      delete form.attributes.uploadlabresults;
    if (!isUndefined(form.attributes.uploadotherdocuments))
      delete form.attributes.uploadotherdocuments;

    const response = await createVisit(form);
    const { errors, data } = response;
    const meta = response?.meta || undefined;
    let emergentError = null;
    let prohibitedPatient = null;

    if (isEmpty(errors)) {

      // Call Conversion Tracking API ony if it is called by GMB (google business page)
      if (localStorage.getItem('utm_source') != null && localStorage.getItem('utm_source') == "gmb" && localStorage.getItem('utm_medium') != null && localStorage.getItem('utm_medium') == "iqprogram")
        fetchConversionTrackingAPI(process.env.REACT_APP_CONVERSTIONTRACKING_URL);


      // set localstorage if erron on doc upload
      if (response.data?.attributes["document-upload"] != null && response.data?.attributes["document-upload"] != undefined)
        if (response.data.attributes["document-upload"]["failed-files"].length > 0)
          sessionStorage.setItem('DocUpload_OnError', response.data.attributes["document-upload"]["locize-key"]);
    }
    if (!isEmpty(errors)) {
      emergentError = errors.find(
        err => err.code === '107' && err.title === 'Invalid filter value',
      );
      prohibitedPatient = errors.find(
        err => err.title === 'Prohibited Patient',
      );

      const formattedErrors = errors.map(e => {
        const parts = e.detail.match(/\S+(?= -)/gm);
        return {
          title: parts ? parts[0] : null,
          message: e.detail || e.title,
          meta,
        };
      });
      GA.event({
        category: 'visit',
        action: 'validation_error_fields',
        label: formattedErrors.map(e => e.title).join(','),
      });
      GA.event({
        category: 'visit',
        action: 'reg_validation_error',
      });
      setFormErrors(
        await updateDateToNextAvailable(
          appointmentTypeId,
          scheduleId,
          scheduleLocation.attributes,
          formattedErrors,
          form.attributes['appointment-at'],
        ),
      );
    }

    if (!isEmpty(emergentError)) {
      const expiresIn = new Date();
      expiresIn.setHours(expiresIn.getHours() + 24);

      cookies.set(
        'prohibited',
        { value: true, expires: expiresIn },
        { path: '/' },
      );

      const blockVisitUrl = `/schedule/prohibited`;
      history.push(blockVisitUrl);
    } else if (!isEmpty(prohibitedPatient)) {
      const prohibitedPatienttUrl = `/schedule/prohibited-patient`;
      history.push(prohibitedPatienttUrl);
    } else {
      if (!isEmpty(errors)) {
        const formattedErrors = errors.map(e => {
          const parts = e.detail.match(/\S+(?= -)/gm);
          return {
            title: parts ? parts[0] : null,
            message: e.detail || e.title,
            meta,
          };
        });

        setFormErrors(
          await updateDateToNextAvailable(
            appointmentTypeId,
            scheduleId,
            scheduleLocation.attributes,
            formattedErrors,
            form.attributes['appointment-at'],
          ),
        );

        window.scrollTo(0, 0);
      }

      if (!isEmpty(data)) {
        setSecureId(data?.attributes["secure-id"]);
        if (data && data?.attributes && data?.attributes["copay-info"] && !data?.attributes["copay-info"]?.['is-copay-collected'] && data?.attributes["copay-info"]?.['copay-amount'] > 0) {
          setCopayInfo(data?.attributes["copay-info"]);
          setSecureToken(data?.attributes["copay-secure-token"]);
          setShowCoPayDialog(true);
        } else {
          await goToConfirmationPage(scheduleId, data?.attributes["secure-id"]);
        }
      }
    }
  };

  const goToConfirmationPage = async (scheduleId, secureId) => {
    const response = await fetch(
      getEndpoint(`schedules/${scheduleId}`, {
        include: 'service',
      }),
      getHeaderInfo(),
    );
    const result = await response.json();
    const service = result.included.find(i => i.type === 'services');
    GA.event({
      category: 'visit',
      action: `finalized_${service.attributes.name.replace(/\s+/g, '-')}`,
    });
    let uri = `/schedule/${scheduleId}/confirmation/${secureId}`;
    if (isDischargePage) {
      uri = `/${urlParams.dischargeFacility}/discharge${uri}`;
    }
    // state hasCouponCode to clear localStorage on Confirmation Page
    history.push({
      pathname: uri,
      state: { hasCouponCode: form.attributes?.['has-coupon-code'] },
    });
  }

  const declinePayment = async (scheduleId) => {
    setShowCoPayDialog(false);
    await payLater(secureId, secureToken);
    goToConfirmationPage(scheduleId, secureId);
  }

  const submitFormIORegistration = async (
    form,
    appointmentTypeId,
    scheduleId,
    scheduleLocation,
    paymentChoice,
    paymentTransactionId,
    urlPermalink,
    hasCouponCode,
    couponCode,
  ) => {
    await submitForm(
      getFormParams(
        scheduleId,
        setFormKeys(
          sortFormIOObject(
            form,
            paymentChoice,
            paymentTransactionId,
            urlPermalink,
            hasCouponCode,
            couponCode,
          ),
        ),
      ),
      appointmentTypeId,
      scheduleId,
      scheduleLocation,
    );
  };

  // @todo ensure errors are display if they exist
  const submitRegistrationForm = async (
    appointmentTypeId,
    scheduleId,
    scheduleLocation,
    screeningQuestionsAnswers,
    paymentChoice,
    paymentTransactionId,
    urlPermalink,
    hasCouponCode,
    couponCode,
  ) => {
    const formParams = getFormParams(
      scheduleId,
      getEnabledFormValues(
        sortFormObject(
          appointmentTypeId,
          screeningQuestionsAnswers,
          paymentChoice,
          paymentTransactionId,
          urlPermalink,
          hasCouponCode,
          couponCode,
        ),
      ),
    );
    await submitForm(
      formParams,
      appointmentTypeId,
      scheduleId,
      scheduleLocation,
    );
  };

  return (
    <RegistrationFormContext.Provider
      value={{
        form,
        formErrors,
        dateTime,
        getError,
        getVisitSettings,
        handleSetFormState,
        hasError,
        isFieldEnabled,
        isFieldRequired,
        isFormValid,
        resetFields,
        screeningQuestions,
        submitRegistrationForm,
        submitFormIORegistration,
        copayInfo,
        secureId,
        secureToken,
        showCoPayDialog,
        setShowCoPayDialog,
        declinePayment,
        goToConfirmationPage
      }}
    >
      {children}
    </RegistrationFormContext.Provider>
  );
};

RegistrationFormProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RegistrationFormProvider;
