const initialState = {
  schedule: {},
  provider: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_SCHEDULE':
      return {
        schedule: action?.payload?.schedule,
        provider: action?.payload?.provider,
      };
    default:
      return state;
  }
}
