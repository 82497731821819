import React, { useContext, useState, useEffect } from "react";
import { PatientIntakeContext } from "../contexts/PatientIntakeContext";
import { Box, Grid, Typography, Alert } from "@mui/material";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import BrandingButton from "components/common/BrandingButton";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as Edit } from 'assets/images/icons/edit.svg';
import { ReactComponent as SubmitIcon } from 'assets/images/icons/check mark-circle.svg';
import { getPatientInfo, submitInfo } from 'api/patientIntakeApi';

const SubmitPage = () => {
    const [t] = useTranslation();
    const intake = useContext(PatientIntakeContext);
    const imageInfo = intake?.intakeInfo;
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [patientInfo, setPatientInfo] = useState(null);

    const useStyles = makeStyles((theme) => ({
        nextButton: {
            paddingInline: "18px",
            float: "right",
            borderRadius: "8px",
        },
        submitIcon: {
            paddingLeft: "5px",
        },
        text: {
            fontSize: "20px"
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 200,
        },
        input: {
            "&:valid": {
                backgroundColor: "yellow"
            },
            "&:invalid": {
                backgroundColor: "red"
            }
        },
        titleStyle: {
            margin: 0,
            padding: 2,
            fontWeight: "600 !important",
            lineHeight: "30px !important"
        },
        valueTextStyle: {
            display: "flex",
            justifyContent: "flex-end",
            fontSize: "13px !important",
            fontWeight: "600 !important"
        },
        keyTextStyle: {
            fontSize: "13px !important",
            fontWeight: "500 !important"
        },
        clearIconStyle: {
            float: "right",
            bottom: "25px",
            padding: "0 !important",
        },
        imageStyle: {
            width: "100%",
            aspectRatio: '3/2',
            objectFit: "stretch !important"
        },
        addressTextStyle: {
            display: "grid",
            justifyContent: "flex-end",
            fontSize: "13px !important",
            fontWeight: "600 !important",
            textAlign: "right"
        }
    }));

    const classes = useStyles();

    const handleClick = async () => {

        try {
            setLoading(true);
            const data = await submitInfo(intake?.secureId, intake?.secureToken);
            if (data?.success) {
                intake.nextPage();
            } else {
                setErrorMessage(t('PatientIntake.InternalServerError'))
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }

    const fetchPatientInfo = async () => {
        try {
            setLoading(true);
            const data = await getPatientInfo(intake?.secureId, intake?.secureToken);
            setPatientInfo(data);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPatientInfo();
    }, [intake?.secureId]);

    const goToPage = async (page) => {
        const docsIndex = intake?.requiredSteps?.findIndex(item => item.documentTypeCode === page);
        if (docsIndex > -1) {
            intake.setPageNum(docsIndex);
        }
        return;
    }

    const paymentIndex = intake?.requiredSteps?.findIndex(item => item.documentTypeCode === "payment")

    return (
        <>
            {errorMessage && (
                <Alert severity="error">
                    <b>{t(errorMessage)}</b>
                </Alert>
            )}

            <Grid container >
                <Box
                    className="validation-page"
                    component="form"
                    noValidate
                    autoComplete="off"
                    sx={{ flexGrow: 1 }}
                >

                    <Grid container alignItems="center" justifyContent={"space-evenly"} >
                        <Grid item xs={11} sm={5} margin={1}>
                            <Grid container justifyContent={"space-between"}>
                                <Grid item xs={11} marginY={1}>
                                    <Typography variant="h6" component="h6" className="stepStyle">
                                        {t('PatientIntake.ApptInfo')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} marginY={0.5}>
                                    <Typography className={classes.keyTextStyle}>
                                        {t('PatientIntake.ApptType')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={7} marginY={0.5}>
                                    <Typography className={classes.valueTextStyle}>
                                        {patientInfo?.appointmentInfo?.appointmentType}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} marginY={0.5}>
                                    <Typography className={classes.keyTextStyle}>
                                        {t('PatientIntake.Appt')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={7} marginY={0.5}>
                                    <Typography className={classes.valueTextStyle}>
                                        {moment(patientInfo?.appointmentInfo?.appointmentTime?.split(".")[0]).format('MMM DD, YYYY h:mm A')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} marginY={0.5}>
                                    <Typography className={classes.keyTextStyle}>
                                        {t('PatientIntake.Provider')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={7} marginY={0.5}>
                                    <Typography className={classes.valueTextStyle}>
                                        {patientInfo?.appointmentInfo?.providerName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} marginY={0.5}>
                                    <Typography className={classes.keyTextStyle}>
                                        {t('PatientIntake.Address')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} marginY={0.5}>
                                    <Typography component={'div'} className={classes.addressTextStyle}>
                                        <p>{patientInfo?.appointmentInfo?.providerAddress}</p>
                                        <p>{patientInfo?.appointmentInfo?.providerLocation}</p>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={11} sm={5} margin={1}>
                            <Grid container justifyContent={"space-between"}>
                                <Grid item xs={11} marginY={1}>
                                    <Typography variant="h6" component="h6" className="stepStyle">
                                        {t('PatientIntake.PatientInfo')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} marginY={0.5}>
                                    <Typography className={classes.keyTextStyle}>
                                        {t('PatientIntake.PatientName')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={7} marginY={0.5}>
                                    <Typography className={classes.valueTextStyle}>
                                        {patientInfo?.patientInfo?.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} marginY={0.5}>
                                    <Typography className={classes.keyTextStyle}>
                                        {t('PatientIntake.PatientAge')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={7} marginY={0.5}>
                                    <Typography className={classes.valueTextStyle}>
                                        {patientInfo?.patientInfo?.age} {t('PatientIntake.Years')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} marginY={0.5}>
                                    <Typography className={classes.keyTextStyle}>
                                        {t('PatientIntake.DateOfBirth')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={7} marginY={0.5}>
                                    <Typography className={classes.valueTextStyle}>
                                        {moment(patientInfo?.patientInfo?.dob).format('MMM DD, YYYY')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {intake?.copayInfo?.copayAmount && paymentIndex > -1 &&
                            <Grid item xs={11} margin={1}>
                                <Grid container justifyContent={"space-between"}>
                                    <Grid item xs={12} >
                                        <Typography variant="h6" component="h6" className="stepStyle">
                                            {t('PatientIntake.Payment')}
                                        </Typography>
                                        {!intake?.copayInfo?.isCopayCollected &&
                                            <IconButton
                                                aria-label="close"
                                                onClick={() => goToPage("payment")}
                                                className={classes.clearIconStyle}
                                            >
                                                <Edit />
                                            </IconButton>}
                                    </Grid>
                                    <Grid item xs={5} >
                                        <Typography className={classes.keyTextStyle}>
                                            {intake?.copayInfo?.isCopayCollected ? `Total: $${Number(intake?.copayInfo?.copayAmount).toFixed(2)}` : "Pay Later at Appointment"}
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </Grid>}

                        <Grid item xs={11} margin={1}>
                            <Grid container justifyContent={"space-between"}>
                                <Grid item xs={12} marginY={1}>
                                    <Typography variant="h6" component="h6" className="stepStyle">
                                        {t('PatientIntake.Insurance')}
                                    </Typography>
                                    {(!intake?.copayInfo?.isCopayCollected || intake?.isCoPayCollectedInRegForm) &&
                                        <IconButton
                                            aria-label="close"
                                            onClick={() => goToPage("insurance")}
                                            className={classes.clearIconStyle}
                                        >
                                            <Edit />
                                        </IconButton>}
                                </Grid>
                                <Grid item xs={5} marginY={0.5}>
                                    <Typography className={classes.keyTextStyle}>
                                        {t('PatientIntake.Insurance')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={7} marginY={0.5}>
                                    <Typography className={classes.valueTextStyle}>
                                        {patientInfo?.insurance?.insurancePlanName}
                                    </Typography>
                                </Grid>
                                {patientInfo?.insurance?.otherInsurance &&
                                    <>
                                        <Grid item xs={5} marginY={0.5}>
                                            <Typography className={classes.keyTextStyle}>
                                                {t('PatientIntake.OtherInsurance')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={7} marginY={0.5}>
                                            <Typography className={classes.valueTextStyle}>
                                                {patientInfo?.insurance?.otherInsurance}
                                            </Typography>
                                        </Grid>
                                    </>}
                                <Grid item xs={5} marginY={0.5}>
                                    <Typography className={classes.keyTextStyle}>
                                        {t('PatientIntake.InsurancePhoneNumber')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={7} marginY={0.5}>
                                    <Typography className={classes.valueTextStyle}>
                                        {patientInfo?.insurance?.insurancePhoneNumber}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} marginY={0.5}>
                                    <Typography className={classes.keyTextStyle}>
                                        {t('PatientIntake.InsuranceFullAddress')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={7} marginY={0.5}>
                                    <Typography className={classes.valueTextStyle}>
                                        {patientInfo?.insurance?.insuranceFullAddress}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} marginY={0.5}>
                                    <Typography className={classes.keyTextStyle}>
                                        {t('PatientIntake.InsuranceSubscriberId')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={7} marginY={0.5}>
                                    <Typography className={classes.valueTextStyle}>
                                        {patientInfo?.insurance?.insuranceSubscriberId}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} marginY={0.5}>
                                    <Typography className={classes.keyTextStyle}>
                                        {t('PatientIntake.InsuranceGroupNumber')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={7} marginY={0.5}>
                                    <Typography className={classes.valueTextStyle}>
                                        {patientInfo?.insurance?.insuranceGroupNumber}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} marginY={0.5}>
                                    <Typography className={classes.keyTextStyle}>
                                        {t('PatientIntake.InsuranceSubscriberName')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={7} marginY={0.5}>
                                    <Typography className={classes.valueTextStyle}>
                                        {patientInfo?.insurance?.insuranceSubscriberName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} marginY={0.5}>
                                    <Typography className={classes.keyTextStyle}>
                                        {t('PatientIntake.InsuranceMemberId')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={7} marginY={0.5}>
                                    <Typography className={classes.valueTextStyle}>
                                        {patientInfo?.insurance?.insuranceMemberId}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        {imageInfo?.insurance?.FrontImage && imageInfo?.insurance?.BackImage &&
                            <Grid item xs={11} margin={1}>
                                <Grid container columnGap={2} rowGap={2}>
                                    <Grid item xs={12} >
                                        <Typography variant="h6" component="h6" className="stepStyle">
                                            {t('PatientIntake.Insurance')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5} md={4} >
                                        <img src={imageInfo?.insurance?.FrontImage} id={`preview-Image`} className={classes.imageStyle} role="img" />
                                    </Grid>
                                    <Grid item xs={5} md={4} >
                                        <img src={imageInfo?.insurance?.BackImage} id={`preview-Image`} className={classes.imageStyle} role="img" />
                                    </Grid>

                                </Grid>
                            </Grid>}

                        {(imageInfo?.["drivers-license"]?.FrontImage || imageInfo?.["drivers-license"]?.BackImage) &&
                            <Grid item xs={11} margin={1}>
                                <Grid container columnGap={2} rowGap={2}>
                                    <Grid item xs={12} >
                                        <Typography variant="h6" component="h6" className="stepStyle">
                                            {t('PatientIntake.DriverLicense')}
                                        </Typography>
                                        <IconButton
                                            aria-label="close"
                                            onClick={() => goToPage("drivers-license")}
                                            className={classes.clearIconStyle}
                                        >
                                            <Edit />
                                        </IconButton>
                                    </Grid>
                                    {imageInfo?.["drivers-license"]?.FrontImage &&
                                        <Grid item xs={5} md={4}>
                                            <img src={imageInfo?.["drivers-license"]?.FrontImage} id={`preview-Image`} className={classes.imageStyle} role="img" />
                                        </Grid>}
                                    {imageInfo?.["drivers-license"]?.BackImage &&
                                        <Grid item xs={5} md={4} >
                                            <img src={imageInfo?.["drivers-license"]?.BackImage} id={`preview-Image`} className={classes.imageStyle} role="img" />
                                        </Grid>}
                                </Grid>
                            </Grid>}

                        {(imageInfo?.other?.FrontImage || imageInfo?.other?.BackImage) &&
                            <Grid item xs={11} margin={1}>
                                <Grid container columnGap={2} rowGap={2}>
                                    <Grid item xs={12} >
                                        <Typography variant="h6" component="h6" className="stepStyle">
                                            {t('PatientIntake.Other')}
                                        </Typography>
                                        <IconButton
                                            aria-label="close"
                                            onClick={() => goToPage("other")}
                                            className={classes.clearIconStyle}
                                        >
                                            <Edit />
                                        </IconButton>
                                    </Grid>
                                    {imageInfo?.other?.FrontImage &&
                                        <Grid item xs={5} md={4} >
                                            <img src={imageInfo?.other?.FrontImage} id={`preview-Image`} className={classes.imageStyle} role="img" />
                                        </Grid>}
                                    {imageInfo?.other?.BackImage &&
                                        <Grid item xs={5} md={4} >
                                            <img src={imageInfo?.other?.BackImage} id={`preview-Image`} className={classes.imageStyle} role="img" />
                                        </Grid>}
                                </Grid>
                            </Grid>}

                        {(imageInfo?.lab?.FrontImage || imageInfo?.lab?.BackImage) &&
                            <Grid item xs={11} margin={1}>
                                <Grid container columnGap={2} rowGap={2}>
                                    <Grid item xs={12} >
                                        <Typography variant="h6" component="h6" className="stepStyle">
                                            {t('PatientIntake.Lab')}
                                        </Typography>
                                        <IconButton
                                            aria-label="close"
                                            onClick={() => goToPage("lab")}
                                            className={classes.clearIconStyle}
                                        >
                                            <Edit />
                                        </IconButton>
                                    </Grid>
                                    {imageInfo?.lab?.FrontImage &&
                                        <Grid item xs={5} md={4} >
                                            <img src={imageInfo?.lab?.FrontImage} id={`preview-Image`} className={classes.imageStyle} role="img" />
                                        </Grid>}
                                    {imageInfo?.lab?.BackImage &&
                                        <Grid item xs={5} md={4} >
                                            <img src={imageInfo?.lab?.BackImage} id={`preview-Image`} className={classes.imageStyle} role="img" />
                                        </Grid>}
                                </Grid>
                            </Grid>}


                        <Grid item xs={11} marginY={1} justifyContent={"flex-end"}>
                            <BrandingButton
                                type="button"
                                className={classes.nextButton}
                                onClick={handleClick}
                            >
                                {t('PatientIntake.Submit')} <SubmitIcon className={classes.submitIcon} />
                            </BrandingButton>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </>
    );
};
export default SubmitPage;
