import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import moment from 'moment-timezone';
import { getTimes } from 'api/availableTimesApi';
import DateCarouselContainer from 'components/appointmentPicker/DateCarouselContainer';
import { filterTimes } from 'utils/helpers';
import { groupedDays } from 'utils/dateTimeUtils';
import { useSelector } from 'react-redux';
import useSettings from 'hooks/useSettings';
import { useQueryString } from '../../hooks/useQueryString';
import './FacilityDateCarousel.scss';

const FacilityDateCarousel = ({
  scheduleId,
  appointmentTypeId,
  maxTimesPerDay,
  link,
  timezoneName,
  locationId,
  datesShow,
  datesLoading,
  dateCurrent,
  noOfDays,
  startTime,
  endTime,
  scrollLeft,
  onScroll,
  setWhoIsScrolling,
  whoIsScrolling,
  nowContext,
  servicePermalink,
  facility,
}) => {
  const healthSystem = useSelector(state => state.healthSystem);
  const { pathname, isDischargePage } = useQueryString();
  const isServiceNow = () => pathname === '/';
  const [numberOfDays, setNumberOfDays] = useState();
  const [date, setDate] = useState();
  const [dates, setDates] = useState([]);
  const [times, setTimes] = useState([]);
  const [nextAvailableTime, setNextAvailableTime] = useState('');
  const [loading, setLoading] = useState(true);
  const [showFacilityDateCarousel, setShowFacilityDateCarousel] = useState(
    true,
  );
  const availableScheduleDays = useSettings({
    facilityId: facility,
    caller: 'FacilityDateCarousel',
  });

  useEffect(() => {
    setDates(datesShow);
    setLoading(datesLoading);
    setDate(dateCurrent);
    setNumberOfDays(noOfDays);
  }, [datesShow, datesLoading, dateCurrent, noOfDays]);

  useEffect(() => {
    let runSetState = true;
    setLoading(true);
    setDates(groupedDays(date, numberOfDays, timezoneName));
    const fetchTimes = async () => {

      const today = moment()
        .tz(timezoneName)
        .format();
      const toDate = moment(today)
        .tz(timezoneName)
        .add(availableScheduleDays - 1, 'days')
        .format();
      const timesResponse = await getTimes(
        scheduleId,
        appointmentTypeId,
        date,
        numberOfDays,
        isDischargePage ? 'discharge' : nowContext ? 'patient_now' : 'patient',
        toDate,
      );

      if (runSetState) {
        const nextTime = timesResponse[0]['next-time'];
        if (
          moment(nextTime)
            .tz(timezoneName)
            .isSameOrBefore(toDate)
        )
          setNextAvailableTime(timesResponse[0]['next-time']);
        setTimes(timesResponse[0].times);
        if (!!startTime || !!endTime) {
          const filteredTimesBytime = await filterTimes(
            timesResponse[0].times,
            startTime,
            endTime,
            timezoneName,
          );
          setTimes(filteredTimesBytime);
        }
        await setLoading(false);
      }
    };
    if (date && numberOfDays && availableScheduleDays) {
      fetchTimes();
      setShowFacilityDateCarousel(true);
    }
    return () => {
      runSetState = false;
    };
  }, [scheduleId, date, numberOfDays, availableScheduleDays]);

  return (
    <>
      {showFacilityDateCarousel && (
        <div
          className={`FacilityDateCarousel mb-3 ${isServiceNow() ? 'educational' : ''
            }`}
          style={{ background: 'white' }}
        >
          <Grid container alignItems="center">
            <>
              <DateCarouselContainer
                dates={dates}
                date={date}
                times={times}
                link={link}
                loading={loading}
                locationId={locationId}
                maxTimesPerDay={maxTimesPerDay}
                timezoneName={timezoneName}
                showMore
                scrollLeft={scrollLeft}
                onScroll={onScroll}
                whoIsScrolling={whoIsScrolling}
                setWhoIsScrolling={setWhoIsScrolling}
                scheduleId={scheduleId}
                nextAvailableTime={nextAvailableTime}
                servicePermalink={servicePermalink}
              />
            </>
          </Grid>
        </div>
      )}
    </>
  );
};

FacilityDateCarousel.defaultProps = {
  locationId: '',
  maxTimesPerDay: null,
  datesShow: [],
  datesLoading: false,
  dateCurrent: '',
  startTime: '',
  endTime: '',
  noOfDays: 1,
  scrollLeft: {},
  onScroll: () => { },
  setWhoIsScrolling: () => { },
  whoIsScrolling: '',
  nowContext: false,
  facility: {},
};

FacilityDateCarousel.propTypes = {
  /** Appt Type ID. Required to fetch times. */
  appointmentTypeId: PropTypes.string.isRequired,
  /** Registration Page link */
  link: PropTypes.string.isRequired,
  /** Schedule ID. Required to fetch times. */
  scheduleId: PropTypes.string.isRequired,
  /** Display time based on the facilty time zone */
  timezoneName: PropTypes.string.isRequired,
  /** Number of days to display. */
  locationId: PropTypes.string,
  /** Component will display all times unless limited by this prop. */
  maxTimesPerDay: PropTypes.number,
  /** Whether to display a link to full schedule page.
   * Redundant on the Schedule Page, so default is false. */
  datesShow: PropTypes.instanceOf(Array),
  datesLoading: PropTypes.bool,
  dateCurrent: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  noOfDays: PropTypes.number,
  scrollLeft: PropTypes.instanceOf(Object),
  onScroll: PropTypes.func,
  setWhoIsScrolling: PropTypes.func,
  whoIsScrolling: PropTypes.string,
  nowContext: PropTypes.bool,
  servicePermalink: PropTypes.string.isRequired,
  facility: PropTypes.instanceOf(Object),
};

export default FacilityDateCarousel;
