import { getVisit } from 'api/visitApi';
import * as types from './actionTypes';
import { beginApiCall, apiCallError } from './apiStatusActions';

export function fetchVisitSuccess(visit) {
  return {
    type: types.FETCH_VISIT_SUCCESS,
    visit,
  };
}

function clearVisit() {
  return { type: types.CLEAR_VISIT };
}

export function fetchVisit(confirmationCode) {
  // eslint-disable-next-line func-names
  return async function(dispatch) {
    dispatch(beginApiCall());
    dispatch(clearVisit());
    try {
      const visit = await getVisit(confirmationCode, {
        include: 'location,provider,schedule',
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
        dispatch(fetchVisitSuccess({ data: 'loaded' }));
      });
      if (visit && visit?.data === null) {
        dispatch(fetchVisitSuccess({ data: 'loaded' }));
        return;
      }
      dispatch(fetchVisitSuccess(visit));
    } catch (error) {
      dispatch(apiCallError(error));
    }
  };
}
