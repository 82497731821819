import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';
import QueryString from 'query-string';
import {
  createMuiTheme,
  MuiThemeProvider,
  LinearProgress,
} from '@material-ui/core';
import { loadApiToken } from 'redux/actions/authActions';
import { fetchHealthSystem } from 'redux/actions/healthSystemActions';
import { getSettings } from 'redux/actions/settingsActions';
import {
  clearHistory,
  addHistoryLocation,
} from 'redux/actions/breadcrumbActions';
import PageNotFound from 'components/PageNotFound';
import Header from 'components/header/Header';
import Footer from 'components/common/Footer';
import EducationalNowPage from 'components/educational/EducationalNowPage';
import InventoryPage from 'components/inventory/InventoryPage';
import FacilityInventoryPage from 'components/inventory/FacilityInventoryPage';
import ProviderDetailPage from 'components/provider/ProviderDetailPage';
import ScheduleAppointment from 'components/schedule/ScheduleAppointment';
import ConfirmationPage from 'components/provider/ConfirmationPage';
import ScheduleDetailPage from 'components/schedule/ScheduleDetailPage';
import VisitCancelPage from 'components/visit/VisitCancelPage';
import VisitCancelConfirmationPage from 'components/visit/VisitCancelConfirmationPage';
import VisitFeedbackPage from 'components/visit/VisitFeedbackPage';
import VisitFeedbackThankYouPage from 'components/visit/VisitFeedbackThankYouPage';
import VisitProhibitedPage from 'components/visit/VisitProhibitedPage';
import VisitProhibitedPatientPage from 'components/visit/VisitProhibitedPatientPage';
import PageServerError from 'components/PageServerError';
import TermsPage from 'components/TermsPage';
import PrivacyPolicy from 'components/PrivacyPolicy';
import BrandingContextProvider from 'components/contexts/BrandingContext';
import { BrandingContext } from './components/contexts/BrandingContext';
import useGoogleAnalytics from 'hooks/useGoogleAnalytics';
import TagManager from 'react-gtm-module';
import RegistrationFormProvider from 'components/form/RegistrationFormProvider';
import LandingPage from 'components/landingPage/LandingPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { isIE, browserVersion } from 'react-device-detect';
import UnsupportedBrowser from 'components/modals/UnsupportedBrowser';
import Sitemap from 'components/sitemap/Sitemap';
import SitemapCities from 'components/sitemap/SitemapCities';
import SitemapFacilities from 'components/sitemap/SitemapFacilities';
import SitemapProviders from 'components/sitemap/SitemapProviders';
import SitemapServices from 'components/sitemap/SitemapServices';
import TrackingCodes from 'components/common/TrackingCodes';
import { Next24HoursHOC } from 'components/inventory/Next24HoursHOC';
import PageMeta from 'components/common/PageMeta';
import PatientIntakePage from 'components/patientIntake/PatientIntakePage';
import { PatientIntakeProvider } from 'components/contexts/PatientIntakeContext';

const LATEST_UNSUPPORTED_IE_VERSION = 11;

const AppTheme = createMuiTheme({
  typography: {
    fontFamily: 'AvenirNextLTPro',
  },
});

export function App({
  loading,
  loadApiToken,
  clearHistory,
  addHistoryLocation,
  fetchHealthSystem,
  healthSystem,
  getSettings,
}) {
  const [loadingHS, setLoadingHS] = useState(true);
  const [defaultEnabled, setDefaultEnabled] = useState(true);
  const [trackingKeys, setTrackingKeys] = useState({});
  const [supportedLocales, setSupportedLocales] = useState([]);
  const [defaultLocale, setDefaultLocale] = useState({});

  const GA = useGoogleAnalytics({ keys: trackingKeys });

  const history = useHistory();
  const { pathname, search } = useLocation();
  const params = QueryString.parse(search);
  const [inactive] = useState(params?.inactive === 'true');

  const [t] = useTranslation();
  localStorage.setItem('topMedicalCare', t('BrandingContext.topMedicalCare'));
  localStorage.setItem(
    'topSpecialityServices',
    t('BrandingContext.topSpecialityServices'),
  );

  // Set the utm source and medium
  if (params.utm_source) {
    localStorage.setItem('utm_source', params.utm_source);
  }
  if (params.utm_medium) {
    localStorage.setItem('utm_medium', params.utm_medium);
  }
  if (params.utm_campaign) {
    localStorage.setItem('utm_campaign', params.utm_campaign);
  }

  useEffect(() => {
    if (pathname === '/') {
      clearHistory();
    }
    addHistoryLocation(history.location);
  }, [history.location]);

  // Effect to set up GTM. Managed via IQApp.
  // If a client has opted in, and provided a GTM ID, and we still get 404s
  // it is likely that their container has not been published.
  // Docs: https://www.npmjs.com/package/react-gtm-module

  useEffect(() => {
    (async () => {
      await loadApiToken();
      await fetchHealthSystem();
      await getSettings();
      // load a new token every 29 minutes since they expire in 30.
      setInterval(loadApiToken, 29 * 60 * 1000);
      // capture the code from url and save it for further use
      if (params.code) {
        localStorage.setItem('code', params.code);
      }
    })();
  }, []);

  useEffect(() => {
    async function setup() {
      setDefaultEnabled(healthSystem['ux-selection'] === 'Default');
      const tagManagerArgs = {
        gtmId: healthSystem['gtm-container'],
      };
      // If a client hasn't opted in, we won't fire request to GTM.
      if (healthSystem['use-gtm-container']) {
        TagManager.initialize(tagManagerArgs);
      }
      setLoadingHS(false);

      const tKeys = [];
      if (healthSystem['use-carenet-gtm'] === true) {
        tKeys.push(healthSystem['google-analytics-key']);
      }

      // const analyticsKey = healthSystem['google-analytics-key'];
      // const partnerKey = healthSystem['partner-google-analytics-key'];

      tKeys.push(healthSystem['partner-google-analytics-key']);
      const keys = tKeys.filter(Boolean).map((key, i) => {
        return {
          trackingId: key,
          titleCase: false, // important, ReactGA automatically titlecase's strings and it has to be disabled
          name: i === 1 ? 'partnerAnalytics' : undefined,
        };
      });

      setTrackingKeys(keys);
      const dimensions = {
        dimensionLocale: localStorage.getItem('i18nextLng'),
        dimension2: `${healthSystem.id}-${healthSystem.name.replace(
          /[\W_]+/g,
          '_',
        )}`,
        dimension3: healthSystem.region
          ? `${healthSystem.region.id}-${healthSystem.region.name.replace(
            /[\W_]+/g,
            '_',
          )}`
          : undefined,
      };
      if (window.location.pathname.includes("/patient-intake/")) {
        let supported = [
          {
            name: 'English',
            code: 'en-US',
            isDeleted: false,
            deleterId: null,
            deletionTime: null,
            lastModificationTime: null,
            lastModifierId: null,
            creationTime: '2020-07-31T15:47:03.658747',
            creatorId: null,
            id: '39f6b896-b96a-bbb3-b4ff-0d33ba64c15c',
          },
        ];
        setSupportedLocales(supported);
        setDefaultLocale(supported);
        setTimeout(() => GA.set(dimensions), 50);
        return;
      }
      let supported = [];
      if (
        healthSystem['supported-locales'] &&
        healthSystem['supported-locales'].length > 0
      ) {
        supported = healthSystem['supported-locales'].filter(
          supportedLocale => supportedLocale.isDeleted === false,
        );
      } else {
        supported = [
          {
            name: 'English',
            code: 'en-US',
            isDeleted: false,
            deleterId: null,
            deletionTime: null,
            lastModificationTime: null,
            lastModifierId: null,
            creationTime: '2020-07-31T15:47:03.658747',
            creatorId: null,
            id: '39f6b896-b96a-bbb3-b4ff-0d33ba64c15c',
          },
        ];
      }
      const localLang = localStorage.getItem('i18nextLng');
      if (healthSystem['default-locale']) {
        if (localLang) {
          if (!supported.find(item => item.code.startsWith(localLang))) {
            i18next.changeLanguage(healthSystem['default-locale'].code);
          } else if (supported.length === 1) {
            i18next.changeLanguage(healthSystem['default-locale'].code);
          }
        } else {
          i18next.changeLanguage(healthSystem['default-locale'].code);
        }
      }

      if (localLang.length === 2) {
        const culturalLang = supported.filter(item =>
          item.code.startsWith(localLang),
        );
        if (localLang === 'en') {
          i18next.changeLanguage('en-US');
          localStorage.setItem('i18nextLng', 'en-US');
        } else {
          i18next.changeLanguage(culturalLang[0].code);
          localStorage.setItem('i18nextLng', culturalLang[0].code);
        }
      }

      setSupportedLocales(supported);
      setDefaultLocale(healthSystem['default-locale']);
      setTimeout(() => GA.set(dimensions), 50);
    }

    if (healthSystem && Object.keys(healthSystem).length) {
      setup();
    }
  }, [healthSystem]);

  // always scroll to top when hitting a new route.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (loading || loadingHS) return <LinearProgress />;

  return (
    <BrandingContextProvider>
      <MuiThemeProvider theme={AppTheme}>
        <div className="App">
          {isIE && browserVersion <= LATEST_UNSUPPORTED_IE_VERSION ? (
            <UnsupportedBrowser />
          ) : null}
          <TrackingCodes />
          <PageMeta />
          <Header supportedLocales={supportedLocales} />
          {inactive ? <PageNotFound errorCode={'400'} /> :
            <Switch>
              <Route
                exact
                path={['/']}
                component={defaultEnabled ? LandingPage : EducationalNowPage}
              />
              <Route
                exact
                key="terms-of-use"
                path="/terms-of-use"
                component={TermsPage}
              />
              <Route
                exact
                key="privacy-policy"
                path="/privacy-policy"
                component={PrivacyPolicy}
              />

              <Route
                exact
                path={[
                  '/:dischargeFacility/discharge',
                  '/facility/:facilityName/:locationPermalink',
                  '/facility/:facilityName/service/:serviceLine',
                  '/facility/:facilityName/:locationPermalink/:serviceLine',
                  '/:dischargeFacility/discharge/:locationPermalink',
                  '/:dischargeFacility/discharge/service/:serviceLine',
                  // '/facility/:facilityName/:locationPermalink/:serviceLine',
                  '/facility/:facilityName/:locationPermalink/service/:serviceLine',
                  // '/:dischargeFacility/discharge/:locationPermalink/service/:serviceLine',
                  // '/:dischargeFacility/discharge/:locationPermalink/:serviceLine',
                ]}
                component={FacilityInventoryPage}
              />
              <Route
                exact
                path={['/facility/:facilityName']}
                component={FacilityInventoryPage}
              />
              <Route
                exact
                path={[
                  '/schedule/calendar/:id',
                  '/:dischargeFacility/discharge/schedule/calendar/:id',
                ]}
                component={ScheduleDetailPage}
              />
              <Route
                exact
                path={[
                  '/providers/:id',
                  '/provider/:permalink',
                  '/:dischargeFacility/discharge/providers/:id',
                  '/:dischargeFacility/discharge/provider/:permalink',
                ]}
                component={ProviderDetailPage}
              />
              <Route
                path={[
                  '/schedule/:scheduleId/confirmation/:confirmationId',
                  '/:dischargeFacility/discharge/schedule/:scheduleId/confirmation/:confirmationId',
                ]}
                component={ConfirmationPage}
              />
              <Route
                exact
                path={[
                  '/schedule/prohibited',
                  '/:dischargeFacility/discharge/schedule/prohibited',
                ]}
                component={VisitProhibitedPage}
              />
              <Route
                exact
                path={[
                  '/schedule/prohibited-patient',
                  '/:dischargeFacility/discharge/schedule/prohibited-patient',
                ]}
                component={VisitProhibitedPatientPage}
              />
              <Route
                exact
                path={[
                  '/schedule/:scheduleId',
                  '/:dischargeFacility/discharge/schedule/:scheduleId',
                ]}
                render={props => (
                  <RegistrationFormProvider>
                    <ScheduleAppointment {...props} />
                  </RegistrationFormProvider>
                )}
              />
              <Route
                exact
                path="/visits/:visitId/cancel"
                component={VisitCancelPage}
              />
              <Route
                exact
                path="/visits/:visitId/canceled"
                component={VisitCancelConfirmationPage}
              />
              <Route
                exact
                path="/feedback/(new|details)/:visitId"
                component={VisitFeedbackPage}
              />
              <Route
                exact
                path="/feedback/thank-you"
                component={VisitFeedbackThankYouPage}
              />
              <Route exact path="/error/500" component={PageServerError} />
              <Route exact path="/sitemap/" component={Sitemap} />
              <Route exact path="/sitemap/cities" component={SitemapCities} />
              <Route
                exact
                path="/sitemap/facilities"
                component={SitemapFacilities}
              />
              <Route
                exact
                path="/sitemap/providers"
                component={SitemapProviders}
              />
              <Route exact path="/sitemap/services" component={SitemapServices} />
              {pathname.startsWith('/next-24') && <Next24HoursHOC />}
              <Route
                exact
                strict
                key="inventory-page"
                path={[
                  '/:serviceLine/search',
                  '/:serviceLine/:state/:city/search',
                  '/search',
                  '/:serviceLine',
                  '/:serviceLine/',
                  '/:serviceLine/:state/:city',
                ]}
                component={InventoryPage}
              />
              <Route
                exact
                path="/patient-intake/:secureId"
                render={props => (
                  <RegistrationFormProvider>
                    <PatientIntakeProvider>
                      <PatientIntakePage {...props} />
                    </PatientIntakeProvider>
                  </RegistrationFormProvider>
                )}
              />
              <Route component={PageNotFound} />
            </Switch>
          }
          <Footer
            supportedLocales={supportedLocales}
            defaultLocale={defaultLocale}
          />
        </div>
      </MuiThemeProvider>
    </BrandingContextProvider>
  );
}

App.propTypes = {
  clearHistory: PropTypes.func,
  addHistoryLocation: PropTypes.func,
  loadApiToken: PropTypes.func,
  fetchHealthSystem: PropTypes.func.isRequired,
  getSettings: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  healthSystem: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  return {
    loading: !state.apiAuthSuccess,
    healthSystem: state.healthSystem,
    settings: state.settings
  };
}

const mapDispatchToProps = {
  loadApiToken,
  addHistoryLocation,
  clearHistory,
  fetchHealthSystem,
  getSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
