export default {
  apiAuthSuccess: 0,
  apiCallsInProgress: 0,
  appointmentTypes: {
    data: [],
    meta: { loading: false },
  },
  breadcrumb: {
    history: [],
  },
  educationalPage: {
    showForm: {
      provider: true,
      physician: true,
    },
  },
  geocoding: {
    loaded: false,
    zipcodeValid: false,
    zipcode: '',
    coords: {},
  },
  healthSystem: {},
  visit: { data: null },
  insurancePlans: [],
  medicalGroups: {},
  providers: {
    searched: false,
    data: [],
    locations: [],
    schedules: [],
    venuesOfCare: [],
    meta: {
      responseMeta: {},
      pagesLoaded: [],
    },
    included: [],
    junk: {},
  },
  services: {},
  settings: [],
  serviceLines: { serviceLines: {}, services: {}, dischargedCalled: false },
  searchParams: {
    selectedParams: {
      appointmentTypeId: '',
      searchType: '',
    },
    appointmentTypes: [],
    insurancePlans: [],
    providerNames: [],
    serviceNames: [],
    specialityNames: [],
  },
  searchFilters: {
    genders: [],
    categories: [],
    languages: [],
    hospitalAffiliations: [],
    acceptNewPatient: false,
    timeZone: 'UTC',
  },
  searchResult: {
    searching: false,
    providers: [],
    locations: [],
    schedules: [],
    availableTimes: [],
  },
  venuesOfCare: {
    data: [],
    included: [],
    meta: {
      loading: false,
    },
  },
  visitSettings: {
    meta: {
      loading: false,
    },
  },
  ui: {
    notification: {
      show: false,
      title: '',
      message: '',
      severity: '',
      hideDesktop: false,
      delay: 0,
    },
  },
  authToken: localStorage.getItem('jwt') || '',
};
