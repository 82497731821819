/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
import React, { useEffect, useState, useCallback, useContext } from 'react';
import 'abort-controller/polyfill';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { find, isEmpty, uniq } from 'lodash';
import Sticky from 'react-sticky-el';
import { isMobile } from 'react-device-detect';
import { useTranslation, Trans } from 'react-i18next';
import { BrandingContext } from 'components/contexts/BrandingContext';
import { connect, useDispatch, useSelector } from 'react-redux';
import { fetchHealthSystem } from 'redux/actions/healthSystemActions';
import { loadVenuesOfCare } from 'redux/actions/venuesOfCareActions';
import { loadScheduleTimes } from 'redux/actions/searchActions';
import { createFacilityEvent } from 'api/analyticsApi';
import {
  loadActiveServices,
  loadDischargeActiveServices,
} from 'redux/actions/serviceLineActions';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import { getEndpoint, getHeaderInfo } from 'api/apiUtils';
import MapView from 'components/inventory/MapView';
import ScheduleResultList from 'components/result/ScheduleResultList';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid } from '@material-ui/core';
import QueryString from 'query-string';
import InventoryControls from 'components/inventory/InventoryControls';
import InventoryControlsMobile from 'components/inventory/InventoryControlsMobile';
import * as searchConstants from 'config/searchConstants';
import PageNotFound from 'components/PageNotFound';
import DateCarousel from 'components/appointmentPicker/DateCarousel';
import Paginator from 'components/common/Paginator';
import { groupedDays } from 'utils/dateTimeUtils';
import { useHistory, useLocation } from 'react-router-dom';
import { getLocationByPermalink } from 'api/locationApi';
import { getAvailableFacilities } from 'redux/actions/facilitiesActions';
import { ER_SERVICE_NAMES, UC_SERVICE_NAMES } from 'config/searchConstants';
import { shouldShowSearchElement, isGroupC } from 'helpers/searchOptions';
import { useFacilityServices } from 'hooks/useFacilityServices';
import { useQueryString } from 'hooks/useQueryString';
import { usePageTitle } from 'hooks/usePageTitle';
import { Helmet } from 'react-helmet';
import { removeDuplicates, removeDuplicatesByUniqueKey } from 'utils/helpers';
import serviceLineGroupMapping from 'config/serviceLineGroupMappings';
import SeoImplemention from './facility/SeoImplemention';
import ServiceTabs from './ServiceTabs';
import CustomSelect from '../form/CustomSelect';
import './InventoryPage.scss';
import './InventoryTabs.scss';
import './FacilityInventoryPage.scss';
import { serviceLineGroupsToMapVOC } from '../../config/searchConstants';
import useSettings from 'hooks/useSettings';

export function FacilityInventoryPage({
  match,
  loading,
  selectedParams,
  fetchHealthSystem,
  healthSystem,
}) {
  const {
    facilityServices,
    serviceIdsState,
    specialServices,
  } = useFacilityServices();
  const {
    pathname,
    params,
    setQueryParamsFromObject,
    isDischargePage,
    urlParams,
    search,
  } = useQueryString();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.only('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.only('md'));
  const isLgDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const uxMode = params.ux_mode || 'educational';
  const [showResultsSection, setShowResultsSection] = useState(false);
  const [hasAppointmentTypeIdOnLoad, setHasAppointmentTypeIdOnLoad] = useState(
    !!params.appointmentTypeId,
  );
  const [isScheduleCall, setIsScheduleCall] = useState(false);
  const [title] = useState(document.title);
  const [pageTitle, setPageTitle] = useState('');
  const [providerCount, setProviderCount] = useState(0);
  const [sortBy, setSortBy] = useState(
    params.sort || healthSystem['sorting-order'] || 'nearest',
  );
  const [page, setPage] = useState(1);
  const [whoIsScrolling, setWhoIsScrolling] = useState();
  const [scrollLeft, setScrollLeft] = useState({
    left: '0',
    fromComponent: 'first',
  });
  const [showMapView, setShowMapView] = useState(params.viewName === 'map');
  const [facilityId, setFacilityId] = useState();
  const [t] = useTranslation();
  const { branding } = useContext(BrandingContext);
  const resultsInitialState = {
    data: [],
    included: [],
    links: {},
    meta: {},
  };
  const [selectedPagesOfServices, setSelectedPagesOfServices] = useState({});
  const [
    totalPagesForSelectedServices,
    setTotalPagesForSelectedServices,
  ] = useState({});
  const [countForSelectedServices, setCountForSelectedServices] = useState({});
  const [results, setResults] = useState(resultsInitialState);
  const [oneTime, setOneTime] = useState(false);
  const [
    locationServiceLinesResults,
    setLocationServiceLinesResults,
  ] = useState({
    data: [],
  });
  const resultsNew = useSelector(state => {
    return state.scheduleReducer.results;
  });

  const servicesReducer = useSelector(state => {
    return state?.serviceLines;
  });
  const [sortOptionChanged, setSortOptionChanged] = useState(false);
  const [urgentCareListReserved, setUrgentCareListReserved] = useState();
  const [withoutAppointmentResults, setWithoutAppointmentResults] = useState();

  useEffect(() => {
    if (oneTime) {
      setResults(resultsNew);
      setOneTime(false);
    }
  }, [resultsNew]);

  const [storedLocations, setStoredLocations] = useState();
  const [storedProviders, setStoredProviders] = useState();
  const [filteredResults, setFilteredResults] = useState({});
  const [datesLoading, setLoading] = useState(true);
  const [numberOfDays, setNumberOfDays] = useState();
  const [date, setDate] = useState();
  const [dates, setDates] = useState([]);

  const [serviceResults, setServiceResults] = useState({});
  const [facilityAppointmentTypes, setFacilityAppointmentTypes] = useState({});
  const [
    facilityAppointmentTypesMobile,
    setFacilityAppointmentTypesMobile,
  ] = useState([]);
  const [
    selectedFacilityAppointmentType,
    setSelectedFacilityAppointmentType,
  ] = useState({});

  const [
    selectedFacilityAppointmentTypeMobile,
    setSelectedFacilityAppointmentTypeMobile,
  ] = useState(params?.appointmentTypeId || '');
  const [showAppointmentTypeError, setShowAppointmentTypeError] = useState(
    false,
  );

  const [groupCServices, setGroupCServices] = useState([]);
  const [listLoading, setListLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(null);
  const checkActiveTab = () => activeTab !== null || activeTab !== undefined;
  const [tabIndex, setTabIndex] = useState(0);
  const [limit, setLimit] = useState(0);
  const [resetFilter, setResetFilter] = useState(false);
  const [hideUrgentCareAppointmentType] = useState(false);
  localStorage.setItem('isFacilityPage', `${!isDischargePage}`);
  localStorage.setItem(
    'facilityPage',
    urlParams.dischargeFacility || urlParams.facilityName,
  );
  const [pageHasChanged, setPageHasChanged] = useState(false);
  const [inactive] = useState(params.inactive === 'true');
  const facResults = useSelector(
    state => state.facilitiesReducer.availableFacilities,
  );
  const [facilityResults, setFacilityResults] = useState({});
  const groupC = isGroupC(uxMode, params.service);

  const [omitTypeServices, setOmitTypeServices] = useState({});
  const isInOmitTypeList = serviceLineId => {
    return Object.keys(omitTypeServices).includes(serviceLineId);
  };
  const [servcieGroups, setServiceGroups] = useState([]);
  const [serviceTabs, setServiceTabs] = useState([]);
  const [locationId, setLocationId] = useState();

  const availableScheduleDays = useSettings({
    healthSystemId: healthSystem.id,
  });


  const checkPrimaryAndSpecialityServices = tabs => {
    const servicesAvailable = specialServices
      .map(specialService => {
        if (tabs.includes(Number(specialService.id)))
          return Number(specialService.id);
      })
      .filter(serviceid => serviceid !== undefined);
    if (servicesAvailable.length === 2) {
      const svcTabs = tabs.filter(el => !servicesAvailable.includes(el));
      return [0, ...svcTabs];
    }
    return tabs;
  };
  const checkPrimaryAndSpecialityServicesLocation = (
    tabs,
    specialServicesLocation,
  ) => {
    const servicesAvailable = specialServicesLocation
      .map(specialService => {
        if (tabs.includes(specialService.id)) return specialService.id;
      })
      .filter(serviceid => serviceid !== undefined);
    if (servicesAvailable.length === 2) {
      const svcTabs = tabs.filter(el => !servicesAvailable.includes(el));
      return ['0', ...svcTabs];
    }
    return tabs;
  };
  /* SEO Page Meta Information Declation Start */
  const [facilityPageTitle, setFacilityPageTitle] = useState();
  const [facilityPageDescription, setFacilityPageDescription] = useState();
  const [facilityPageDesc1, setFacilityPageDesc1] = useState();
  const [facilityPageDesc2, setFacilityPageDesc2] = useState();
  const [type, setType] = useState();
  const [MediaContentBlockBottom, setMediaContentBlockBottom] = useState();
  const [MediaContentBlockTop, setMediaContentBlockTop] = useState();
  const [locationDetails, setLocationDetails] = useState({
    name: '',
    city: '',
    state: '',
  });
  const getCurrentServiceName = tab => {
    return facilityServices[tab]?.attributes?.name;
  };

  const [MediaTitleTop, setMediaTitleTop] = useState();
  const getCurrentServicePermalink = tab => {
    return facilityServices[tab]?.attributes?.permalink;
  };
  useEffect(() => {
    if (results?.included?.length > 0) {
      const sLocs = results?.included?.filter(t => t.type === 'locations');
      const locObj = {};
      sLocs.forEach(s => {
        locObj[s.id] = s;
      });
      setStoredLocations({ ...storedLocations, ...locObj });
      const sPros = results?.included?.filter(t => t.type === 'providers');
      const proObj = {};
      sPros.forEach(p => {
        proObj[p.id] = p;
      });
      setStoredProviders({ ...storedProviders, ...proObj });
    }
    if (results?.data?.length === 0) {
      if (filteredResults[params.service]?.length > 0) {
        if (
          params.service !== undefined &&
          groupCServices.includes(getCurrentServicePermalink(params.service)) &&
          !isEmpty(selectedFacilityAppointmentType[params.service]) &&
          !isMobile
        ) {
          // setFilteredResults({
          //   ...filteredResults,
          //   [params.service]: [
          //     ...filteredResults[params.service]?.filter(
          //       result => !result.attributes['appointment-types'].length,
          //     ),
          //   ],
          // });
        } else {
          setFilteredResults({
            ...filteredResults,
            [params.service]: undefined,
          });
        }
      }
    }
  }, [results]);

  // This is the useEffect to populate the records on Group_C and Group_B without appointment types.
  useEffect(() => {
    if (withoutAppointmentResults?.included?.length > 0) {
      const sLocs = withoutAppointmentResults?.included?.filter(
        t => t.type === 'locations',
      );
      const locObj = {};
      sLocs.forEach(s => {
        locObj[s.id] = s;
      });
      setStoredLocations({ ...storedLocations, ...locObj });
      const sPros = withoutAppointmentResults?.included?.filter(t => t.type === 'providers');
      const proObj = {};
      sPros.forEach(p => {
        proObj[p.id] = p;
      });
      setStoredProviders({ ...storedProviders, ...proObj });
    }
    if (withoutAppointmentResults?.data?.length === 0) {
      if (filteredResults[params.service]?.length > 0) {
        if (
          params.service !== undefined &&
          groupCServices.includes(getCurrentServicePermalink(params.service)) &&
          !isEmpty(selectedFacilityAppointmentType[params.service]) &&
          !isMobile
        ) {
          // setFilteredResults({
          //   ...filteredResults,
          //   [params.service]: [
          //     ...filteredResults[params.service]?.filter(
          //       result => !result.attributes['appointment-types'].length,
          //     ),
          //   ],
          // });
        } else {
          setFilteredResults({
            ...filteredResults,
            [params.service]: undefined,
          });
        }
      }

      setSelectedPagesOfServices({
        ...selectedPagesOfServices,
        [params.service]: 1,
      });
    }
  }, [withoutAppointmentResults]);

  /* SEO Page Meta Information Declation End */
  useEffect(() => {
    const fetchLocationDetail = async locationPermalink => {
      try {
        await setLoading(true);
        const data = await getLocationByPermalink(locationPermalink);
        setLocationServiceLinesResults(
          data.included.filter(data => data.type === 'service-lines'),
        );
        setLocationId(data.data[0].id);
        setLocationDetails(data.data[0]?.attributes);
        const serviceLineIds = facResults?.data?.[0]?.attributes?.[
          'access-points'
        ]
          ?.filter(a => Number(a?.location_id) === Number(data?.data?.[0]?.id))
          .map(m => m?.service_line_id);
        const mergeToProviderServicesIds = isMobile
          ? ['specialty-care']
          : ['primary-care', 'specialty-care'];
        const specialServicesLocation = data.included
          .filter(data => data.type === 'service-lines')
          .filter(el =>
            mergeToProviderServicesIds.includes(el.attributes.permalink),
          );
        const refinedTabs = checkPrimaryAndSpecialityServicesLocation(
          uniq(serviceLineIds),
          specialServicesLocation,
        );
        setServiceTabs(refinedTabs);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      } finally {
        await setLoading(false);
      }
    };

    // Check if locationPermalink has already been fetched
    const locationPermalinkFetched =
      Object.values(facilityServices).filter(
        e => e.attributes.permalink === urlParams.locationPermalink,
      ).length > 0;

    // Fetch locationDetail only if it hasn't been fetched yet
    if (
      urlParams.locationPermalink &&
      !locationPermalinkFetched &&
      Object.keys(facilityServices).length > 0
    ) {
      if (facResults?.included?.length > 0) {
        const location = facResults?.included?.filter(
          res =>
            res.type === 'locations' &&
            res.attributes.permalink === urlParams.locationPermalink,
        );
        if (location.length === 0) {
          setLocationId('not-found');
        } else {
          fetchLocationDetail(urlParams.locationPermalink);
        }
      }
    }
  }, [urlParams.locationPermalink, facilityServices]);

  useEffect(() => {
    // weather locationPermalink is location or service
    // if service it will redirect to the correct uri
    if (
      urlParams.locationPermalink &&
      Object.values(facilityServices).filter(
        e => e.attributes.permalink === urlParams.locationPermalink,
      ).length > 0
    ) {
      const service = Object.values(facilityServices).filter(
        e => e.attributes.permalink === urlParams.locationPermalink,
      );
      history.push({
        pathname: pathname.replaceAll(
          service[0].attributes.permalink,
          `service/${service[0].attributes.permalink}`,
        ),
        search:
          search.length === 0
            ? `?service=${service[0].id}`
            : search.includes('inactive=true')
              ? `${search}&service=${service[0].id}`
              : `?service=${service[0].id}`,
        state: {
          servicePermalink: true,
          service: service[0].attributes.permalink,
        },
      });
    }
    if (
      urlParams.locationPermalink &&
      urlParams.serviceLine &&
      !pathname.includes('service') &&
      Object.values(facilityServices).length > 0
    ) {
      const service = Object.values(facilityServices).filter(
        e => e.attributes.permalink === urlParams.serviceLine,
      );
      history.push({
        pathname: pathname.replaceAll(
          service[0].attributes.permalink,
          `service/${service[0].attributes.permalink}`,
        ),
        search:
          search.length === 0
            ? `?service=${service[0].id}`
            : search.includes('inactive=true')
              ? `${search}&service=${service[0].id}`
              : `?service=${service[0].id}`,
        state: {
          servicePermalink: true,
          service: service[0].attributes.permalink,
        },
      });
    }

    if (
      urlParams.locationPermalink &&
      urlParams.serviceLine &&
      !pathname.includes('service') &&
      Object.values(facilityServices).length > 0
    ) {
      const service = Object.values(facilityServices).filter(
        e => e.attributes.permalink === urlParams.serviceLine,
      );
      history.push({
        pathname: pathname.replaceAll(
          service[0].attributes.permalink,
          `service/${service[0].attributes.permalink}`,
        ),
        search:
          search.length === 0
            ? `?service=${service[0].id}`
            : search.includes('inactive=true')
              ? `${search}&service=${service[0].id}`
              : `?service=${service[0].id}`,
        state: {
          servicePermalink: true,
          service: service[0].attributes.permalink,
        },
      });
    }
  }, [facilityServices]);
  // Ensure to set the serviceTabs when redirecting to service pages
  const [serviceLinePage, setServiceLinePage] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (location?.state?.servicePermalink) setServiceLinePage(true);
  }, [location?.state]);

  useEffect(() => {
    if (Object.keys(facResults).length > 0 && facResults?.data?.length === 0) {
      setFacilityId('not-found');
    }
    if (
      Object.keys(facResults).length &&
      Object.keys(serviceIdsState)?.length > 0
    ) {
      const locations = facResults?.included?.filter(
        res => res.type === 'locations',
      );

      if (locations.length)
        setStoredLocations({ [locations?.[0]?.id]: locations?.[0] });

      if (!urlParams.locationPermalink) {
        const serviceLineIds = facResults?.data?.[0]?.attributes?.[
          'access-points'
        ]?.map(m => m?.service_line_id);
        const refinedTabs = checkPrimaryAndSpecialityServices(
          uniq(serviceLineIds),
        );
        setServiceTabs(refinedTabs);
      }

      if (!urlParams.locationPermalink) {
        // This logic is to Show All the service Tabs which has discharge Context HS Level
        if (isDischargePage) {
          if (Object.keys(servicesReducer?.serviceLines)?.length > 0) {
            let serviceLineIds = Object.keys(servicesReducer?.serviceLines);
            serviceLineIds = serviceLineIds.map(str => {
              return Number(str);
            });
            const refinedTabs = checkPrimaryAndSpecialityServices(
              uniq(serviceLineIds),
            );
            setServiceTabs(refinedTabs);
          }
        } else {
          const serviceLineIds = facResults.data[0].attributes[
            'access-points'
          ].map(m => m.service_line_id);
          const refinedTabs = checkPrimaryAndSpecialityServices(
            uniq(serviceLineIds),
          );
          setServiceTabs(refinedTabs);
        }
      }
      setFacilityResults(facResults);
    }
  }, [facResults, serviceIdsState, serviceLinePage]);

  const [serviceDates, setServiceDates] = useState({});
  const [serviceDatesOnAppointment, setServiceDatesOnAppointment] = useState({});
  const [
    serviceDatesOnAppointmentMobile,
    setServiceDatesOnAppointmentMobile,
  ] = useState({});
  useEffect(() => {
    const momentDateToday = moment()
      .tz(healthSystem['time-zone'])
      .format();

    if (
      params.service &&
      !Object.keys(serviceDates).includes(params.service) &&
      numberOfDays
    ) {
      setServiceDates(prev => ({
        ...prev,
        [Number(params.service)]: {
          date: momentDateToday,
          dates: groupedDays(
            momentDateToday,
            numberOfDays,
            healthSystem['time-zone'],
          ),
        },
      }));
    }
    if (
      params.service &&
      !serviceDatesOnAppointment?.[params.service]?.[
      params.appointmentTypeId
      ] &&
      numberOfDays &&
      params.appointmentTypeId
    ) {
      setServiceDatesOnAppointment(prev => {
        const updatedServiceDates = { ...prev };
        if (!updatedServiceDates[params.service]) {
          updatedServiceDates[params.service] = {};
        }

        updatedServiceDates[params.service][params.appointmentTypeId] = {
          date: momentDateToday, // Assuming momentDateToday is already defined with the desired value
          dates: groupedDays(
            momentDateToday,
            numberOfDays, // Assuming numberOfDays is defined with the desired value
            healthSystem['time-zone'] // Assuming healthSystem object is defined and has a 'time-zone' property
          ),
        };
        console.log(updatedServiceDates, ' serviceDates');
        return updatedServiceDates;
      });
    }

    if (
      !serviceDatesOnAppointmentMobile?.[
      params.appointmentTypeId
      ] &&
      numberOfDays &&
      params.appointmentTypeId &&
      isMobile
    ) {
      setServiceDatesOnAppointmentMobile(prev => {
        const updatedServiceDates = { ...prev };
        updatedServiceDates[params.appointmentTypeId] = {
          date: momentDateToday,
          dates: groupedDays(
            momentDateToday,
            numberOfDays,
            healthSystem['time-zone']
          ),
        };
        return updatedServiceDates;
      });
    }
  }, [
    activeTab,
    numberOfDays,
    dates,
    params.service,
    params.appointmentTypeId,
  ]);

  useEffect(() => {
    if (
      facResults?.data?.length > 0 &&
      facResults?.data[0]?.attributes.permalink !== urlParams.facilityName
    ) {
      (async () => {
        const filterObj = {
          'filter[permalink]':
            match.params.facilityName ||
            urlParams.dischargeFacility ||
            urlParams.facilityName,
          'filter[context]': isDischargePage ? 'discharge' : 'patient',
          include: 'access-points,locations',
        };
        await dispatch(getAvailableFacilities(filterObj));

      })();
    }
  }, []);

  useEffect(() => {
    if (!Object.keys(healthSystem).length > 0) {
      fetchHealthSystem();
    }
    (async () => {
      if (!Object.keys(facResults).length > 0) {
        const filterObj = {
          'filter[permalink]':
            match.params.facilityName ||
            urlParams.dischargeFacility ||
            urlParams.facilityName,
          'filter[context]': isDischargePage ? 'discharge' : 'patient',
          include: 'access-points,locations',
        };
        await dispatch(getAvailableFacilities(filterObj));
      }
    })();
  }, []);

  const [facTimezone, setfacTimezone] = useState();

  useEffect(() => {
    if (facResults?.data?.length > 0) {
      const timezone = facResults?.included?.find(i => i.type === 'locations')
        ?.attributes['time-zone'];
      setfacTimezone(timezone);
      setDate(
        moment()
          .tz(timezone)
          .format(),
      );
    }
  }, [facResults]);

  useEffect(() => {
    if (isTablet) {
      setNumberOfDays(5);
    }

    if (isDesktop) {
      setNumberOfDays(5);
    }

    if (isLgDesktop) {
      setNumberOfDays(5);
    }

    if (isMobile) {
      setNumberOfDays(1);
      if (date) setDates(groupedDays(date, 1, facTimezone));
    }
  }, [isTablet, isDesktop, isLgDesktop, isMobile]);

  const loadServices = useCallback(() => {
    if (!Object.keys(servicesReducer?.serviceLines)?.length > 0) {
      if (isDischargePage) {
        dispatch(loadDischargeActiveServices());
      } else {
        dispatch(loadActiveServices());
      }
    }
  }, [isDischargePage, servicesReducer, dispatch]);

  useEffect(() => {
    if (numberOfDays && date && dates.length === 0) loadServices();

    if (date && dates.length === 0)
      setDates(groupedDays(date, numberOfDays, healthSystem['time-zone']));
  }, [
    numberOfDays,
    date,
    isDischargePage,
    servicesReducer,
    groupedDays,
    healthSystem,
    dispatch,
    loadServices,
  ]);

  useEffect(() => {
    if (healthSystem && Object.keys(healthSystem).length) {
      if (availableScheduleDays) {
        setLimit(availableScheduleDays);
      }
    }
  }, [availableScheduleDays, healthSystem]);

  const getBasicOptions = selectedParams => {
    const searchOptions = {
      'filter[active]': inactive === true ? 'false' : 'true',
      'filter[radius]': healthSystem['search-radius'] || 50,
      include: 'location,provider,service,facility',
    };

    if (
      selectedParams.appointmentTypeId &&
      shouldShowSearchElement(
        uxMode,
        params.service,
        searchConstants.APP_TYPE_INPUT,
      ) &&
      !groupC
    ) {
      searchOptions['filter[appointment_type]'] =
        selectedParams.appointmentTypeId;
    }

    // If we don't manually set these here, they won't get included
    // during the transition from 'now' to 'later', resulting in
    // unexpected result. This at least maintains consistency with
    // the 'manual' user-initiated search.
    searchOptions.from = selectedParams.date
      ? selectedParams.date.start
      : moment()
        .tz(healthSystem['time-zone'])
        .format();
    searchOptions.check_availability = sortBy === 'soonest';

    searchOptions.to = selectedParams.date
      ? selectedParams.date.end
      : moment()
        .tz(healthSystem['time-zone'])
        .add(limit, 'days')
        .format();

    return searchOptions;
  };

  // eslint-disable-next-line no-unused-vars
  const onScroll = (dateSliderRef, fromComponent) => {
    if (dateSliderRef.current.id === whoIsScrolling) {
      setScrollLeft({
        left: dateSliderRef.current.scrollLeft,
        fromComponent: whoIsScrolling,
      });
    }
  };

  const getResultsByService = (results, servicesIdObject = null) => {
    const sortedResults = {};
    const idsObject = servicesIdObject || serviceIdsState;

    if (results?.data) {
      results.data.forEach(async result => {
        // eslint-disable-next-line no-restricted-syntax
        for (const [parentServiceId, childServiceIds] of Object.entries(
          idsObject,
        )) {
          const resultServiceId = result?.attributes?.service?.id?.toString();
          const parentServiceIdString = parentServiceId.toString();

          if (
            resultServiceId === parentServiceIdString ||
            childServiceIds.indexOf(resultServiceId) > -1
          ) {
            if (!sortedResults[parentServiceIdString]) {
              sortedResults[parentServiceIdString] = [];
            }

            sortedResults[parentServiceIdString] = [
              ...sortedResults[parentServiceIdString],
              result,
            ];
          }
        }
      });
    }
    if (sortedResults[activeTab])
      sortedResults[activeTab] = removeDuplicatesByUniqueKey(
        sortedResults[activeTab],
        'id',
      );
    return sortedResults;
  };

  const updateResultsByAppointmentType = (
    type,
    withoutAppointmentResults = null,
  ) => {
    const sortedResults = getResultsByService(
      withoutAppointmentResults === null ? results : withoutAppointmentResults,
    );
    const urgentCareList = [];

    if (isMobile) {
      const idsObject = {};

      Object.keys(facilityServices).forEach(id => {
        if (
          facilityServices[id] &&
          groupCServices.includes(facilityServices[id].attributes.permalink)
        ) {
          urgentCareList.push(
            sortedResults[id?.toString()]?.filter(
              result => !result.attributes['appointment-types'].length,
            ),
          );
        }

        if (isInOmitTypeList(id) && serviceIdsState[id]) {
          idsObject[id] = serviceIdsState[id];
        }
      });

      const erObject = getResultsByService(results, idsObject);
      let appointmentResults = [];
      if (!isEmpty(type)) {
        appointmentResults = results.data.filter(result => {
          return result.attributes['appointment-types'].filter(appointment => {
            return type.toString() === appointment.id.toString();
          }).length;
        });
      }
      const schedules = {
        ...erObject,
        ...getResultsByService({
          ...results,
          data: [...appointmentResults, ...urgentCareList.flat()],
        }),
      };
      // To filter the records when appointmentType is empty remove the records which contain appointment type.
      if (isEmpty(type)) {
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const schedule in schedules) {
          schedules[schedule] = schedules[schedule].filter(
            el => !el.attributes['appointment-types'].length,
          );
        }
      } else if (!isEmpty(type)) {
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const schedule in schedules) {
          schedules[schedule] = schedules[schedule].filter(
            el =>
              !el.attributes['appointment-types'].length ||
              (el.attributes['appointment-types'].length > 0 &&
                el.attributes['appointment-types']
                  .map(a => a.id)
                  .includes(Number(type))),
          );
        }
      }
      return schedules;
    }
    return sortedResults;
  };

  useEffect(() => {
    setResetFilter(false);
    // @note this code block is 99% a carbon copy of filteredResults
    // @todo check and see if we actually need this block. if not, use filteredResults
    const servicesWithNoAppointments = {};
    if (Object.keys(filteredResults).length) {
      const omittedServicesCount = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const [serviceId, schedules] of Object.entries(filteredResults)) {
        if (isInOmitTypeList(serviceId) && schedules) {
          servicesWithNoAppointments[serviceId] = null;
          const appointmentTypes = schedules.filter(
            schedule =>
              !(
                schedule.attributes &&
                !schedule.attributes['appointment-types'].length
              ),
          );
          // Schedules without Appointment type
          if (appointmentTypes.length) {
            servicesWithNoAppointments[serviceId] = appointmentTypes;
          }
          omittedServicesCount[serviceId] = filteredResults[serviceId].length;

          if (
            groupCServices.includes(getCurrentServicePermalink(serviceId)) &&
            !isEmpty(selectedFacilityAppointmentType[serviceId]) &&
            !isMobile
          ) {
            omittedServicesCount[serviceId] = filteredResults[serviceId].length;
          } else if (
            groupCServices.includes(getCurrentServicePermalink(serviceId)) &&
            isEmpty(selectedFacilityAppointmentType[serviceId]) &&
            !isMobile
          ) {
            omittedServicesCount[serviceId] = [
              ...filteredResults[serviceId]?.filter(
                result => !result.attributes['appointment-types'].length,
              ),
            ]?.length;
          }
        }
      }
      delete omittedServicesCount[params.service];
      setCountForSelectedServices({
        ...countForSelectedServices,
        ...omittedServicesCount,
      });
      setServiceResults({
        ...serviceResults,
        ...servicesWithNoAppointments,
      });
    }
  }, [filteredResults]);

  const countCheck = filteredRecords => {
    return (
      (countForSelectedServices[params.service] !== filteredRecords.length &&
        Object.keys(countForSelectedServices).length > 1) ||
      (countForSelectedServices[params.service] !== filteredRecords.length &&
        [1, 2].includes(Object.keys(omitTypeServices).length))
    );
  };

  useEffect(() => {
    if (
      Object.keys(omitTypeServices).includes(params.service) &&
      filteredResults[params?.service]?.length &&
      !isMobile
    ) {
      const pages = Math.ceil(filteredResults[params.service].length / 10);
      if (
        listLoading === false &&
        loading === false &&
        withoutAppointmentResults?.data?.length &&
        groupCServices.includes(getCurrentServicePermalink(params.service))
      ) {
        let filteredRecords = filteredResults[activeTab]?.filter(
          result => !result.attributes['appointment-types'].length,
        );
        if (
          selectedFacilityAppointmentType[activeTab] &&
          selectedFacilityAppointmentType[activeTab]?.length
        ) {
          const records = filteredResults[activeTab]?.filter(result => {
            return result.attributes['appointment-types'].filter(
              appointment => {
                return (
                  params?.appointmentTypeId?.toString() ===
                  appointment?.id?.toString()
                );
              },
            ).length;
          });
          filteredRecords = filteredRecords.concat(records);
        }
        const pages = Math.ceil(filteredRecords.length / 10);
        setTotalPagesForSelectedServices({
          ...totalPagesForSelectedServices,
          [params.service]: pages,
        });

        if (countCheck(filteredRecords)) {
          setCountForSelectedServices({
            ...countForSelectedServices,
            [params.service]: filteredRecords.length,
          });
        }
        return;
      }

      if (omitTypeServices[params.service] === 'GROUP_B') {
        if (!Object.keys(countForSelectedServices).includes(params.service)) {
          setCountForSelectedServices(prev => {
            return {
              ...prev,
              [params.service]: filteredResults[activeTab].length,
            };
          });
        }
        setTotalPagesForSelectedServices({
          ...totalPagesForSelectedServices,
          [params.service]: pages,
        });
      }
    }
  }, [
    filteredResults,
    withoutAppointmentResults,
    params.service,
    params.appointmentTypeId,
    listLoading,
  ]);

  useEffect(() => {
    if (
      checkActiveTab() &&
      Object.keys(selectedFacilityAppointmentType).length > 0
    ) {
      setShowAppointmentTypeError(
        selectedFacilityAppointmentType[activeTab] === '',
      );
      setQueryParamsFromObject({
        appointmentTypeId: selectedFacilityAppointmentType[activeTab],
      });
    }
    if (isMobile) {
      setShowAppointmentTypeError(selectedFacilityAppointmentTypeMobile === '');
      if (!isEmpty(selectedFacilityAppointmentTypeMobile))
        setQueryParamsFromObject({
          appointmentTypeId: selectedFacilityAppointmentTypeMobile,
        });
    }
  }, [
    activeTab,
    selectedFacilityAppointmentType,
    selectedFacilityAppointmentTypeMobile,
  ]);

  useEffect(() => {
    if (isMobile && params.service) {
      // eslint-disable-next-line no-use-before-define
      loadInitialSchedules(servcieGroups.flat(), true).then(res => {
        const obj = {};
        // eslint-disable-next-line array-callback-return
        Object.keys(omitTypeServices).map(id => {
          obj[id] = 1;
        });
        setSelectedPagesOfServices({
          ...selectedPagesOfServices,
          ...obj,
        });
        setTotalPagesForSelectedServices({
          ...totalPagesForSelectedServices,
          ...obj,
        });
        if (isMobile) {
          if (res.data.length === 0) setListLoading(false);
          setIsScheduleCall(false);
          setListLoading(false);
        }
      });
    }
  }, [selectedFacilityAppointmentTypeMobile]);

  // schedule API call for appointment type change
  const loadScheduleData = async (serviceId = null, pageNumber = null) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async resolve => {
      if (isScheduleCall) {
        setListLoading(true);
        const searchOptions = getBasicOptions(selectedParams);
        const searchParams = new URLSearchParams(searchOptions);
        const parentIds = Object.keys(serviceIdsState);
        let { service } = params;
        if (Object.keys(omitTypeServices).includes(service) && !isMobile) {
          setListLoading(false);
          return resolve(resultsInitialState);
        }

        if (serviceId) service = serviceId;
        searchParams.append('filter[facility_id]', facilityId);

        if (isDischargePage) {
          searchParams.append('filter[context]', 'discharge');
          searchParams.append('filter[hidden]', true);
        } else {
          searchParams.append('filter[context]', 'patient');
          searchParams.append('filter[hidden]', false);
        }
        // Need to maintain the page number in state;
        if (sortBy === 'soonest') {
          searchParams.append(
            'page_number',
            pageNumber === null ? 1 : pageNumber,
          );
          searchParams.append('page_size', 10);
        }
        if (sortBy === 'nearest' || showMapView) {
          searchParams.append(
            'page[number]',
            pageNumber === null ? 1 : pageNumber,
          );
          searchParams.append('page[size]', 10);
        }
        if (params.service) {
          // eslint-disable-next-line camelcase
          const appt_id =
            selectedFacilityAppointmentType[service] ||
            params.appointmentTypeId;
          if (!isEmpty(appt_id)) {
            searchParams.append('filter[appointment_type]', appt_id);
          }
        }

        if (sortBy) {
          searchParams.append('sort', sortBy);
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const parentServiceId of parentIds) {
          if (parentServiceId.toString() === service.toString()) {
            serviceIdsState[parentServiceId].forEach(id => {
              searchParams.append('filter[service_id][]', id);
            });
          }
        }

        if (isDischargePage) {
          searchParams.delete('filter[facility_id]');
          searchParams.append('filter[context]', 'discharge');
        }

        if (urlParams.locationPermalink && locationId) {
          searchParams.append('filter[location_id]', locationId);
        }

        const response = await fetch(
          getEndpoint(`schedules`, searchParams),
          getHeaderInfo(),
        );

        const results = await response.json();
        if (results?.meta['record-count']) {
          const pages = Math.ceil(results?.meta['record-count'] / 10);
          setTotalPagesForSelectedServices({
            ...totalPagesForSelectedServices,
            [service]: pages,
          });
          const omittedServicesCount = {};
          Object.keys(omitTypeServices).forEach(omitTypeService => {
            omittedServicesCount[omitTypeService] =
              filteredResults[omitTypeService]?.length;
          });
          setCountForSelectedServices({
            ...omittedServicesCount,
            ...countForSelectedServices,
            [service]: results?.meta['record-count'],
          });
          if (selectedPagesOfServices[activeTab] === undefined)
            setSelectedPagesOfServices({
              ...selectedPagesOfServices,
              [activeTab]: 1,
            });
        } else if (results?.meta['record-count'] === 0) {
          setCountForSelectedServices({
            ...countForSelectedServices,
            [params.service]: results?.meta['record-count'],
          });
        }
        setResults(results);
        resolve(results);
      }
    });
  };

  const handlePageChange = async pg => {
    if (Object.keys(omitTypeServices).includes(params.service)) {
      setSelectedPagesOfServices({
        ...selectedPagesOfServices,
        [activeTab]: pg,
      });
      return;
    }
    setPageHasChanged(true);
    setIsScheduleCall(true);
    setSelectedPagesOfServices({
      ...selectedPagesOfServices,
      [activeTab]: pg,
    });
  };
  const removeSchedulesWithAppointmentTypes = results => {
    return results?.data?.filter(
      d => d?.attributes['appointment-types']?.length === 0,
    );
  };
  const [withApptTypeMobile, setWithApptTypeMobile] = useState();
  const loadInitialSchedules = async (serviceIds = null, isAppt = false) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async resolve => {
      setListLoading(true);
      if (facilityId === undefined || facilityId === 'not-found') return;

      const searchOptions = getBasicOptions(selectedParams);
      const searchParams = new URLSearchParams(searchOptions);
      if (isDischargePage) {
        searchParams.append('filter[context]', 'discharge');
        searchParams.append('filter[hidden]', true);
      } else {
        // here
        searchParams.append('filter[context]', 'patient');
        searchParams.append('filter[hidden]', false);
      }
      searchParams.append('page_size]', 1000);
      if (sortBy === 'soonest') {
        searchParams.append('page_size', 1999);
        searchParams.append('page_number', 1);
      }
      if (sortBy === 'nearest') {
        searchParams.append('page[size]', 1999);
        searchParams.append('page[number]', 1);
      }

      searchParams.append('filter[facility_id]', facilityId);

      if (sortBy) {
        searchParams.append('sort', sortBy);
      }
      if (isMobile === false)
        // eslint-disable-next-line no-restricted-syntax
        for (const id of serviceIds) {
          searchParams.append('filter[service_id][]', id);
        }

      if (isDischargePage) {
        searchParams.delete('filter[facility_id]');
        searchParams.append('filter[context]', 'discharge');
      }

      if (urlParams.locationPermalink && locationId) {
        searchParams.append('filter[location_id]', locationId);
      }

      if (isAppt && isMobile) {
        // eslint-disable-next-line camelcase
        const appt_id =
          selectedFacilityAppointmentTypeMobile || params.appointmentTypeId;
        if (!isEmpty(appt_id)) {
          searchParams.append('filter[appointment_type]', appt_id);
        }
      }

      const response = await fetch(
        getEndpoint(`schedules`, searchParams),
        getHeaderInfo(),
      );

      const results = await response.json();
      if (isMobile) {
        if (!isAppt)
          setWithoutAppointmentResults({
            ...results,
            data: removeSchedulesWithAppointmentTypes(results),
          });
        if (isAppt) {
          setWithApptTypeMobile(results);
        }
      } else {
        setWithoutAppointmentResults({
          ...results,
        });
      }
      if (!isMobile)
        setUrgentCareListReserved({
          ...results,
          data: removeSchedulesWithAppointmentTypes(results),
        });
      resolve(results);
    });
  };

  useEffect(() => {
    if (withoutAppointmentResults && withApptTypeMobile && isMobile) {
      const res = removeDuplicates(
        withApptTypeMobile?.data?.concat(withoutAppointmentResults?.data),
        'id',
      );
      setResults({
        ...withoutAppointmentResults,
        data: res,
        meta: { 'record-count': res?.length },
      });
      setFilteredResults(
        updateResultsByAppointmentType(selectedFacilityAppointmentTypeMobile),
      );
      // console.log(res, 'setWithoutAppointmentResults');
    }

    if (
      withoutAppointmentResults &&
      selectedFacilityAppointmentTypeMobile === '' &&
      isMobile
    ) {
      setResults(withoutAppointmentResults);
    }
  }, [withApptTypeMobile, withoutAppointmentResults]);

  // call schedule  api on appointment type change
  useEffect(() => {
    if (isScheduleCall && facilityId) {
      (async () => {
        await loadScheduleData().then(res => {
          if (res.data.length === 0) setListLoading(false);
          setIsScheduleCall(false);
          setSortOptionChanged(false);
        });
      })();
    }
  }, [selectedFacilityAppointmentType]);

  // call schedule  api on appointment type change
  // Page state Change
  useEffect(() => {
    (async () => {
      if (pageHasChanged) {
        await loadScheduleData(
          activeTab,
          selectedPagesOfServices[activeTab],
        ).then(() => {
          setPageHasChanged(false);
        });
      }
    })();
  }, [selectedPagesOfServices[activeTab]]);
  useEffect(() => {
    const fetchData = async () => {
      if (sortOptionChanged) {
        setListLoading(true);
        if (servcieGroups.length > 0) {
          await loadInitialSchedules(servcieGroups.flat());
          const obj = Object.fromEntries(
            Object.keys(omitTypeServices).map(id => [id, 1]),
          );
          setSelectedPagesOfServices(prev => ({ ...prev, ...obj }));
          // setTotalPagesForSelectedServices(prev => ({ ...prev, ...obj }));
        }
        const servicesToFetchSchedules = Object.keys(filteredResults).filter(
          val => !Object.keys(omitTypeServices).includes(val),
        );
        const promises = servicesToFetchSchedules.map(service => {
          return loadScheduleData(service);
        });
        await Promise.all(promises);
        setListLoading(false);
        setIsScheduleCall(false);
        setSortOptionChanged(false);
        setSelectedPagesOfServices(prev => ({ ...prev, [activeTab]: 1 }));
      }
    };

    fetchData();
  }, [sortOptionChanged]);

  useEffect(() => {
    if (
      params.appointmentTypeId &&
      params.service &&
      Object.keys(filteredResults).length > 0 &&
      !showMapView &&
      hasAppointmentTypeIdOnLoad
    ) {
      if (isMobile) {
        setResetFilter(true);
        setSelectedFacilityAppointmentTypeMobile(
          params.appointmentTypeId.toString(),
        );

        setFilteredResults(
          updateResultsByAppointmentType(params.appointmentTypeId.toString()),
        );
        setShowResultsSection(true);
      } else {
        setSelectedFacilityAppointmentType({
          ...selectedFacilityAppointmentType,
          [params.service]: params.appointmentTypeId.toString(),
        });
        setServiceResults({
          ...serviceResults,
          [params.service]: params.appointmentTypeId.toString(),
        });
        setShowResultsSection(true);
      }
      setHasAppointmentTypeIdOnLoad(false);
    }
  }, [filteredResults]);
  useEffect(() => {
    if (!isMobile)
      if (params.appointmentTypeId) {
        setIsScheduleCall(true);
        setSelectedFacilityAppointmentType({
          ...selectedFacilityAppointmentType,
          [params.service]: params.appointmentTypeId.toString(),
        });
      }
  }, [params.appointmentTypeId]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  /**
   * Loads appointment types for the mobile view.
   */
  useEffect(() => {
    if (isMobile && !isEmpty(facilityId) && facilityId !== 'not-found') {
      (async () => {
        let params = '';
        let delim = '';
        // eslint-disable-next-line no-restricted-syntax
        for (const parentServiceId of serviceTabs) {
          // eslint-disable-next-line no-loop-func
          if (serviceIdsState[parentServiceId] !== undefined)
            // eslint-disable-next-line no-loop-func
            serviceIdsState[parentServiceId].forEach(id => {
              params += `${delim}filter[service][]=${id}`;
              delim = '&';
            });
        }

        const filterContext = isDischargePage
          ? '&filter[context]=discharge'
          : '';
        const filterFacility = isDischargePage
          ? ''
          : `&filter[facility]=${facilityId}`;

        const response = await fetch(
          getEndpoint(
            `appointment-types?page[size]=999${filterContext}${filterFacility}&${params}`,
          ),
          getHeaderInfo(),
        );
        const res = await response.json();
        const formattedDefaultAppointmentTypes = res?.data.map(at => {
          return { value: at.id, text: at.attributes.name };
        });
        setFacilityAppointmentTypesMobile(formattedDefaultAppointmentTypes);
      })();
    }
  }, [facilityId, resultsNew, isMobile]);

  const serviceTabGroup = serviceId => {
    const groupName =
      serviceLineGroupMapping[
      facilityServices[serviceId]?.attributes?.permalink
      ];
    return groupName;
  };

  const mcaEvent = appointmentTypeId => {
    const groupName =
      serviceLineGroupMapping[
      facilityServices[params.service]?.attributes?.permalink
      ] || 'GROUP_D';
    const VOC = serviceLineGroupsToMapVOC.filter(t => t[groupName])[0][
      groupName
    ];
    const payload = {
      searchType: 'facilityPage',
    };
    if (appointmentTypeId !== undefined && appointmentTypeId !== '') {
      localStorage.setItem('voc', VOC);
      localStorage.setItem('appointmentTypeId', appointmentTypeId);
      createFacilityEvent(payload, VOC, appointmentTypeId, params.service);
    }
  };

  useEffect(() => {
    const grouping = [];
    const groupCServicesParmalinks = [];
    if (Object.keys(serviceIdsState).length > 0 && serviceTabs.length > 0) {
      serviceTabs.forEach(serviceLineId => {
        const group = serviceTabGroup(serviceLineId);
        if (group === 'GROUP_B' || group === 'GROUP_C') {
          omitTypeServices[serviceLineId] = group;
          grouping.push(serviceIdsState[serviceLineId]);
          if (group === 'GROUP_C')
            groupCServicesParmalinks.push(
              facilityServices[serviceLineId]?.attributes?.permalink,
            );
        }
      });
      setGroupCServices(groupCServicesParmalinks);
      setServiceGroups(grouping);
      setOmitTypeServices(omitTypeServices);
      if (!params.service) {
        setActiveTab(serviceTabs[0]);
        setQueryParamsFromObject({ service: serviceTabs[0].toString() });
      }
    }
  }, [serviceIdsState, serviceTabs]);

  useEffect(() => {
    /*
      For Mobile View Sometime if no service fall under Group_C, Group_B serviceGroups will be empty.
      In that case we need to check if serviceTabs are available or not.
    */
    if (facilityId === undefined || facilityId === 'not-found') return;
    if (servcieGroups.length > 0 || (isMobile && serviceTabs.length > 0)) {
      loadInitialSchedules(servcieGroups.flat()).then(res => {
        const obj = {};
        Object.keys(omitTypeServices).map(id => {
          obj[id] = 1;
        });
        setSelectedPagesOfServices({
          ...selectedPagesOfServices,
          ...obj,
          // [params.service]: 1,
        });
        if (isMobile) {
          setTotalPagesForSelectedServices({
            ...totalPagesForSelectedServices,
            ...obj,
          });
          if (res.data.length === 0) setListLoading(false);
          setIsScheduleCall(false);
          setListLoading(false);
        }
      });
      if (isMobile && params.service && params.appointmentTypeId) {
        loadInitialSchedules(servcieGroups.flat(), true).then(res => {
          const obj = {};
          // eslint-disable-next-line array-callback-return
          Object.keys(omitTypeServices).map(id => {
            obj[id] = 1;
          });
          setSelectedPagesOfServices({
            ...selectedPagesOfServices,
            ...obj,
          });
          setTotalPagesForSelectedServices({
            ...totalPagesForSelectedServices,
            ...obj,
          });
          if (isMobile) {
            if (res?.data?.length === 0) setListLoading(false);
            setIsScheduleCall(false);
            setListLoading(false);
          }
        });
      }
      if (!isMobile) {
        // This will load schedules that does not require appointment type
        setIsScheduleCall(true);
        loadScheduleData().then(res => {
          if (res.data.length === 0) setListLoading(false);
          setIsScheduleCall(false);
        });
      }
    }
  }, [servcieGroups, facilityId]);

  const [accessPoints, setAccessPoints] = useState();
  const getAccessPointsAPI = async () => {
    try {
      const filterContext = isDischargePage
        ? '?filter[context]=discharge'
        : '?filter[context]=patient';
      const response = await fetch(
        getEndpoint(`access-points${filterContext}`),
        getHeaderInfo(),
      );
      const res = await response.json();
      return res.data;
    } catch (err) {
      console.error('In error', err);
    }
  };

  useEffect(() => {
    (async () => {
      if (isDischargePage) {
        const ap = await getAccessPointsAPI();
        setAccessPoints(ap);
      }
    })();
  }, []);

  const getAccessPoints = () => {
    const accessPointsIds = facResults?.data[0]?.attributes?.[
      'access-points'
    ]?.map(m => m?.id?.toString());
    return facResults?.included?.filter(
      res =>
        res.type === 'access-points' &&
        accessPointsIds.includes(res.id.toString()),
    );
  };

  useEffect(() => {
    if (Object.keys(facResults).length && !isDischargePage) {
      // eslint-disable-next-line no-use-before-define
      setAccessPoints(getAccessPoints());
    }
  }, [facResults]);

  const [appointmentTypes, setAppointmentTypes] = useState();
  const [types, setTypes] = useState({});

  const getAppointmentTypes = async () => {
    let params = '';
    let delim = '';
    const outputResult = {
      appointmentTypes: {},
      selectedAppointmentTypes: {},
    };
    // eslint-disable-next-line no-restricted-syntax
    for (const parentServiceId of serviceTabs) {
      // eslint-disable-next-line no-loop-func
      if (serviceIdsState[parentServiceId] !== undefined)
        // eslint-disable-next-line no-loop-func
        serviceIdsState[parentServiceId].forEach(id => {
          params += `${delim}filter[service][]=${id}`;
          delim = '&';
        });
      if (
        serviceIdsState[parentServiceId] &&
        !Object.keys(facilityAppointmentTypes).length
      ) {
        // Selected Appointment types for each
        outputResult.selectedAppointmentTypes = {
          ...outputResult.selectedAppointmentTypes,
          [parentServiceId]: null,
        };
      }
    }

    const filterContext = isDischargePage ? '&filter[context]=discharge' : '';
    const filterFacility = isDischargePage
      ? ''
      : `&filter[facility]=${facilityId}`;

    const response = await fetch(
      getEndpoint(
        `appointment-types?page[size]=999${filterContext}${filterFacility}&${params}`,
      ),
      getHeaderInfo(),
    );

    const appointments = await response.json();
    setAppointmentTypes(appointments);
    return outputResult;
  };

  const fetchFacilityAppointmentTypes = async () => {
    let outputResult = {
      appointmentTypes: {},
      selectedAppointmentTypes: {},
    };
    if (!appointmentTypes) {
      outputResult = await getAppointmentTypes();
    }

    if (Object.keys(facResults).length && !isDischargePage) {
      const res = accessPoints;
      let appointmentTypesPerService = res?.reduce((acc, item) => {
        const {
          'service-line-id': serviceLineId,
          'appointment-types': appointmentTypes,
        } = item.attributes;
        // Map each appointmentTypes item to its appointment_type_template_id
        const appointmentTypeTemplateIds = appointmentTypes.map(
          appointment => appointment.appointment_type_template_id,
        );
        const uniqueAppointmentTypeTemplateIds = [
          ...new Set(appointmentTypeTemplateIds),
        ];

        if (acc[serviceLineId]) {
          acc[serviceLineId] = [
            ...new Set(
              Array.prototype.concat(
                acc[serviceLineId],
                uniqueAppointmentTypeTemplateIds,
              ),
            ),
          ];
        } else {
          acc[serviceLineId] = uniqueAppointmentTypeTemplateIds;
        }
        return acc;
      }, {});
      const providerServicesAppointments = [[]]; // Initialize with an empty array at index 0
      specialServices.forEach(s => {
        const appointments = appointmentTypesPerService[Number(s?.id)];
        if (appointments) {
          providerServicesAppointments[0] = [
            ...providerServicesAppointments[0],
            ...appointments,
          ];
        }
      });
      const uniqueAppointmentTypes = new Set(
        providerServicesAppointments.flat(),
      );
      appointmentTypesPerService = {
        ...appointmentTypesPerService,
        ...[[...uniqueAppointmentTypes]],
      };
      const getNameById = id => {
        const appointmentType = appointmentTypes?.data?.find(
          type => type.id === id.toString(),
        );
        return appointmentType ? appointmentType.attributes.name.trim() : null;
      };

      const finalResponse = Object.keys(appointmentTypesPerService).reduce(
        (acc, serviceLineId) => {
          const filteredIds = appointmentTypesPerService[serviceLineId].filter(
            id => getNameById(id) !== null,
          );
          acc[serviceLineId] = filteredIds.map(id => ({
            value: id.toString(),
            text: getNameById(id),
          }));
          return acc;
        },
        {},
      );

      outputResult.appointmentTypes = {
        ...outputResult.appointmentTypes,
        ...finalResponse,
      };
    } else if (isDischargePage && accessPoints?.length) {
      const groupedByServiceLineId = accessPoints.reduce(
        (accumulator, item) => {
          const serviceLineId = item.attributes['service-line-id'];
          const appointmentTypes = item.attributes['appointment-types'];
          // If the service line ID doesnot exist, initialize it with the current appointment types
          if (!accumulator[serviceLineId]) {
            accumulator[serviceLineId] = [];
          }
          // Merge current appointment types with existing ones for the same service line ID
          accumulator[serviceLineId] = accumulator[serviceLineId].concat(
            appointmentTypes,
          );
          return accumulator;
        },
        {},
      );
      // Ensure uniqueness of appointment types for each service
      let appointmentTypesPerService = Object.keys(
        groupedByServiceLineId,
      ).reduce((accumulator, serviceLineId) => {
        const uniqueAppointmentTypes = new Set(
          groupedByServiceLineId[serviceLineId].map(
            appointmentType => appointmentType.appointment_type_template_id,
          ),
        );
        // Convert the Set back to array of objects with just the unique `appointment_type_template_id` values
        accumulator[serviceLineId] = [...uniqueAppointmentTypes].map(id => id);
        return accumulator;
      }, {});
      const getNameById = id => {
        const appointmentType = appointmentTypes?.data?.find(
          type => type.id === id.toString(),
        );
        return appointmentType ? appointmentType.attributes.name.trim() : null;
      };

      const providerServicesAppointments = [[]]; // Initialize with an empty array at index 0
      specialServices.forEach(s => {
        const appointments = appointmentTypesPerService[Number(s?.id)];
        if (appointments) {
          providerServicesAppointments[0] = [
            ...providerServicesAppointments[0],
            ...appointments,
          ];
        }
      });
      const uniqueAppointmentTypes = new Set(
        providerServicesAppointments.flat(),
      );
      appointmentTypesPerService = {
        ...appointmentTypesPerService,
        ...[[...uniqueAppointmentTypes]],
      };

      const finalResponse = Object.keys(appointmentTypesPerService).reduce(
        (acc, serviceLineId) => {
          const filteredIds = appointmentTypesPerService[serviceLineId].filter(
            id => getNameById(id) !== null,
          );
          acc[serviceLineId] = filteredIds.map(id => ({
            value: id.toString(),
            text: getNameById(id),
          }));
          return acc;
        },
        {},
      );

      outputResult.appointmentTypes = {
        ...outputResult.appointmentTypes,
        ...finalResponse,
      };
    }

    if (checkActiveTab() && Object.keys(facilityAppointmentTypes).length) {
      outputResult.appointmentTypes = facilityAppointmentTypes;
      outputResult.selectedAppointmentTypes = selectedFacilityAppointmentType;
    } else if (Object.keys(outputResult.appointmentTypes).length) {
      setFacilityAppointmentTypes(outputResult.appointmentTypes);
    }
    return outputResult;
  };
  // Fetch Facility Appointment Types
  useEffect(() => {
    (async () => {
      // Service-lines and services to pull the Appointments
      if (
        accessPoints &&
        Object.keys(serviceIdsState).length > 0 &&
        facilityId !== undefined &&
        facilityId !== 'not-found' &&
        serviceTabs.length > 0
      ) {
        if (!isMobile && !(Object.keys(types).length > 0)) {
          const outputResult = await fetchFacilityAppointmentTypes();
          setTypes(outputResult);
        }
      }
    })();
  }, [
    serviceIdsState,
    facilityId,
    serviceTabs,
    accessPoints,
    appointmentTypes,
  ]);

  useEffect(() => {
    if (facilityResults.data && facilityResults.data.length) {
      const facilityId = facilityResults.data[0].id;
      setFacilityId(facilityId);
    }
  }, [facilityResults]);
  const setERResultsOnLoad = async (flag = false) => {
    const updatedResults = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const [tab, type] of Object.entries(types.selectedAppointmentTypes)) {
      if (!flag)
        updatedResults[tab] = updateResultsByAppointmentType(type)[tab];
      else
        updatedResults[tab] = updateResultsByAppointmentType(
          type,
          withoutAppointmentResults,
        )[tab];
    }

    // Remove undefined from updated object to retain the old values
    Object.keys(updatedResults).forEach(
      key => updatedResults[key] === undefined && delete updatedResults[key],
    );

    // if (
    //   flag &&
    //   params.appointmentTypeId &&
    //   params.service &&
    //   groupCServices.includes(getCurrentServicePermalink(params.service))
    // )necessry
    //   delete updatedResults[params.service];

    setFilteredResults(prev => {
      return {
        ...prev,
        ...filteredResults,
        ...updatedResults,
      };
    });
  };
  useEffect(() => {
    if (Object.keys(serviceIdsState).length && results?.data?.length) {
      if (isMobile) {
        if (params.appointmentTypeId && results?.data.length) {
          setSelectedFacilityAppointmentTypeMobile(params.appointmentTypeId);
          setFilteredResults(
            updateResultsByAppointmentType(params.appointmentTypeId),
          );
        } else if (!params.appointmentTypeId && results?.data.length) {
          setFilteredResults(
            updateResultsByAppointmentType(getResultsByService(results)),
          );
        }
        setResetFilter(false);
      } else {
        if (Object.keys(types).length > 0)
          setERResultsOnLoad().then(() => {
            setListLoading(false);
          });
        setListLoading(false);
      }
    }
  }, [results, serviceIdsState, isMobile, types]);

  useEffect(() => {
    if (
      Object.keys(serviceIdsState).length &&
      withoutAppointmentResults?.data?.length &&
      types.selectedAppointmentTypes
    ) {
      if (Object.keys(types).length > 0)
        setERResultsOnLoad(true).then(() => {
          setListLoading(false);
        });
      setListLoading(false);
    }
  }, [
    withoutAppointmentResults,
    Object.keys(filteredResults).length,
    serviceIdsState,
    types,
  ]);

  useEffect(() => {
    if (isMobile) {
      setPageTitle('Facility');
    } else if (checkActiveTab() && facilityServices[activeTab]) {
      setPageTitle(facilityServices[activeTab].attributes.name);
    }
  }, [isMobile, facilityServices, activeTab]);

  const handleSortBy = event => {
    setSortBy(event.target.value);
    const search = QueryString.stringify({
      ...params,
      sort: event.target.value,
    });
    history.replace({
      pathname,
      search,
    });
    setSortOptionChanged(true);
    setIsScheduleCall(true);
  };

  const getResults = useCallback(() => {
    if (isMobile) {
      return filteredResults;
    }

    if (!checkActiveTab()) {
      return [];
    }

    if (
      Object.keys(omitTypeServices).includes(params.service) &&
      !isMobile &&
      filteredResults[activeTab]?.length > 0
    ) {
      let filteredRecords = filteredResults[activeTab]?.filter(
        result => !result.attributes['appointment-types'].length,
      );
      if (
        selectedFacilityAppointmentType[activeTab] &&
        selectedFacilityAppointmentType[activeTab]?.length
      ) {
        const records = filteredResults[activeTab]?.filter(result => {
          return result.attributes['appointment-types'].filter(appointment => {
            return (
              params?.appointmentTypeId?.toString() === appointment?.id?.toString()
            );
          }).length;
        });
        filteredRecords = filteredRecords.concat(records);
      }
      const start = (selectedPagesOfServices[activeTab] - 1) * 10;
      const end = start + 10;
      return filteredRecords.slice(start, end);
    }
    return filteredResults[activeTab];
  }, [
    activeTab,
    filteredResults,
    isMobile,
    selectedFacilityAppointmentType,
    results,
    selectedPagesOfServices[activeTab],
  ]);

  const showAppointmentTypesDropdown = useCallback(() => {
    if (isMobile && facilityAppointmentTypesMobile?.length > 0) {
      return true;
    }

    if (
      !isInOmitTypeList(activeTab?.toString()) &&
      facilityAppointmentTypes[activeTab]
    ) {
      if (
        Object.keys(facilityServices).length > 0 &&
        checkActiveTab() &&
        groupCServices.includes(
          facilityServices[activeTab].attributes.permalink,
        )
      ) {
        return !hideUrgentCareAppointmentType;
      }

      if (
        Object.keys(facilityServices).length > 0 &&
        checkActiveTab() &&
        facilityAppointmentTypes[activeTab]?.length > 0
        // eslint-disable-next-line no-empty
      ) {

      } else {
        return false;
      }
      return true;
    }

    if (
      isInOmitTypeList(activeTab?.toString()) &&
      facilityAppointmentTypes[activeTab]
    ) {
      if (
        Object.keys(facilityServices).length > 0 &&
        checkActiveTab() &&
        groupCServices.includes(
          facilityServices[activeTab]?.attributes?.permalink,
        ) &&
        facilityAppointmentTypes[activeTab]?.length > 0
      ) {
        return !hideUrgentCareAppointmentType;
      }
    }
    return false;
  }, [
    getResults,
    isMobile,
    activeTab,
    facilityServices,
    hideUrgentCareAppointmentType,
    facilityAppointmentTypes,
    facilityAppointmentTypesMobile,
    facilityId,
    types,
    activeTab,
    params.service,
  ]);

  const showPaginator = useCallback(() => {
    return (
      filteredResults[params.service]?.length > 0 &&
      totalPagesForSelectedServices[params.service] !== undefined
    );
  }, [filteredResults, totalPagesForSelectedServices]);

  usePageTitle(pageTitle);

  const getScheduleLocation = schedule => {
    const locations =
      results.data && results.data.length
        ? results.included.filter(result => result.type === 'locations')
        : [];
    const loc = find(locations, {
      id: schedule?.relationships?.location?.data?.id,
    });
    if (loc) return loc?.attributes;
    return null;
  };

  const renderSearchedResult = () => {
    const handleDateChange = date => {
      setLoading(true);
      if (date?.start) {
        setDate(date.start);
        setDates(
          groupedDays(date.start, numberOfDays, healthSystem['time-zone']),
        );
        setServiceDates({
          ...serviceDates,
          [Number(params.service)]: {
            date: date.start,
            dates: groupedDays(
              date.start,
              numberOfDays,
              healthSystem['time-zone'],
            ),
          },
        });
        setServiceDatesOnAppointment(prev => {
          const updatedServiceDates = { ...prev };
          updatedServiceDates[params.service][params.appointmentTypeId] = {
            date: date.start,
            dates: groupedDays(
              date.start,
              numberOfDays,
              healthSystem['time-zone'],
            ),
          };
          console.log(updatedServiceDates, omitTypeServices, groupCServices, groupC, 'serviceDates');
          return updatedServiceDates;
        });
        if (isMobile) {
          setServiceDatesOnAppointmentMobile(prev => {
            const updatedServiceDates = { ...prev };
            updatedServiceDates[params.appointmentTypeId] = {
              date: date.start,
              dates: groupedDays(
                date.start,
                numberOfDays,
                healthSystem['time-zone'],
              ),
            };
            return updatedServiceDates;
          });
        }
      } else {
        setDate(date);
        setDates(groupedDays(date, numberOfDays, healthSystem['time-zone']));
        setServiceDates({
          ...serviceDates,
          [Number(params.service)]: {
            date,
            dates: groupedDays(date, numberOfDays, healthSystem['time-zone']),
          },
        });
        if (params?.appointmentTypeId)
          setServiceDatesOnAppointment(prev => {
            const updatedServiceDates = { ...prev };
            updatedServiceDates[params?.service][params?.appointmentTypeId] = {
              date,
              dates: groupedDays(date, numberOfDays, healthSystem['time-zone']),
            };
            console.log(updatedServiceDates, omitTypeServices, groupCServices, groupC, ' serviceDates');
            return updatedServiceDates;
          });

        if (isMobile) {
          setServiceDatesOnAppointmentMobile(prev => {
            const updatedServiceDates = { ...prev };
            updatedServiceDates[params.appointmentTypeId] = {
              date,
              dates: groupedDays(date, numberOfDays, healthSystem['time-zone']),
            };
            return updatedServiceDates;
          });
        }
      }
    };
    if (
      facilityId === 'not-found' ||
      locationId === 'not-found' ||
      inactive ||
      (isDischargePage &&
        facResults.length &&
        !facResults?.data?.filter(
          facility =>
            facility.attributes.permalink === urlParams.dischargeFacility &&
            facility.attributes['discharge-enabled'],
        ).length)
    )
      return <PageNotFound errorCode={inactive ? '400' : '404'} />;

    const handleSetDates = date => {
      if (isMobile) {
        setServiceDatesOnAppointmentMobile(prev => {
          const updatedServiceDates = { ...prev };
          updatedServiceDates[params.appointmentTypeId] = {
            date,
            dates: groupedDays(date, numberOfDays, healthSystem['time-zone']),
          };
          return updatedServiceDates;
        });
        setDates(groupedDays(date, 1, healthSystem['time-zone']));
      }
    };

    return (
      <Row className={showAppointmentTypesDropdown() ? 'provider' : null}>
        <Col sm={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
          {!loading && isMobile && facResults && (
            <Sticky>
              <InventoryControlsMobile
                showMapView={showMapView}
                setShowMapView={setShowMapView}
                showDateCarousel
                timezoneName={
                  facResults?.included?.find(i => i.type === 'locations')
                    ?.attributes['time-zone'] || healthSystem['time-zone']
                }
                isFacility
                dates={
                  serviceDatesOnAppointmentMobile?.[params?.appointmentTypeId]
                    ?.dates || dates
                }
                onDateChange={handleDateChange}
                handleSetDates={handleSetDates}
              />
            </Sticky>
          )}
          {!isMobile && (
            <InventoryControls
              showSortBy={false}
              showMapView={showMapView}
              setShowMapView={setShowMapView}
              sortBy={params.sort || sortBy}
              handleChange={handleSortBy}
            />
          )}
          {!resetFilter && !showMapView && (
            <>
              {Object.keys(serviceTabs).length > 0 && !isMobile && (
                <ServiceTabs
                  activeTab={activeTab}
                  handleTabClick={e => setActiveTab(e)}
                  handleTabIndex={e => setTabIndex(e)}
                  results={filteredResults}
                  serviceResults={serviceResults}
                  tabIndex={tabIndex}
                  serviceLineResults={locationServiceLinesResults}
                  serviceTabs={serviceTabs}
                  countForSelectedServices={countForSelectedServices}
                />
              )}

              {showAppointmentTypesDropdown() && (
                <Grid
                  container
                  style={
                    isMobile
                      ? {
                        paddingBottom: '10px',
                        paddingLeft: '20px',
                        paddingTop: '0px',
                        paddingRight: '20px',
                      }
                      : {
                        paddingBottom: '10px',
                        paddingLeft: '20px',
                        paddingTop: '30px',
                        paddingRight: '20px',
                      }
                  }
                >
                  <Grid item xs={isMobile ? 10 : 6} sm={isMobile ? 10 : 4}>
                    <CustomSelect
                      filled
                      fieldKey="appointmentTypes"
                      fieldValue={{
                        value: isMobile
                          ? selectedFacilityAppointmentTypeMobile ||
                          params.appointmentTypeId || ''
                          : selectedFacilityAppointmentType[activeTab] ||
                          params.appointmentTypeId ||
                          '',
                      }}
                      icon={<LocalHospitalIcon />}
                      label="Please select an appointment type"
                      items={
                        isMobile
                          ? facilityAppointmentTypesMobile
                          : facilityAppointmentTypes[activeTab]
                      }
                      validateFormHandler={async e => {
                        if (isMobile) {
                          setResetFilter(true);
                          if (facResults?.data?.length > 0) {
                            const timezone = facResults?.included?.find(
                              i => i.type === 'locations',
                            )?.attributes['time-zone'];
                            setfacTimezone(timezone);
                            setDate(
                              moment()
                                .tz(timezone)
                                .format(),
                            );
                            setDates(
                              groupedDays(
                                moment()
                                  .tz(timezone)
                                  .format(),
                                numberOfDays,
                                healthSystem['time-zone'],
                              ),
                            );
                          }
                          setQueryParamsFromObject({
                            // eslint-disable-next-line max-len
                            appointmentTypeId: e.appointmentTypes.value.toString(),
                          });
                          setSelectedFacilityAppointmentTypeMobile(
                            e.appointmentTypes.value.toString(),
                          );
                          setWithApptTypeMobile(undefined);
                          setIsScheduleCall(true);
                          mcaEvent(e.appointmentTypes.value.toString());
                        } else {
                          if (!isEmpty(e.appointmentTypes.value)) {
                            setIsScheduleCall(true);
                            setResults(resultsInitialState);
                          } else if (
                            isEmpty(e.appointmentTypes.value) &&
                            isInOmitTypeList(params.service)
                          ) {
                            // setResults(urgentCareListReserved);
                          } else if (
                            isEmpty(e.appointmentTypes.value) &&
                            !isInOmitTypeList(params.service)
                          ) {
                            // When Choose Empty From Appointment Types Dropdown Mark that Service tab results as empty
                            setFilteredResults({
                              ...filteredResults,
                              [activeTab]: [],
                            });
                          }
                          setSelectedFacilityAppointmentType({
                            ...selectedFacilityAppointmentType,
                            [activeTab]: e.appointmentTypes.value.toString(),
                          });
                          setSelectedPagesOfServices({
                            ...selectedPagesOfServices,
                            [activeTab]: 1,
                          });
                          setServiceResults({
                            ...serviceResults,
                            [params.service]: e.appointmentTypes.value,
                          });
                          // setFilteredResults({
                          //   ...filteredResults,
                          //   [activeTab]: updateResultsByAppointmentType(
                          //     e.appointmentTypes.value,
                          //   )[activeTab],
                          // });
                        }
                        mcaEvent(e.appointmentTypes.value.toString());
                        setShowResultsSection(true);
                      }}
                      hasError={showAppointmentTypeError}
                      errorMessage={
                        showAppointmentTypeError
                          ? 'Please select an Appointment Type'
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={1} sm={1} />
                  {!showMapView &&
                    Object.keys(serviceResults).includes(params.service) && (
                      <Grid item xs={1} sm={1}>
                        <></>
                      </Grid>
                    )}
                  {!showMapView &&
                    !isMobile &&
                    !ER_SERVICE_NAMES.includes(
                      getCurrentServiceName(params.service),
                    ) &&
                    !UC_SERVICE_NAMES.includes(
                      getCurrentServiceName(params.service),
                    ) &&
                    Object.keys(serviceResults).includes(params.service) && (
                      <Grid item xs={6} sm={6} className="date_carousel">
                        <Sticky stickyClassName="sticky">
                          <DateCarousel
                            dates={
                              serviceDatesOnAppointment?.[
                                Number(params?.service)
                              ]?.[params?.appointmentTypeId]?.dates ||
                              serviceDates?.[Number(params?.service)]?.dates
                            }
                            onDateChange={handleDateChange}
                            timezoneName={
                              // eslint-disable-next-line no-nested-ternary
                              resultsNew?.data?.length > 0
                                ? getScheduleLocation(resultsNew?.data[0]) !==
                                  null
                                  ? getScheduleLocation(resultsNew?.data[0])[
                                  'time-zone'
                                  ]
                                  : healthSystem['time-zone']
                                : healthSystem['time-zone']
                            }
                            dateHeadingLayout="horizontal"
                            onScroll={onScroll}
                            scrollLeft={scrollLeft}
                            whoIsScrolling={whoIsScrolling}
                            setWhoIsScrolling={setWhoIsScrolling}
                          />
                        </Sticky>
                      </Grid>
                    )}
                </Grid>
              )}
              {Object.keys(serviceResults)?.length > 0 &&
                !loading &&
                !resetFilter &&
                !showMapView && (
                  <Grid
                    container
                    style={
                      showAppointmentTypesDropdown()
                        ? {
                          paddingBottom: '0px',
                          paddingLeft: '20px',
                          paddingTop: '0px',
                        }
                        : {
                          paddingBottom: '10px',
                          paddingLeft: '20px',
                          paddingTop: '10px',
                        }
                    }
                  >
                    <Grid item xs={12} sm={12}>
                      <p className="disclaimer hide-mobile">
                        <strong>{t('Search.important')}</strong>{' '}
                        {t('Search.important1')}
                      </p>
                    </Grid>
                  </Grid>
                )}
            </>
          )}
          {showMapView && (
            <MapView
              schedules={filteredResults[params.service]}
              locations={
                storedLocations !== undefined && storedLocations !== null
                  ? Object.values(storedLocations)
                  : []
              }
              providers={
                storedProviders !== undefined && storedProviders !== null
                  ? Object.values(storedProviders)
                  : []
              }
            />
          )}
          {!showMapView &&
            (!isMobile
              ? Object.keys(serviceResults).includes(params.service) ||
              (facilityServices[params.service] &&
                facilityServices[params.service].attributes.permalink ===
                'urgent-care' &&
                !!getResults()?.length)
              : isEmpty(selectedFacilityAppointmentTypeMobile)
                ? Object.keys(getResults()).length
                  ? true
                  : false
                : true) && (
              <ScheduleResultList
                results={getResults()}
                locations={
                  storedLocations ? Object.values(storedLocations) : []
                }
                dates={
                  isMobile && params.appointmentTypeId === undefined || params.appointmentTypeId === ''
                    ? dates
                    : omitTypeServices?.[Number(params?.service)] ===
                      'GROUP_B' ||
                      omitTypeServices?.[Number(params?.service)] === 'GROUP_C'
                      ? serviceDates?.[Number(params.service)]?.dates
                      : serviceDatesOnAppointment?.[Number(params.service)]?.[
                        params?.appointmentTypeId
                      ]?.dates
                }
                datesLoading={datesLoading}
                dateCurrent={
                  isMobile && params.appointmentTypeId === undefined || params.appointmentTypeId === ''
                    ? date
                    : omitTypeServices?.[Number(params?.service)] ===
                      'GROUP_B' ||
                      omitTypeServices?.[Number(params?.service)] === 'GROUP_C'
                      ? serviceDates?.[Number(params.service)]?.date
                      : serviceDatesOnAppointment?.[Number(params.service)]?.[
                        params?.appointmentTypeId
                      ]?.date}
                noOfDays={numberOfDays}
                providers={
                  storedProviders ? Object.values(storedProviders) : []
                }
                facilities={facResults ? facResults?.data : []}
                selectedParams={selectedParams}
                loading={
                  sortOptionChanged
                    ? true
                    : loading || resetFilter || listLoading
                }
                uxMode={uxMode}
                showResults={showResultsSection}
                onScroll={onScroll}
                scrollLeft={scrollLeft}
                whoIsScrolling={whoIsScrolling}
                setWhoIsScrolling={setWhoIsScrolling}
                facilityPageTitle={facilityPageTitle}
                facilityPageDesc1={facilityPageDesc1}
                facilityPageDesc2={facilityPageDesc2}
                canonicalLink={pathname}
                type={type}
              />
            )}
          {!showMapView &&
            !isMobile &&
            checkActiveTab() &&
            showPaginator() && (
              <Paginator
                key={`${activeTab}-${totalPagesForSelectedServices[activeTab] || 1}`}
                handleChange={pg => handlePageChange(pg)}
                activePage={selectedPagesOfServices[activeTab] || 1}
                totalPages={totalPagesForSelectedServices[activeTab]}
              />
            )}
        </Col>
      </Row>
    );
  };

  const displayContent = () => {
    return renderSearchedResult();
  };

  return (
    <main className="FacilityInventoryPage" style={{ marginTop: '40px' }}>
      <SeoImplemention
        setFacilityPageTitle={setFacilityPageTitle}
        setFacilityPageDescription={setFacilityPageDescription}
        setFacilityPageDesc1={setFacilityPageDesc1}
        setFacilityPageDesc2={setFacilityPageDesc2}
        setType={setType}
        setMediaContentBlockBottom={setMediaContentBlockBottom}
        setMediaContentBlockTop={setMediaContentBlockTop}
        locationDetails={locationDetails}
        filteredResults={filteredResults}
        facilityResults={facilityResults}
        facilityServices={facilityServices}
        healthSystem={healthSystem}
        setMediaTitleTop={setMediaTitleTop}
      />
      {MediaTitleTop && (
        <div style={{ color: branding.primaryColor }} className="container">
          <Trans>
            <h1 className='locizeHeaderStyle'>{MediaTitleTop}</h1>
          </Trans>
        </div>
      )}
      {MediaContentBlockTop && (
        <div style={{ color: branding.primaryColor }} className="container">
          <Helmet>
            <meta charSet="utf-8" />
            <meta name="desctop" content={MediaContentBlockTop} />
          </Helmet>
          <Trans>
            <p className="locizePTagStyle">{MediaContentBlockTop}</p>
          </Trans>
        </div>
      )}
      <Container>
        {inactive && false && (
          <div className="private-mode">
            <p>Including inactive schedules.</p>
          </div>
        )}
        {displayContent()}
      </Container>
      {MediaContentBlockBottom && (
        <div style={{ color: branding.primaryColor }} className="container">
          <Helmet>
            <meta charSet="utf-8" />
            <meta name="desctop" content={MediaContentBlockBottom} />
          </Helmet>
          <Trans>
            <p className="locizePTagStyle">{MediaContentBlockBottom}</p>
          </Trans>
        </div>
      )}
    </main>
  );
}

FacilityInventoryPage.defaultProps = {
  loading: true,
  selectedParams: {},
  match: {
    params: {
      facilityName: '',
    },
  },
};

FacilityInventoryPage.propTypes = {
  selectedParams: PropTypes.instanceOf(Object),
  loading: PropTypes.bool,
  fetchHealthSystem: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      facilityName: PropTypes.string,
    }),
  }),
  healthSystem: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  return {
    performSearch: state.searchResult.searching,
    selectedParams: state.searchParams.selectedParams,
    providers: state.searchResult.providers,
    schedules: state.searchResult.schedules,
    availableTimes: state.searchResult.availableTimes,
    venuesOfCare: state.venuesOfCare.data,
    loading: state.venuesOfCare.meta.loading,
    defaultAppointmentTypes: state.appointmentTypes.data,
    healthSystem: state.healthSystem,
  };
}

const mapDispatchToProps = {
  loadVenuesOfCare,
  loadScheduleTimes,
  fetchHealthSystem,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FacilityInventoryPage);
