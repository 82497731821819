import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { isEmpty, isUndefined } from 'lodash';
import { Badge, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import useGoogleAnalytics from 'hooks/useGoogleAnalytics';
import { usePageTitle } from 'hooks/usePageTitle';
import { useLocation, Link } from 'react-router-dom';
import QueryString from 'query-string';
import { useTranslation, Trans } from 'react-i18next';
import camel from 'camelcase-keys';
import Phone from 'components/common/Phone';
import PageNotFound from 'components/PageNotFound';
import { getEndpoint, getHeaderInfo } from 'api/apiUtils';
import AppointmentPicker from 'components/appointmentPicker/AppointmentPicker';
import BookApptForm from 'components/appointmentPicker/BookApptForm';
import BreadCrumbs from 'components/common/BreadCrumbs';
import LocationDetail from 'components/common/LocationDetail';
import './ScheduleDetailPage.scss';
import Provider from 'components/provider';
import { Skeleton } from '@material-ui/lab';
import emergencyRoomImage from 'assets/images/vocImages/default_emergency_room_md.jpeg';
import urgentCareImage from 'assets/images/vocImages/default_urgent_care_md.jpeg';
import { useQueryString } from 'hooks/useQueryString';
import { fetchHealthSystem } from 'redux/actions/healthSystemActions';
import { BrandingContext } from 'components/contexts/BrandingContext';
import { objectCamelCase } from 'utils/helpers';
import { getPageDescription, getPageTitle } from 'utils/pageMeta';
import ProviderAvatar from 'components/provider/ProviderAvatar';
import ProviderAvatarServiceLine from 'components/provider/ProviderAvatarServiceLine';
import ProviderLocationMobile from 'components/provider/ProviderLocationMobile';
import PageMetaInfo from 'components/common/PageMetaInfo';
import { ER_SERVICE_NAMES } from '../../config/searchConstants';
import { serviceLineIcons } from '../../config/serviceLineIcons';
import { setProviderAvatar } from '../../helpers/selectProviderAvatar';
import { singleScheduleFetch } from 'redux/actions/schedulesActions';

const ScheduleDetail = ({ propSchedule, match, fetchHealthSystem,
  healthSystem, singleScheduleFetch }) => {
  const { history, urlParams, isDischargePage } = useQueryString();
  const [t] = useTranslation();
  const GA = useGoogleAnalytics();
  const { branding } = useContext(BrandingContext);
  const { search, pathname } = useLocation();
  const params = QueryString.parse(search);
  const [aTID, setaTID] = useState(params.appointmentTypes);
  const [schedule, setSchedule] = useState(propSchedule);
  const [service, setService] = useState();
  const [servicePerlink, setServicePerlink] = useState();
  const [included, setIncluded] = useState();
  const [loading, setLoading] = useState(true);
  const [appointmentTitle, setAppointmentTitle] = useState(
    `${t('ScheduleAppointment.bookAnAppointment')}`,
  );
  const dispatch = useDispatch();
  const [inactive] = useState(
    params.inactive === 'true',
  );
  const [form, setForm] = useState({
    insurancePlans: {
      value: '',
    },
    appointmentType: {
      value: aTID || '',
    },
  });
  const [appointmentTypeOptions, setAppointmentTypeOptions] = useState([]);

  useEffect(() => {
    if (!healthSystem || !fetchHealthSystem)
      fetchHealthSystem();
    return () => GA.set({ dimension1: undefined });
  }, []);

  const loadScheduleData = async () => {
    const response = await fetch(
      getEndpoint(`schedules/${match.params.id}`, {
        include:
          'location,provider,service,insurance-plans,venue-of-care,facility',
      }),
      getHeaderInfo(),
    );
    const results = await response.json();
    setIncluded(results.included || null);
    setSchedule(results.data || null);
    if (results.included.find(obj => obj.type === 'providers')) {
      const { name, permalink, providerType, suffix } = results.included.find(obj => obj.type === 'providers')?.attributes;
      dispatch({ type: 'SET_SCHEDULE', payload: { schedule: results.data, provider: { name, permalink, providerType, suffix } } });
    } else {
      dispatch({ type: 'SET_SCHEDULE', payload: { schedule: results.data } });
    }

    const servicePermalink = results.included
      ? results.included.find(obj => obj.type === 'services')
      : null;
    setServicePerlink(results.included
      ? results.included.find(obj => obj.type === 'services')
      : null);
    if (
      ER_SERVICE_NAMES.includes(
        servicePermalink.attributes.permalink.toUpperCase(),
      )
    ) {
      setAppointmentTitle(t('ScheduleAppointment.bookAVisit'));
      setService('Emergency Room');
    }
    setLoading(false);
    const facility = results.included.find(i => i.type === 'facilities');
    GA.set({
      dimension1: `${facility.id}-${facility.attributes.name.replace(
        /[\W_]+/g,
        '_',
      )}`,
    });
  };

  useEffect(() => {
    if (!schedule && loading) {
      loadScheduleData();
    }
  }, [schedule, loading]);

  useEffect(() => {
    setLoading(true);
    setSchedule(propSchedule);
  }, [match.params.id]);

  useEffect(() => {
    (async () => {
      if (schedule && schedule?.attributes) {
        setAppointmentTypeOptions(schedule?.attributes['appointment-types']);
        if (!isUndefined(params.appointment_type)) {
          const atid = schedule.attributes['appointment-types'].find(
            at => at.permalink === params.appointment_type,
          )?.id;
          setForm({
            ...form,
            appointmentType: { error: false, value: atid },
          });
          setaTID(atid)
        }
      }
    })();
  }, [schedule]);

  const getProvider = providerId => {
    const provider = included.find(
      obj => obj.id === providerId && obj.type === 'providers',
    );
    provider.attributes = objectCamelCase(provider.attributes);
    return provider || null;
  };

  const getVenue = venueId => {
    const venue = included.find(
      obj => obj.id === venueId && obj.type === 'venue-of-cares',
    );
    venue.attributes = objectCamelCase(venue.attributes);
    return venue || null;
  };

  const getLocation = locationId => {
    const location = included.find(
      obj => obj.id === locationId && obj.type === 'locations',
    );
    return location || null;
  };

  const getInsurance = () => {
    return included.filter(obj => obj.type === 'insurance-plans');
  };

  const updateQueryString = e => {
    const search = QueryString.stringify({
      ...params,
      ...e,
    });
    history.replace({ pathname, search });
  };

  const handleChange = (e, key) => {
    setForm({
      ...form,
      [key]: e[key],
    });

    updateQueryString({ appointmentTypes: e.appointmentType.value.toString() });
    if (params.appointment_type != undefined) {
      const search = QueryString.stringify({
        appointmentTypes: e.appointmentType.value.toString(),
      });
      history.replace({ pathname, search });
    }
    // updateQueryString({ appointment_type: getAppointmentType( e.appointmentType.value.toString())});
  };


  const handleAppointmentTypeChange = e => {
    setForm({
      ...form,
      appointmentType: e,
    });
    updateQueryString({ appointmentTypes: e.value });
  };

  const provider =
    schedule && schedule.relationships.provider.data
      ? getProvider(schedule.relationships.provider.data.id)
      : null;

  const venue =
    schedule && schedule.relationships['venue-of-care'].data
      ? getVenue(schedule.relationships['venue-of-care'].data.id)
      : null;

  const location = schedule
    ? getLocation(schedule.relationships.location.data.id)
    : null;

  const timezoneName = schedule
    ? location.attributes['time-zone'] || location.attributes.timeZone
    : null;

  const renderScheduleName = () => {
    if (schedule.attributes.name !== '') {
      return (
        <h2
          className="service-name-header"
          style={{ color: branding.primaryColor }}
        >
          {schedule.attributes.name}
        </h2>
      );
    }
    return (
      <h2
        className="service-name-header"
        style={{ color: branding.primaryColor }}
      >
        {schedule.attributes.service.name}
      </h2>
    );
  };

  const renderScheduleService = () => {
    if (schedule.attributes.name !== '') {
      return <p>{schedule.attributes.service.name}</p>;
    }
    return <></>;
  };

  const dischargeLink = () => {
    return isDischargePage ? `/${urlParams.dischargeFacility}/discharge` : '';
  };
  const imageForCard = () => {
    if (provider && provider.attributes) {
      return provider.attributes.profileImage;
    }
    if (venue && venue.attributes) {
      switch (venue.attributes.venueType) {
        case 'urgent_care':
          return urgentCareImage;
        case 'emergency_room':
          return emergencyRoomImage;
        default:
          return null;
      }
    }
    return null;
  };

  usePageTitle(t('fullSchedule'));

  const getFacility = facilityId => {
    const facility = included?.find(
      obj => obj?.id === facilityId && obj?.type === 'facilities'
    );
    return facility?.attributes || null;
  };

  const facility =
    schedule && schedule?.relationships?.facility?.data
      ? getFacility(schedule?.relationships?.facility?.data?.id)
      : null;

  if (loading) {
    return (
      <div className="ScheduleDetailPage">
        <div className="container">
          <Grid container>
            <Grid item sm={8}>
              <Provider loading />
            </Grid>
            <Grid item sm={4}>
              <Skeleton />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }

  const serviceName = schedule.attributes.service.name;
  const providerData = provider !== undefined && provider !== null ? provider.attributes : '';
  const pageTitle = getPageTitle(healthSystem, providerData, serviceName);
  const pageDescription = getPageDescription(healthSystem, providerData, serviceName);
  let MediaContentBlockTop = '';
  let MediaContentBlockBottom = '';
  let MediaTitle_Top = '';
  if (
    t('FullSchedulePage_MediaContentBlock_Top') !== '' &&
    t('FullSchedulePage_MediaContentBlock_Top') !=
    'FullSchedulePage_MediaContentBlock_Top'
  ) {
    MediaContentBlockTop = t('FullSchedulePage_MediaContentBlock_Top', {
      hs_name: healthSystem?.name,
      region_name: healthSystem?.region?.name,
      service_name: serviceName ? serviceName : '',
      facility_name: location?.attributes?.['facility-name'],
      location_name: location?.attributes?.name,
      location_city: location?.attributes?.city,
      location_state: location?.attributes?.state,
      location_zip: location?.attributes?.zip,
    });
  }
  if (
    t('FullSchedulePage_MediaContentBlock_Bottom') !== '' &&
    t('FullSchedulePage_MediaContentBlock_Bottom') !==
    'FullSchedulePage_MediaContentBlock_Bottom'
  ) {
    MediaContentBlockBottom = t('FullSchedulePage_MediaContentBlock_Bottom', {
      hs_name: healthSystem?.name,
      region_name: healthSystem?.region?.name,
      service_name: serviceName ? serviceName : '',
      facility_name: location?.attributes?.['facility-name'],
      location_name: location?.attributes?.name,
      location_city: location?.attributes?.city,
      location_state: location?.attributes?.state,
      location_zip: location?.attributes?.zip,
    });
  }
  if (
    t('FullSchedulePage_MediaTitle_Top') !== '' &&
    t('FullSchedulePage_MediaTitle_Top') !==
    'FullSchedulePage_MediaTitle_Top'
  ) {
    MediaTitle_Top = t('FullSchedulePage_MediaTitle_Top', {
      hs_name: healthSystem?.name,
      region_name: healthSystem?.region?.name,
      service_name: serviceName ? serviceName : '',
      facility_name: location?.attributes?.['facility-name'],
      location_name: location?.attributes?.name,
      location_city: location?.attributes?.city,
      location_state: location?.attributes?.state,
      location_zip: location?.attributes?.zip,
    });
  }

  if ((!schedule || isEmpty(schedule)) && !loading) return <PageNotFound />;

  return (
    <article className="ScheduleDetailPage">
      {/* <PageMetaInfo description={pageDescription} title={pageTitle} /> */}
      {MediaTitle_Top && (
        <div style={{ color: branding.primaryColor }} className="container">
          <Trans>
            <h1 className='locizeHeaderStyle'>{MediaTitle_Top}</h1>
          </Trans>
        </div>
      )}
      {MediaContentBlockTop && (
        <div style={{ color: branding.primaryColor }} className="container">
          <Trans>
            <p className="locizePTagStyle">{MediaContentBlockTop}</p>
          </Trans>
        </div>
      )}
      {inactive && (
        <div className="private-mode">
          <p>Inactive schedule</p>
        </div>
      )}
      <div className="container">
        <BreadCrumbs
          links={[
            {
              text:
                schedule.attributes.name || schedule.attributes.service.name,
            },
          ]}
        />
      </div>
      <div className="container">
        <Grid container>
          <Grid item xs={12} sm={isMobile ? 12 : 8}>
            <main>
              <Grid container alignItems="flex-start" className="mt-3">
                <Grid item xs={4} sm={3}>
                  {/* <ProviderAvatar alt="Schedule Image" src={imageForCard()} /> */}
                  {setProviderAvatar(provider, location?.attributes, schedule?.attributes?.service?.permalink, healthSystem, serviceName, facility)}
                </Grid>
                <Grid item xs={8} sm={6}>
                  {provider && (
                    <div>
                      <h1>
                        {provider?.attributes?.providerType !== 'HealthResource' && (
                          <Link
                            to={`${dischargeLink()}/providers/${provider.id
                              }?appointmentTypes=${form.appointmentType.value}`}
                            className="name-link"
                          >
                            {provider.attributes.suffix
                              ? [
                                provider.attributes.name,
                                provider.attributes.suffix,
                              ].join(', ')
                              : provider.attributes.name}
                          </Link>
                        )}
                        {provider?.attributes?.providerType === 'HealthResource' && (
                          <Badge
                            className="name-link"
                          >
                            {provider.attributes.suffix
                              ? [
                                provider.attributes.name,
                                provider.attributes.suffix,
                              ].join(', ')
                              : provider.attributes.name}
                          </Badge>
                        )}
                      </h1>
                      <p className="provider-title">
                        {provider.attributes.subspecialties &&
                          provider.attributes.subspecialties
                            .map(s => s.name)
                            .join(', ')}
                      </p>
                    </div>
                  )}
                  {!provider && (
                    <>
                      {renderScheduleName()}

                      {renderScheduleService()}
                    </>
                  )}

                  <Grid container spacing={2}>
                    <Grid container item>
                      <Phone
                        name={location.attributes.name}
                        phoneNumber={location.attributes.phone}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {!isMobile &&
                <div className="hide-mobile">
                  <AppointmentPicker
                    scheduleId={schedule.id}
                    patientNowTimes={!isUndefined(params.nowContext) && params.nowContext === 'true'}
                    appointmentTypeId={
                      schedule.attributes['appointment-types'].find(
                        at => at.id === Number(form.appointmentType.value),
                      )
                        ? form.appointmentType.value
                        : ''
                    }
                    link={`${dischargeLink()}/schedule/${schedule.id
                      }?appointmentTypes=${form.appointmentType.value}&inactive=${inactive !== null ? inactive : false}&service=${params.service}`}
                    amountDaysTablet={4}
                    amountDaysDesktop={6}
                    amountDaysLgDesktop={7}
                    suppressWarning={
                      schedule.attributes['appointment-types'].length === 0
                    }
                    timezoneName={timezoneName}
                    maxTimesPerDay={null}
                    serviceName={
                      schedule.attributes.name || schedule.attributes.service.name
                    }
                    component="ScheduleDetailPage"
                    inactive={!schedule.attributes.active}
                    servicePermalink={params.service}
                    scheduleStatus={{ forceDeactivation: schedule.attributes["force-deactivation"], active: schedule.attributes.active, hidden: schedule.attributes.hidden }}
                  />
                </div>
              }
              {isMobile &&
                <div className="">
                  <ProviderLocationMobile
                    schedules={[schedule]}
                    locations={[location]}
                    provider={provider || {}}
                    insurancePlans={[]}
                    appointmentTypeParam={aTID || ''}
                    updateQueryString={e =>
                      handleAppointmentTypeChange({ value: e.appointmentTypes })
                    }
                    scheduleDetailMobile
                    showAllAvailabilityLink={false}
                    servicePermalink={params.service}
                    suppressWarning={
                      schedule.attributes['appointment-types'].length === 0
                    }
                    nowContext={!isUndefined(params.nowContext) && params.nowContext === 'true'}
                  />
                </div>
              }
            </main>
          </Grid>
          {!isMobile && (
            <Grid item sm={4} className="sidebar hide-mobile">
              <aside>
                <BookApptForm
                  appointmentType={
                    schedule.attributes['appointment-types'].find(
                      at => at.id === Number(form.appointmentType.value),
                    )
                      ? form.appointmentType
                      : { value: '' }
                  }
                  appointmentTypes={appointmentTypeOptions}
                  form={form}
                  handleChange={handleChange}
                  insurancePlans={getInsurance()}
                  headingLabel={appointmentTitle}
                  service={service}
                  servicePermalink={params.service}
                />
                <LocationDetail
                  location={{
                    ...location.attributes,
                    ...camel(location.attributes),
                  }}
                />
              </aside>
            </Grid>
          )}
        </Grid>
      </div>
      {MediaContentBlockBottom && (
        <div style={{ color: branding.primaryColor }} className="container">
          <Trans>
            <p className="locizePTagStyle">{MediaContentBlockBottom}</p>
          </Trans>
        </div>
      )}
    </article>
  );

};

ScheduleDetail.defaultProps = {
  propSchedule: null,
};

ScheduleDetail.propTypes = {
  propSchedule: PropTypes.instanceOf(Object),
  match: PropTypes.instanceOf(Object).isRequired,
  fetchHealthSystem: PropTypes.func.isRequired,
  healthSystem: PropTypes.instanceOf(Object).isRequired
};
function mapStateToProps(state) {
  return {
    healthSystem: state.healthSystem
  };
}

const mapDispatchToProps = {
  fetchHealthSystem,
  singleScheduleFetch
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleDetail);
