import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import './FullSchedule.scss';
import { useQueryString } from '../../hooks/useQueryString';

/**
 * Formats and displays a link to a schedule detail page.
 * It used to display "Full Schedule", thus the component name,
 * but business requested a label change.
 */
const FullSchedule = ({
  schedule,
  appointmentType,
  inactive,
  type,
  servicePermalink,
  nowContext,
}) => {
  const { params, urlParams, isDischargePage } = useQueryString();
  const [t] = useTranslation();

  if (!schedule) return <></>;
  const id = typeof schedule === 'object' ? schedule.id : schedule;
  // TIL 'typeof null' equals 'object'.
  const aId =
    typeof appointmentType === 'object' && appointmentType !== null
      ? appointmentType.id
      : appointmentType;

  const searchParams = new URLSearchParams('');

  if (aId) {
    searchParams.append('appointmentTypes', aId);
  }

  let link = `/schedule/calendar/${id}?${searchParams.toString()}&type=${type}&service=${servicePermalink}&nowContext=${nowContext}`;

  if (isDischargePage) {
    link = `/${urlParams.dischargeFacility}/discharge${link}`;
  }

  return (
    <Link
    aria-label={`${t('FullSchedule.moreAvailableTimes')}`}
      to={link}
      className="FullSchedule"
    >
      <CalendarTodayIcon />
      {t('FullSchedule.moreAvailableTimes')}
    </Link>
  );
};

FullSchedule.defaultProps = {
  appointmentType: {},
  schedule: {},
  inactive: false,
  nowContext: false,
};

FullSchedule.propTypes = {
  appointmentType: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string,
  ]),
  schedule: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string,
    PropTypes.number,
  ]),
  inactive: PropTypes.bool,
  type: PropTypes.string,
  nowContext: PropTypes.bool,
};

export default FullSchedule;
