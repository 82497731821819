import { Grid, Typography, Button } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import AddToCalendar from 'react-add-to-calendar';
import { PatientIntakeContext } from "../contexts/PatientIntakeContext";
import { ReactComponent as SuccessImage } from 'assets/images/icons/check.svg';
import { BrandingContext } from '../contexts/BrandingContext';
import "./PatientIntakePage.scss";

const ThankYouPage = () => {

    const [t] = useTranslation();
    const intake = useContext(PatientIntakeContext);
    const appointmentInfo = intake?.apptInfo;
    const { branding } = useContext(BrandingContext);
    
    const useStyles = makeStyles((theme) => ({
        container:{
            paddingInline: "10px",
            justifyContent:"center",
            alignItems:"center"
        },
        successTextStyle:{
            color:"#222222",
            fontSize:"14px !important",
            lineHeight:"20px !important",
            fontWeight:"600 !important",
            maxWidth:"240px !important",
            textAlign: "center"
        },
        button: {
            color:  `${branding.buttonTextColor} !important`,
            borderColor:  `${branding.buttonColor} !important`,
            backgroundColor: `${branding.buttonColor} !important`,
            borderRadius: '4px',
            textTransform: "none !important",
            paddingInline:"10px !important",
            '& a': {
                "& span" : {
                    color: `${branding.buttonTextColor} !important`
                },
            },
          },
          dropdownClass: {
            border: `1px solid ${branding.buttonColor}`,
            backgroundColor: branding.buttonTextColor,
            zIndex: 1
          },
       
    }));
    const classes = useStyles();

    const createEvent = () => {

        return {
            title: `Appointment - ${appointmentInfo?.providerLocation}`,
            description: `Appointment with ${appointmentInfo?.providerName}.`,
            location: appointmentInfo?.providerLocation,
            startTime: appointmentInfo?.appointmentTime?.split(".")[0],
            endTime: appointmentInfo?.appointmentTime?.split(".")[0]
        }
    }

    const icon = { "calendar-o" : 'left' };

    return (
        <Grid className="ConfirmationPage" container alignItems="center" justifyContent="center" direction="column">
            <Grid item xs={4}  marginY={1} >
                <SuccessImage />
            </Grid>
            <Grid item xs={4}  marginY={3}>
                <Typography className={classes.successTextStyle} >
                    {t('PatientIntake.ThanksPageInfo')}
                </Typography>
            </Grid>
            <Grid item xs={4} marginBottom={5}>
                 <Button className={classes.button} type="button">
                    <AddToCalendar
                        event={createEvent()}
                        buttonLabel={t('ConfirmationPage.AddToCalendar')}
                        dropdownClass={`react-add-to-calendar__dropdown ${classes.dropdownClass}`}
                        buttonTemplate={icon}
                        listItems={[ { apple: 'Apple' }, { google: 'Google' }, { outlook: 'Outlook' }, { outlookcom: 'Outlook.com' }, { yahoo: 'Yahoo' } ]}
                    />
                </Button>
            </Grid>   
        </Grid>
    )
}

export default ThankYouPage;