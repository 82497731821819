import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getEndpoint, getHeaderInfo } from 'api/apiUtils';
import { darken } from '@material-ui/core/styles';
import i18next from 'i18next';
import { Form } from 'react-formio';
import { useLocation, useParams } from 'react-router-dom';
import QueryString from 'query-string';
import './formio/Formio.scss';
import PropTypes from 'prop-types';
import { isEmpty, isUndefined } from 'lodash';
import moment from 'moment-timezone';
import { RegistrationFormContext } from '../form/RegistrationFormProvider';
import { BrandingContext } from '../contexts/BrandingContext';
import StripeForm from '../form/registrationForm/StripeForm';
import DialogMessage from '../form/registrationForm/DialogMessage';
import { getCurrency } from 'utils/helpers';
import { ER_SERVICE_NAMES } from '../../config/searchConstants';
import { createPay, getPaymentSettings } from 'api/paymentApi';
import { getSubDomain } from 'api/apiUtils';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { getAppointmentTypePayments } from 'api/appointmentTypesApi';
import { useTranslation } from 'react-i18next';
import { useQueryString } from '../../hooks/useQueryString';
import IntakeStripeForm from 'components/patientIntake/IntakeStripeForm';
import { createTransaction } from "api/patientIntakeApi";

const setAppointmentAt = (timestamp, timezone = 'UTC') =>
  moment(timestamp)
    .tz(timezone)
    .format('dddd, MMMM DD - h:mm A');
const newInstance = i18next.createInstance();

newInstance.init(
  {
    fallbackLng: 'en',
    lng: localStorage.getItem('i18nextLng'),
    debug: false,
  },
  err => {
    if (err) return console.log('something went wrong loading', err);
  },
);

const FormioForm = ({
  appointmentTimeLabel,
  appointmentType,
  appointmentTypeLabel,
  appointmentCostLabel,
  handleErrors,
  handleScreeningQuestions,
  scheduleId,
  scheduleLocation,
  screeningQuestionsAnswers,
  isScreeningQuestionsShowing,
  service,
}) => {
  const { isDischargePage, urlParams } = useQueryString();
  const healthSystem = useSelector(state => state.healthSystem);
  const { branding } = useContext(BrandingContext);
  const {
    dateTime,
    getVisitSettings,
    formErrors,
    submitFormIORegistration,
  } = useContext(RegistrationFormContext);
  const location = useLocation();
  const params = QueryString.parse(location.search);
  const formContext = useContext(RegistrationFormContext);
  const [canSubmit, setCanSubmit] = useState(true);
  const [formDefinition, setFormDefinition] = useState(null);
  const [formSubmission, setFormSubmission] = useState({});
  const [requiredObject, setRequiredObject] = useState({});
  const [viewAlert, setViewAlert] = useState(false);
  const [paymentRequired, setpaymentRequired] = useState(false);
  const [paymentTransactionId, setPaymentTransactionId] = useState();
  const [hasCouponCode, setHasCouponCode] = useState(false);
  const [couponCode, setCouponCode] = useState();
  const [paymentChoice, setPaymentChoice] = useState();
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [appointmentCost, setAppointmentCost] = useState(0);
  const [appointmentCurrency, setAppointmentCurrency] = useState('USD');
  const [paymentCreated, setpaymentCreated] = useState(false);
  const [paymentAuthorized, setPaymentAuthorized] = useState(false);
  const [hsPaymentMode, setHsPaymentMode] = useState('');
  const [viewCloseAlert, setViewCloseAlert] = useState(false);
  const [canceled, setCanceled] = useState(false);
  const [payCalled, setPayCalled] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [insuranceLableAndValue, setInsuranceLableAndValue] = useState('');
  const [sessionKeys, setSessionKeys] = useState({});

  const callPay = async () => {
    setPayCalled(true);
    const paymentParams = {
      schedule_id: scheduleId,
    };
    if (appointmentType.id) {
      paymentParams.appointment_type_id = appointmentType.id;
    }
    const { response } = await createPay(paymentParams);
    if (response?.session) {
      formContext.handleSetFormState({
        paymentTransactionId: response.session.transactionId,
        providerSessionSecret: response.session.providerSessionSecret,
        publishableKey: response.session.publishableKey,
        providerUserId: response.session.providerUserId,
      });
      setpaymentCreated(true);
      setPaymentTransactionId(response.session.transactionId);
      setPaymentOpen(true);
    }
  };
  const [t] = useTranslation();
  useEffect(() => {
    handleErrors(formContext.formErrors);
    if (Object.keys(formSubmission).length) {
      if (
        formContext.formErrors.length === 1 &&
        formContext.formErrors[0]?.title === 'appointment-at - is taken'
      ) {
        setCanSubmit(true);
      } else {
        // setCanSubmit(!formContext.formErrors.length);
        setCanSubmit(true);
      }
    }
    if (
      paymentRequired &&
      formContext.formErrors.length === 1 &&
      formContext.formErrors[0].title === 'payment-transaction-id-missing' &&
      paymentCreated === false &&
      canceled === false
    ) {
      if (!payCalled) callPay();
    }
    if (
      paymentRequired &&
      paymentCreated &&
      formContext.formErrors.length === 1 &&
      formContext.formErrors[0].message.includes('requires_payment_method') &&
      canceled === false
    ) {
      setPaymentOpen(true);
    }
    if (
      paymentRequired &&
      paymentAuthorized &&
      formContext.formErrors.length === 1 &&
      // formContext.formErrors[0].title.includes('is taken') ||
      formContext.formErrors[0].title.includes('appointment-at') &&
      canceled === false
    ) {
      setPaymentOpen(false);
    }
  }, [
    formContext.formErrors,
    formSubmission,
    paymentRequired,
    paymentCreated,
    paymentAuthorized,
  ]);

  useEffect(() => {
    handleErrors(formErrors);
  }, [formErrors]);

  useEffect(() => {
    (async () => {
      if (appointmentType && scheduleId) {
        await getVisitSettings(appointmentType, scheduleId);
        if (appointmentType?.has_coupon_code) {
          if (localStorage.getItem('code')) {
            setCouponCode(localStorage.getItem('code'));
            setHasCouponCode(true);
          }
        }
      }
    })();
  }, [appointmentType, scheduleId]);

  useEffect(() => {
    formContext.handleSetFormState({
      couponCode: { value: couponCode },
      hasCouponCode: { value: hasCouponCode },
    });
  }, [couponCode]);

  useEffect(() => {
    if (formContext.screeningQuestions) {
      handleScreeningQuestions(formContext.screeningQuestions);
    }
  }, [formContext.screeningQuestions]);

  const fetchApptPayment = async () => {
    const apptId = params.appointmentTypes;
    const scheduleId = location.pathname.split('/')[2];
    if (scheduleId) {
      try {
        const res = await getAppointmentTypePayments(apptId, scheduleId);
        if (
          res &&
          res.response &&
          res.response.items &&
          res.response.items.length > 0
        ) {
          const data = res.response.items[0];
          setAppointmentCurrency(getCurrency(data.currency));
          setAppointmentCost(data.appointmentFeeAmount);
        }
        const { response } = await getPaymentSettings(
          localStorage.getItem('facilityPage'),
        );
        setViewAlert(false);
        setHsPaymentMode('disabled');
        if (
          response['visit.payment'] &&
          response['visit.payment'] === 'enabled'
        ) {
          setHsPaymentMode('enabled');
          setViewAlert(true);
        }

        if (
          response['visit.payment'] &&
          response['visit.payment'] === 'required'
        ) {
          setpaymentRequired(true);
          setHsPaymentMode('required');
          setViewAlert(true);
          setPaymentChoice('PAYNOW');
        }
      } catch (err) {
        console.error('Get payment Error => ', err);
      }
    }
  };

  useEffect(() => {
    fetchApptPayment();
  }, []);

  useEffect(() => {
    if (formDefinition?.data?.components) {
      const hasField = formDefinition.data.components.filter(component =>
        ['referring-facility-user-field', 'referring-facility'].includes(
          component.key,
        ),
      );
      if (isDischargePage && urlParams.dischargeFacility && hasField.length) {
        (async () => {
          const facilityResponse = await fetch(
            getEndpoint(`facilities`, {
              'filter[permalink]': urlParams.dischargeFacility,
            }),
            getHeaderInfo(),
          );
          const facilities = await facilityResponse.json();
          const facility = facilities.data
            .filter(
              facility =>
                facility.attributes.permalink === urlParams.dischargeFacility,
            )
            .shift();
          setFormSubmission({
            data: {
              'appointment-context': 'discharge',
              'referring-facility': facility?.attributes?.name ?? '',
              'referring-facility-user-field': facility?.attributes?.name ?? '',
            },
          });
        })();
      }
    }
  }, [formDefinition]);
  const paymentMessage = ER_SERVICE_NAMES.includes(service)
    ? 'visit'
    : 'Appointment';
  const toggleViewAlert = from => {
    setViewAlert(!viewAlert);
    setpaymentRequired(from);
  };
  const toggleCloseViewAlert = from => {
    setViewCloseAlert(!viewCloseAlert);
    setpaymentRequired(from);
  };
  const renderStripe = e => {
    const stripePromise = loadStripe(formContext.form.publishableKey, {
      stripeAccount: formContext.form.providerUserId,
    });
    return (
      <Elements stripe={stripePromise}>
        <StripeForm
          show={paymentOpen && paymentRequired}
          toggleModal={e => {
            setPaymentOpen(false);
            setViewCloseAlert(true);
          }}
          emailAddress={emailAddress}
          formContext={formContext}
          appointmentCurrency={appointmentCurrency}
          appointmentCost={appointmentCost}
          handleClose={e => {
            setPaymentAuthorized(false);
            setViewAlert(true);
          }}
          callVisit={async e => {
            const urlPermalink =
              localStorage.getItem('isFacilityPage') === 'true'
                ? localStorage.getItem('facilityPage')
                : getSubDomain();
            await submitFormIORegistration(
              {
                ...formSubmission.data,
                questions: screeningQuestionsAnswers,
                'appointment-type': appointmentType.id,
              },
              appointmentType.id,
              scheduleId,
              scheduleLocation,
              paymentChoice,
              paymentTransactionId,
              urlPermalink,
              hasCouponCode,
              couponCode,
            );
            setPaymentAuthorized(true);
          }}
        />
      </Elements>
    );
  };

  const validateFormIO = elem => {
    let hasValue = false;

    if (elem.type === 'radio') {
      const radios = elem
        .closest('.form-radio')
        .querySelectorAll('input[type=radio]');
      let notChecked = 0;

      radios.forEach(radio => {
        if (!radio.checked) {
          notChecked += 1;
        }
      });

      hasValue = radios.length !== notChecked;
    } else if (elem.type === 'checkbox') {
      hasValue = elem.checked;
    } else if (elem.value) {
      hasValue = elem.value.trim().length;
    } else if (elem.querySelector('select')) {
      hasValue = !!elem.querySelector('select option');
    }

    return hasValue;
  };

  // @note
  // set a record with element unique identifier as the key; i.e. patient-complaint
  // and an object with the custom error.
  // This is created to force the custom errors to show.
  const getAndSetFormDefinitionLabels = definition => {
    const { components } = definition.data;
    let result = {};

    components.forEach(component => {
      if (component.type === 'columns') {
        component.columns.forEach(column => {
          result = {
            ...result,
            ...getAndSetFormDefinitionLabels({ data: column }),
          };
        });
      } else {
        if (component.validate && component.validate.required) {
          //Set InsuranceLableAndValue to use InsuranceValue while autopopulate insurance dropdown
          if (component.label == "Insurance") {
            setInsuranceLableAndValue(component.data.values);
          }
          result[component.key] = {
            message:
              component.validate.customMessage ??
              `${component.label} is required`,
          };
        }
      }
    });

    return result;
  };

  useEffect(() => {
    (async () => {
      let defaultLanguage = 'English';
      const locale = localStorage.getItem('i18nextLng');

      if (locale) {
        const languages = healthSystem['supported-locales'].filter(
          lang => lang.code === locale,
        );

        if (languages.length) {
          defaultLanguage = languages[0].name;
        }
      }

      const params = {
        type: 'registration',
        schedule_id: scheduleId,
        appointment_at: dateTime,
        language: defaultLanguage,
      };

      if (appointmentType && appointmentType.id) {
        params.appt_type_id = appointmentType.id;
      }

      const response = await fetch(
        getEndpoint(`custom-templates`, params),
        getHeaderInfo(),
      );
      const results = await response.json();

      if (results.data && results.data.length) {
        const { attributes } = results.data[0];

        if (attributes && attributes['form-definition']) {
          const data = JSON.parse(attributes['form-definition']);
          setFormDefinition(data);
          setRequiredObject(getAndSetFormDefinitionLabels(data));
        }
      }
    })();
  }, [healthSystem]);

  const submitForm = async e => {
    document
      .querySelector('.formio-component-submit button')
      .setAttribute('disabled', 'disabled');
    if (canSubmit) {
      setCanSubmit(false);
      setCanceled(false);
      const urlPermalink =
        localStorage.getItem('isFacilityPage') === 'true'
          ? localStorage.getItem('facilityPage')
          : getSubDomain();
      setEmailAddress(e.data.email || e.data.email1);
      await submitFormIORegistration(
        {
          ...e.data,
          questions: screeningQuestionsAnswers,
          'appointment-type': appointmentType.id,
        },
        appointmentType.id,
        scheduleId,
        scheduleLocation,
        paymentChoice,
        paymentTransactionId,
        urlPermalink,
        hasCouponCode,
        couponCode,
      );
      setFormSubmission(e);
      setCanSubmit(true);
    }

  };

  const labelStyle = { fontSize: '13px' };
  const textStyle = { fontSize: '15px', fontWeight: 700 };

  const getRequiredFormLabels = components => {
    let labels = [];

    components.forEach(component => {
      if (component.columns) {
        component.columns.forEach(column => {
          labels = [...labels, ...getRequiredFormLabels(column.components)];
        });
      }

      if (component.validate && component.validate.required) {
        labels.push(component.label);
      }
    });

    return labels;
  };

  const getKeyFromElement = elem => {
    if (elem.name && elem.name.match(/^data\[([\w-]+)\](.*?)$/)) {
      return elem.name.match(/^data\[([\w-]+)\](.*?)$/)[1];
    } else if (elem.id) {
      const list = elem.id.split('-');
      delete list[0];
      return list.join('-').substr(1);
    }

    return '';
  };

  const getErrorMessage = elem => {
    let key = '';

    if (['INPUT', 'TEXTAREA'].includes(elem.nodeName)) {
      key = getKeyFromElement(elem);
    } else {
      key = getKeyFromElement(elem.querySelector('select'));
    }

    return requiredObject[key] && requiredObject[key].message
      ? requiredObject[key].message
      : null;
  };

  // @note
  // this is a hack because, for some reason, formio validation is not changing
  // the styles on invalid form. This is to force errors to show.
  const setEventListenerOnRequiredField = node => {
    const parentContainer = node.closest('.formio-component');
    const formNode = parentContainer.querySelector(
      'textarea, .formio-choices .form-control.selection, input',
    );

    if (formNode) {
      formNode.addEventListener('blur', e => {
        const formClass = 'is-invalid';
        const parentClass = ['has-error', 'has-message'];
        const labelText = node.innerText;
        const hasValue = validateFormIO(e.target);

        if (!hasValue) {
          const errorMessage =
            getErrorMessage(e.target) ?? `${labelText} is required`;
          e.target.classList.add(formClass);
          parentContainer.classList.add(...parentClass);
          parentContainer.querySelector(
            '.formio-errors',
          ).innerHTML = `<div class="form-text error">${errorMessage}</div>`;
        } else {
          e.target.classList.remove(formClass);
          parentContainer.classList.remove(...parentClass);
        }
      });
    }
  };

  // @note
  // this is a hack because, for some reason, textareas do not show the required
  // asterisk when field is set to required. This will force the asterisk to
  // display.
  const setRequiredFields = () => {
    const requiredClassName = 'field-required';
    const labels = getRequiredFormLabels(formDefinition.data.components);
    const labelNodes = document.querySelectorAll(
      '.formio-component-form .col-form-label, .formio-component-form .form-check-label',
    );

    labelNodes.forEach(node => {
      if (
        labels.includes(node.innerText) &&
        !node.classList.contains(requiredClassName)
      ) {
        node.classList.add(requiredClassName);
      }

      if (node.classList.contains(requiredClassName)) {
        setEventListenerOnRequiredField(node);
      }
    });
  };

  // @note
  // Another hack for a couple of reasons
  // 1. Required textareas are not showing as required and does not show error message
  // 2. Drop downs are not showing the error message when there are errors
  // when submit button is clicked
  const showErrorFields = () => {
    for (const [key, obj] of Object.entries(requiredObject)) {
      const elem = document.querySelector(`.formio-component-${key}`);

      if (elem) {
        const formElem = elem.querySelector(
          'textarea, .formio-choices .form-control.selection, input',
        );
        const hasValue = validateFormIO(formElem);

        if (!hasValue) {
          elem.classList.add(
            'formio-error-wrapper',
            'has-error',
            'has-message',
          );
          elem.querySelector(
            '.formio-errors',
          ).innerHTML = `<div class="form-text error">${obj.message}</div>`;
        }
      }
    }
  };

  const hideEmptyFormioComponentNodes = () => {
    const components = document.querySelectorAll('.formio-component');

    components.forEach(component => {
      if (!component.innerHTML.trim().length) {
        component.classList.add('formio-empty-component');
      }
    });
  };

  const removeAutoCompleteInputs = () => {
    document
      .querySelectorAll(
        'div.formio-component-day, div.formio-component-month, div.formio-component-year, div.formio-component-state',
      )
      .forEach(el => {
        const element = el.querySelector(
          'input.formio-select-autocomplete-input',
        );
        if (element) {
          element.remove();
        }
      });
  };
  const translateSubmit = () => {
    const submit = document.querySelector("button[name='data[submit]']");
    if (submit && ER_SERVICE_NAMES.includes(service)) {
      submit.innerText = t('RegistrationForm.confirm');
    }
  };

  function getHeaderInfoForDocs(method = 'POST') {
    const headerInfo = {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.api+json',
    };

    return { method, headers: headerInfo };
  }
  const fixedHeaders = {
    'Content-Type': 'application/vnd.api+json',
     Authorization: `api-key ${process.env.REACT_APP_OCR_APIKEY}`,
    accept: '*/*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Request-Headers': 'authorization',
    'Access-Control-Request-Method': 'POST',
  };
  const callFormRecognizerAPI = async e => {
    if (e.changed?.component?.key == "uploadInsuranceCardFront") {
      const elem = document.querySelector(`.formio-component-uploadInsuranceCardFront`);
      //Clear values if documnet has been removed
      if (isEmpty(e.changed?.value))
        clearValues(false);
      var fileType = e.data.uploadInsuranceCardFront[0]?.originalName.split(".").pop();

      if (['pdf', 'jpeg', 'jpg', 'png', 'bmp', 'tiff'].includes(fileType?.toLowerCase())) {
        if (!isUndefined(e.data.uploadInsuranceCardFront[0].url))
          FetchFormRecognizerAPI(process.env.REACT_APP_INSURANCEOCR_URL, e, e.data.uploadInsuranceCardFront[0]?.url, false)
      }
    }
    if (e.changed?.component?.key == "uploadInsuranceCardBack") {
      var fileType = e.data.uploadInsuranceCardBack[0]?.originalName.split(".").pop();
      const elem = document.querySelector(`.formio-component-uploadInsuranceCardBack`);
      if (['pdf', 'jpeg', 'jpg', 'png', 'bmp', 'tiff'].includes(fileType?.toLowerCase())) {
        if (!isUndefined(e.data.uploadInsuranceCardBack[0].url))
          FetchFormRecognizerAPI(process.env.REACT_APP_INSURANCEOCR_URL, e, e.data.uploadInsuranceCardBack[0]?.url, false)
      }
    }

    if (e.changed?.component?.key == "uploadDriversLicenseFront") {
      //Clear values if documnet has been removed
      if (isEmpty(e.changed?.value))
        clearValues(true);
      const elem = document.querySelector(`.formio-component-uploadDriversLicenseFront`);
      var fileType = e.data.uploadDriversLicenseFront[0]?.originalName.split(".").pop();
      if (['pdf', 'jpeg', 'jpg', 'JPG', 'png', 'bmp', 'tiff'].includes(fileType?.toLowerCase())) {
        if (!isUndefined(e.data.uploadDriversLicenseFront[0].url))
          FetchFormRecognizerAPI(process.env.REACT_APP_DRIVERSLICENSEOCR_URL, e, e.data.uploadDriversLicenseFront[0]?.url, true)
      }
    }

    if (e.changed?.component?.key == "uploadDriversLicenseBack") {
      const elem = document.querySelector(`.formio-component-uploadDriversLicenseBack`);
      var fileType = e.data.uploadDriversLicenseBack[0]?.originalName.split(".").pop();
      if (['pdf', 'jpeg', 'jpg', 'png', 'bmp', 'tiff'].includes(fileType?.toLowerCase())) {
        if (!isUndefined(e.data.uploadDriversLicenseBack[0].url))
          FetchFormRecognizerAPI(process.env.REACT_APP_DRIVERSLICENSEOCR_URL, e, e.data.uploadDriversLicenseBack[0]?.url, true)
      }
    }
  }
  const clearValues = (isLicense) => {
    if (isLicense) {
      updateField('data[first-name]', "");
      updateField('data[last-name]', "");
      updateField('data[address]', "")
      updateField('data[city]', "")
      updateField('data[zip]', "")
      clearSelectCustomOption('data[state]');
      clearSelectCustomOption('data[month]');
      clearSelectCustomOption('data[day]');
      clearSelectCustomOption('data[year]');
    }
    else {
      clearSelectCustomOption('data[insurance-plan-name]')
      updateField('data[insurance-plan-full-address]', "")
      updateField('data[insurance-plan-phone-number]', "")
      updateField('data[insurance-subscriber-id]', "")
      updateField('data[insurance-member-number]', "")
      updateField('data[insurance-group-number]', "")
      updateField('data[insurance-subscriber-name]', "")
      clearSelectCustomOption('data[insuranceyear]');
      clearSelectCustomOption('data[insurancemonth]');
      clearSelectCustomOption('data[insuranceday]');
    }
  }

  const updateField = (name, value) => {
    const xpathExpression = `//input[@name="${name}"]`;
    const xpathResult = document.evaluate(
      xpathExpression,
      document,
      null,
      XPathResult.ANY_TYPE,
      null,
    );

    const element = xpathResult.iterateNext();

    if (element) {
      element.value = value;
    }
    //Added this event not to show required validation for autofill text fields
    element.dispatchEvent(new Event('input'));
  };


  const FetchFormRecognizerAPI = async (apiURL, e, base64FileContent, isLicense) => {

    var fileContent = base64FileContent.split("base64,").pop();
    const { method } = getHeaderInfoForDocs('POST');
    const payload = {
      "filename": "b",
      "fileContent": fileContent
    };
    const params = {
      method,
      headers: fixedHeaders,
      body: JSON.stringify(payload),
    };

    const response = await fetch(
      apiURL, params
    );
    const results = await response.text();
    try {
      var parsedData = JSON.parse(results);
      console.log("Parse", parsedData)
      if (isLicense) {

        if (parsedData.firstName != null && parsedData.firstName.confidence >= 0.99) {
          updateField('data[first-name]', parsedData.firstName.value);
        }
        if (parsedData.lastName != null && parsedData.lastName.confidence >= 0.99) {
          updateField('data[last-name]', parsedData.lastName.value)
        }
        if (parsedData.address != null) {
          updateField('data[address]', parsedData.address.streetAddress)
          updateField('data[city]', parsedData.address.city)
          updateField('data[zip]', parsedData.address.postalCode)
          if (parsedData.region.confidence >= 0.99)
            selectCustomOption('data[state]', parsedData.address.state);
        }

        if (parsedData.dateOfBirth?.value != null && parsedData.dateOfBirth.confidence >= 0.99) {

          //Index for month starts to "0", so adding up 1 
          var month = new Date(parsedData.dateOfBirth.value).getUTCMonth() + 1;
          var day = new Date(parsedData.dateOfBirth.value).getUTCDate();

          selectCustomOption('data[month]', AppendZeroForDropdown(month));
          selectCustomOption('data[day]', AppendZeroForDropdown(day));
          selectCustomOption('data[year]', new Date(parsedData.dateOfBirth.value).getFullYear().toString());
        }

      }

      else {
        if (parsedData.plan?.name != null && parsedData.plan.name.confidence >= 0.99) {
          insuranceLableAndValue.forEach(item => {
            if (item.label == parsedData.plan.name.value) {
              selectCustomOption('data[insurance-plan-name]', item.value)
            }
          })
        }
        if (parsedData.payer?.address != null)
          updateField('data[insurance-plan-full-address]', parsedData.payer.address)
        if (parsedData.payer?.phoneNumber != null)
          updateField('data[insurance-plan-phone-number]', parsedData.payer.phoneNumber)
        if (parsedData.idNumber?.number != null && parsedData.idNumber.number.confidence >= 0.99) {
          updateField('data[insurance-subscriber-id]', parsedData.idNumber.number.value)
          updateField('data[insurance-member-number]', parsedData.idNumber.number.value)
        }

        if (parsedData.groupNumber?.confidence != null && parsedData.groupNumber.confidence >= 0.99)
          updateField('data[insurance-group-number]', parsedData.groupNumber.value)
        if (parsedData.member?.name != null && parsedData.member.name.confidence >= 0.99)
          updateField('data[insurance-subscriber-name]', parsedData.member.name.value)

        if (parsedData.member?.birthDate != null) {
          var month = new Date(parsedData.member.birthDate).getUTCMonth() + 1;
          var day = new Date(parsedData.member.birthDate).getUTCDate();

          selectCustomOption('data[insuranceyear]', (new Date(parsedData.member.birthDate).getFullYear()).toString());
          selectCustomOption('data[insurancemonth]', AppendZeroForDropdown(month));
          selectCustomOption('data[insuranceday]', AppendZeroForDropdown(day));
        }

      }
    }
    catch (e) {
      console.log("Error doc ")
    }

  }
  function AppendZeroForDropdown(optionValue) {
    if (optionValue < 10) {
      optionValue = "0" + optionValue.toString();
    }
    else {
      optionValue = optionValue.toString();
    }
    return optionValue;
  }

  function selectCustomOption(selectName, optionValue) {
    const selectInput = document.querySelector(`select[name="${selectName}"]`);
    if (selectInput.tagName === 'SELECT') {
      const choiceItems = selectInput
        .closest('.choices')
        .querySelectorAll('.choices__item--selectable');
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < choiceItems.length; i++) {
        if (choiceItems[i].dataset.value === optionValue) {
          choiceItems[i].classList.add('is-selected'); // Add a class to visually mark as selected
          choiceItems[i].setAttribute('aria-selected', 'true'); // Set ARIA attribute for accessibility
          choiceItems[i].dispatchEvent(new Event('mousedown'));
        }
      }
    }
  }

  function clearSelectCustomOption(selectName) {
    const xpathExpression = `//*[@name="${selectName}"]/../div`;
    const xpathResult = document.evaluate(
      xpathExpression,
      document,
      null,
      XPathResult.ANY_TYPE,
      null,
    );

    const selectelement = xpathResult.iterateNext();

    if (selectelement)
      selectelement.innerHTML = "";
  }
  const handleRender = () => {
    removeAutoCompleteInputs();
    hideEmptyFormioComponentNodes();
    setRequiredFields();
    translateSubmit();
  };

  const renderCopayStripe = e => {
    const stripePromise = loadStripe(sessionKeys?.publishableKey, {
        stripeAccount: sessionKeys?.providerUserId,
    });
    return (
      <Elements stripe={stripePromise}>
        <IntakeStripeForm
            show={paymentOpen}
            toggleModal={async e => {
                setPaymentOpen(false);
                await formContext.goToConfirmationPage(scheduleId, formContext?.secureId)
            }}
            sessionKeys={sessionKeys}
            appointmentCurrency={getCurrency("USD")}
            appointmentCost={formContext?.copayInfo?.['copay-amount']?.toString()}
            handleClose={e => {
                setPaymentOpen(false);
            }}
            emailAddress={emailAddress}
            callVisit={async e => {
                await formContext.goToConfirmationPage(scheduleId, formContext?.secureId)
                setPaymentOpen(false);
            }}
            secureId={formContext?.secureId}
            secureToken={formContext?.secureToken}
            isRegForm={true}
        />
      </Elements>
    );
  };

  const callPayNow = async () => {
    const data = await createTransaction(formContext?.secureId, formContext?.secureToken)
    formContext.setShowCoPayDialog(false);
    if(data?.isSuccess){
        setSessionKeys(data?.sessionDetails);
        setPaymentOpen(true);
    }else{
      await formContext.goToConfirmationPage(scheduleId, formContext?.secureId)
    }
  };

  return (
    <>
      <style>{`
        .choices__list--dropdown .choices__item--selectable.is-selected {
          background-color: #f2f2f2;
        }
        .choices__list--dropdown .choices__item--selectable.is-selected:after {
          opacity: 0.5;
        }
        .formio-component.formio-empty-component {
          display: none;
        }
        .formio-component-htmlelement p + p {
          margin-bottom: 1rem;
        }
        .formio-component-form .formio-component-button button[type=submit]:disabled,
        .formio-component-form .formio-component-button button[disabled="disabled"],
        .formio-component-form .formio-component-button button[type=submit][disabled="disabled"] {
          background: rgba(0,0,0,0.25) !important;
          border-color: transparent !important;
          cursor: text !important;
        }
        .formio-component-form .formio-component-button button[type=submit] {
          background-color: ${branding && branding.buttonColor ? branding.buttonColor : ''
        };
          border-color: ${branding && branding.buttonColor ? branding.buttonColor : ''
        };
          color: ${branding && branding.buttonTextColor ? branding.buttonTextColor : ''
        };
          padding: 16px 48px;
        } 
        .formio-component-form .formio-component-button button[type=submit]:hover, 
        .formio-component-form .formio-component-button button[type=submit]:focus, 
        .formio-component-form .formio-component-button button[type=submit]:not(.disabled):not(.disabled):active': {
          background-color: ${branding && branding.buttonColor
          ? darken(branding.buttonColor, 0.15)
          : ''
        };
          color: ${branding && branding.buttonTextColor ? branding.buttonTextColor : ''
        };
          borderColor: ${branding && branding.buttonColor ? branding.buttonColor : ''
        };
        }
      `}</style>
      <div
        className="form-section"
        style={{ borderColor: branding.primaryColor }}
      >
        {paymentRequired && (
          <div className="form-section_row">
            <div className="label">{appointmentCostLabel}</div>
            <div className="text">
              {`Appointment fee in the amount of ${appointmentCurrency}${appointmentCost} will be charged to your credit card.`}
            </div>
          </div>
        )}
        {!isEmpty(appointmentType) && (
          <div className="form-section_row">
            <div style={labelStyle}>{appointmentTypeLabel}</div>
            <div style={textStyle}>{appointmentType.name}</div>
          </div>
        )}

        {dateTime && (
          <div className="form-section_row">
            <div style={labelStyle}>{appointmentTimeLabel}</div>
            <div style={textStyle}>
              {setAppointmentAt(dateTime, scheduleLocation.attributes.timeZone)}
            </div>
          </div>
        )}
      </div>

      {formDefinition && formDefinition.data && (
        <Form
          form={formDefinition.data}
          onSubmit={submitForm}
          onChange={callFormRecognizerAPI}
          onError={showErrorFields}
          onRender={handleRender}
          options={{
            i18next: newInstance,
          }}
          submission={formSubmission}
        />
      )}
      {formContext.form.publishableKey && renderStripe()}

      {viewAlert && !isScreeningQuestionsShowing && (
        <DialogMessage
          show={viewAlert}
          title={t('paymentModal.titlePaymentInformation')}
          message={
            paymentRequired
              ? paymentMessage === 'visit'
                ? t('paymentModal.visitInitialCostMessage')
                  .replace('{appointmentCurrency}', `${appointmentCurrency}`)
                  .replace('{appointmentCost}', `${appointmentCost}`)
                : t('paymentModal.visitProviderRequiresPaymentMessage')
                  .replace('{paymentMessage}', `${paymentMessage}`)
                  .replace('{paymentMessage}', `${paymentMessage}`)
                  .replace('{appointmentCurrency}', `${appointmentCurrency}`)
                  .replace('{appointmentCost}', `${appointmentCost}`)
              : paymentMessage === 'visit'
                ? t('paymentModal.visitInitialCostMessage')
                  .replace('{appointmentCurrency}', `${appointmentCurrency}`)
                  .replace('{appointmentCost}', `${appointmentCost}`)
                : t('paymentModal.visitPayNowORLaterMessage')
                  .replace('{appointmentCurrency}', `${appointmentCurrency}`)
                  .replace('{appointmentCost}', `${appointmentCost}`)
                  .replace('{paymentMessage}', `${paymentMessage}`)
          }
          primaryBtnText={
            !paymentRequired
              ? t('paymentModal.btnPayNow')
              : t('paymentModal.btnOK')
          }
          primaryBtnClick={e => {
            toggleViewAlert(true);
            setPaymentChoice('PAYNOW');
          }}
          secondryBtnText={
            !paymentRequired ? t('paymentModal.btnPayLater') : null
          }
          secondryBtnClick={e => {
            toggleViewAlert(false);
            setPaymentChoice('PAYLATER');
          }}
        />
      )}

      {viewCloseAlert && (
        <DialogMessage
          show={viewCloseAlert}
          title={t('paymentModal.titlePaymentInformation')}
          message={
            hsPaymentMode === 'required'
              ? t('paymentModal.visitProviderRequiresPayMessage')
                .replace('{paymentMessage}', `${paymentMessage}`)
                .replace('{paymentMessage}', `${paymentMessage}`)
              : t('paymentModal.visitContinueBookingMessage').replace(
                '{paymentMessage}',
                `${paymentMessage}`,
              )
          }
          primaryBtnText={
            hsPaymentMode === 'enabled'
              ? t('paymentModal.btnPayNow')
              : t('paymentModal.btnOK')
          }
          primaryBtnClick={e => {
            toggleCloseViewAlert(true);
            setPaymentOpen(false);
            setPaymentChoice('PAYNOW');
          }}
          secondryBtnText={
            hsPaymentMode === 'enabled' ? t('paymentModal.btnPayLater') : null
          }
          secondryBtnClick={e => {
            toggleCloseViewAlert(false);
            setPaymentChoice('PAYLATER');
            setPaymentTransactionId(null);
          }}
        />
      )}

      {formContext?.showCoPayDialog && (
        <DialogMessage
          show={formContext?.showCoPayDialog}
          title={t('paymentModal.titlePaymentInformation')}
          message={t('PatientIntake.CoPayMessage')
                  .replace('{appointmentCurrency}', `$`)
                  .replace('{appointmentCost}', `${Number(formContext?.copayInfo?.['copay-amount']).toFixed(2)}`)
          }
          primaryBtnText={t('paymentModal.btnPayNow')}
          primaryBtnClick={e => {
            callPayNow()
          }}
          secondryBtnText={t('paymentModal.btnPayLater')}
          secondryBtnClick={e => {
            formContext.declinePayment(scheduleId);
          }}
          cancelBtnClick={e => {
            formContext.declinePayment(scheduleId);
          }}
        />
      )}

      {sessionKeys?.publishableKey && renderCopayStripe()}
    </>
  );
};

FormioForm.defaultProps = {
  appointmentTimeLabel: 'Appointment Time',
  appointmentTypeLabel: 'Appointment Type',
};

FormioForm.propTypes = {
  appointmentTimeLabel: PropTypes.string,
  appointmentType: PropTypes.instanceOf(Object).isRequired,
  appointmentTypeLabel: PropTypes.string,
  handleErrors: PropTypes.func.isRequired,
  handleScreeningQuestions: PropTypes.func.isRequired,
  scheduleId: PropTypes.string.isRequired,
  scheduleLocation: PropTypes.instanceOf(Object).isRequired,
  screeningQuestionsAnswers: PropTypes.instanceOf(Array).isRequired,
  isScreeningQuestionsShowing: PropTypes.bool.isRequired,
  service: PropTypes.string,
};

export default FormioForm;
