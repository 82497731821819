import React, { useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Grid, Typography } from '@material-ui/core';
import Button from 'react-bootstrap/Button';
import { getVisit, cancel } from 'api/visitApi';
import PageNotFound from 'components/PageNotFound';
import CustomSelect from 'components/form/CustomSelect';
import { displayNotification } from 'redux/actions/uiActions';
import { BrandingContext } from 'components/contexts/BrandingContext';
import { makeStyles } from '@material-ui/core/styles';
import BreadCrumbs from '../common/BreadCrumbs';
import { getCurrency } from '../../utils/helpers';

import './VisitCancelPage.scss';
import { useTranslation } from 'react-i18next';

const VisitCancelPage = ({ match }) => {
  const history = useHistory();
  const [visit, setVisit] = useState({
    data: null,
  });

  const [location, setLocation] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [paymentInfo, setPaymentInfo] = useState({});
  const [cancelForm, setCancelForm] = useState({
    reason: {
      value: '',
    },
  });

  const { branding } = useContext(BrandingContext);
  const useStyles = makeStyles({
    button: {
      color: branding.buttonTextColor,
      borderColor: branding.buttonColor,
      backgroundColor: branding.buttonColor,
      '&:hover': {
        color: branding.buttonColor,
        borderColor: branding.buttonColor,
        backgroundColor: branding.buttonTextColor,
      },
      '&:active': {
        color: `${branding.buttonColor} !important`,
        borderColor: `${branding.buttonColor} !important`,
        backgroundColor: `${branding.buttonTextColor} !important`,
      },
      '&:focus': {
        color: `${branding.buttonColor} !important`,
        borderColor: `${branding.buttonColor} !important`,
        backgroundColor: `${branding.buttonTextColor} !important`,
      },
    },
  });
  const classes = useStyles();
  const [t] = useTranslation();
  useEffect(() => {
    (async () => {
      if (visit.data === null) {
        const v = await getVisit(match.params.visitId, {
          include: 'location,provider',
        });
        if (v.data !== null) {
          setVisit(v);
          if (v.data.attributes['payment-info'] !== null) {
            const paymentInfo = JSON.parse(v.data.attributes['payment-info']);
            setPaymentInfo(paymentInfo);
          }

          const loc = v.included.find(l => l.type === 'locations');
          setLocation(loc.attributes);
        } else {
          setVisit({ data: 'loaded' });
        }
      }
    })();
  });

  if (visit.data === null) return <></>;
  if (visit.data === 'loaded') return <PageNotFound />;

  const handleChange = e => {
    setCancelForm({ reason: { value: e.reason.value } });
  };

  const submit = async () => {
    const id = match.params.visitId;
    const params = {
      type: 'visits',
      id,
      attributes: {
        cancel_reason: cancelForm.reason.value,
      },
    };

    const { errors, data } = await cancel(id, params);

    if (!isEmpty(data)) {
      const uri = `/visits/${id}/canceled`;
      return history.push(uri);
    }
    if (!isEmpty(errors)) {
      setFormErrors(errors);
    }

    return false;
  };

  const cancelReasonOptions = [
    {
      value: "I'm feeling better",
      text: t('VisitCancelConfirmationPage.cancelresponseFeelingBetter'),
    },
    {
      value: "There's too long of a wait",
      text: t('VisitCancelConfirmationPage.cancelresponseToolongForaWait'),
    },
    {
      value: "I'm going somewhere else",
      text: t('VisitCancelConfirmationPage.cancelresponseSomewhereElse'),
    },
    {
      value: 'Cancelling to reschedule',
      text: t('VisitCancelConfirmationPage.cancelresponseToReschedule'),
    },
    {
      value: 'Other',
      text: t('VisitCancelConfirmationPage.cancelresponseOther'),
    },
  ];

  return (
    <main className="VisitCancelPage container">
      <BreadCrumbs onlyHome />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" component="h1">
            {t('VisitCancelConfirmationPage.cancelresponsePleaseCancel')}{' '}
            {location.name}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={8}>
          <CustomSelect
            fieldKey="reason"
            fieldValue={cancelForm.reason}
            label={t('VisitCancelConfirmationPage.cancelresponseLetUsKnow')}
            items={cancelReasonOptions}
            validateFormHandler={e => handleChange(e, 'reason')}
            hasError={!isEmpty(formErrors)}
            errorMessage={
              !isEmpty(formErrors)
                ? formErrors.map(e => e.detail).join(', ')
                : ''
            }
          />
        </Grid>
        {paymentInfo && paymentInfo.payment && paymentInfo.payment.status && (
          <Grid item xs={12} sm={8}>
            {paymentInfo.fee.cancellationChargeAmount === 0 ? (
              <Typography variant="body1" component="p">
                You will be refunded back{' '}
                {getCurrency(paymentInfo.fee.currency)}
                {Number(paymentInfo.payment.amount).toFixed(2)} for canceling
                the appointment
              </Typography>
            ) : (
              <Typography variant="body1" component="p">
                Cancellation fee in the amount{' '}
                {getCurrency(paymentInfo.fee.currency)}
                {Number(paymentInfo.fee.cancellationChargeAmount).toFixed(
                  2,
                )}{' '}
                will be charged and you will be refunded back{' '}
                {getCurrency(paymentInfo.fee.currency)}
                {Number(
                  paymentInfo.payment.amount -
                    paymentInfo.fee.cancellationChargeAmount,
                ).toFixed(2)}{' '}
                for canceling the appointment
              </Typography>
            )}
          </Grid>
        )}

        <Grid item xs={12}>
          <Button
            className={classes.button}
            type="button"
            onClick={() => submit()}
          >
            {t('VisitCancelPage.yesCancelMyVisit')}
          </Button>
        </Grid>
      </Grid>
    </main>
  );
};

VisitCancelPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      visitId: PropTypes.string,
    }),
  }).isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  displayNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(VisitCancelPage);
