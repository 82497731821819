import React, { useState, createContext, useMemo } from "react";

export const PatientIntakeContext = createContext();

export const PatientIntakeProvider = (props) => {
    const initialImageData = {
        FrontImage: null,
        FrontImageName: null,
        FrontImageType: null,
        ValidFrontImageSize: true,
        BackImage: null,
        BackImageName: null,
        BackImageType: null,
        ValidBackImageSize: true,
        ImagesChanged: false
    }
    const initialInsuranceData = {
        planName: "",
        permalink: "",
        otherName: "",
        address: "",
        phoneNumber: "",
        subscriberId: "",
        groupNumber: "",
        subscriberName: "",
        memberId: "",
        dataChanged: false
    }
    const initialIntakeState = {
        validation: {
            firstName: "",
            lastName: "",
            email: "",
            dob: ""
        },
        insurance: initialImageData,
        "drivers-license": initialImageData,
        other: initialImageData,
        lab: initialImageData
    }
    const [insuranceData, setInsuranceData] = useState(initialInsuranceData)
    const [page, setPage] = useState(0);
    const [secureId, setSecureId] = useState("");
    const [scheduleId, setScheduleId] = useState("");
    const [secureToken, setSecureToken] = useState("");
    const [intakeInfo, setIntakeInfo] = useState(initialIntakeState);
    const [docs, setDocs] = useState([])
    const [validImageTypes, setValidImageTypes] = useState(["png", "bmp", "jpg", "jpeg", "pdf"]);
    const [tokenExpired, setTokenExpired] = useState(false);
    const [requiredSteps, setRequiredSteps] = useState([])
    const [apptInfo, setApptInfo] = useState(null);
    const [copayInfo, setCopayInfo] = useState(null);
    const [isCoPayCollectedInRegForm, setIsCoPayCollectedInRegForm] = useState(false);
    const [showInsuranceForm, setShowInsuranceForm] = useState(false);
    const [payMethod, setPayMethod] = useState("payNow");
    const [showPaySuccessPage, setShowPaySuccessPage] = useState(false);

    const nextPage = () => {
        setPage(page + 1);
    };

    const setPageNum = (num) => {
        setPage(num);
    };

    const prevPage = () => {
        setPage(page - 1);
    };

    const handleChange = (prop) => (event) => {
        setIntakeInfo({ ...intakeInfo, [prop]: event.target.value });
    };

    const setValidationInfo = (prop, value) => {
        setIntakeInfo({
            ...intakeInfo,
            validation: { ...intakeInfo.validation, [prop]: value }
        });
    };

    const setInsuranceInfo = (prop, value) => {
        setIntakeInfo({
            ...intakeInfo,
            insurance: { ...intakeInfo.insurance, [prop]: value }
        });
    };

    const updateInsuranceInfo = (updatedInsuranceInfo, name) => {
        setIntakeInfo(prevIntakeInfo => ({
            ...prevIntakeInfo,
            [name]: { ...prevIntakeInfo[name], ...updatedInsuranceInfo }
        }));
    }

    const handleTokenExpiry = () => {
        setIntakeInfo(initialIntakeState);
        setPage(0);
        setTokenExpired(true)
    };

    const value = useMemo(
        () => ({
            page,
            nextPage,
            prevPage,
            setPageNum,
            intakeInfo,
            handleChange,
            setValidationInfo,
            setInsuranceInfo,
            secureId,
            setSecureId,
            secureToken,
            setSecureToken,
            updateInsuranceInfo,
            docs,
            setDocs,
            scheduleId,
            setScheduleId,
            validImageTypes,
            tokenExpired,
            setTokenExpired,
            handleTokenExpiry,
            apptInfo,
            setApptInfo,
            requiredSteps,
            setRequiredSteps,
            copayInfo,
            setCopayInfo,
            insuranceData,
            setInsuranceData,
            isCoPayCollectedInRegForm,
            setIsCoPayCollectedInRegForm,
            showInsuranceForm,
            setShowInsuranceForm,
            payMethod,
            setPayMethod,
            showPaySuccessPage,
            setShowPaySuccessPage
        }),
        [page,
            intakeInfo,
            nextPage,
            docs,
            requiredSteps,
            setRequiredSteps,
            copayInfo,
            insuranceData,
            isCoPayCollectedInRegForm,
            showInsuranceForm,
            payMethod
        ]
    );

    return (
        <PatientIntakeContext.Provider value={value}>
            {props.children}
        </PatientIntakeContext.Provider>
    );
};
