export const BEGIN_API_CALL = 'BEGIN_API_CALL';
export const API_CALL_ERROR = 'API_CALL_ERROR';
export const BEGIN_API_AUTH = 'BEGIN_API_AUTH';
export const API_AUTH_ERROR = 'API_AUTH_ERROR';
export const API_AUTH_SUCCESS = 'API_AUTH_SUCCESS';

export const FETCH_AUTH_TOKEN_SUCCESS = 'FETCH_AUTH_TOKEN_SUCCESS';
export const FETCH_AUTH_TOKEN_FAILED = 'FETCH_AUTH_TOKEN_FAILED';

export const BEGIN_FETCH_APPOINTMENT_TYPES = 'BEGIN_FETCH_APPOINTMENT_TYPES';
export const FETCH_APPOINTMENT_TYPES_SUCCESS =
  'FETCH_APPOINTMENT_TYPES_SUCCESS';
export const FETCH_APPOINTMENT_TYPES_FAILED = 'FETCH_APPOINTMENT_TYPES_FAILED';

export const FETCH_GEOCODING_SUCCESS = 'FETCH_GEOCODING_SUCCESS';
export const FETCH_GEOCODING_FAILED = 'FETCH_GEOCODING_FAILED';
export const FETCH_REVERSE_GEOCODING_SUCCESS =
  'FETCH_REVERSE_GEOCODING_SUCCESS';
export const FETCH_REVERSE_GEOCODING_FAILED = 'FETCH_REVERSE_GEOCODING_FAILED';
export const CLEAR_GEOCODING = 'CLEAR_GEOCODING';

export const BEGIN_FETCH_PROVIDER = 'BEGIN_FETCH_PROVIDER';
export const PROVIDER_NOT_FOUND = 'PROVIDER_NOT_FOUND';
export const FETCH_PROVIDER_SUCCESS = 'FETCH_PROVIDER_SUCCESS';
export const FETCH_PROVIDER_FAILED = 'FETCH_PROVIDER_FAILED';
export const FETCH_PROVIDERS_SUCCESS = 'FETCH_PROVIDERS_SUCCESS';
export const FETCH_PROVIDERS_FAILED = 'FETCH_PROVIDERS_FAILED';

export const CLEAR_VISIT = 'CLEAR_VISIT';
export const FETCH_VISIT_SUCCESS = 'FETCH_VISIT_SUCCESS';
export const FETCH_HEALTH_SYSTEM_SUCCESS = 'FETCH_HEALTH_SYSTEM_SUCCESS';
export const FETCH_HEALTH_SYSTEM_FAILED = 'FETCH_HEALTH_SYSTEM_FAILED';

export const FETCH_INSURANCE_PLANS_SUCCESS = 'FETCH_INSURANCE_PLANS_SUCCESS';
export const FETCH_INSURANCE_PLANS_FAILED = 'FETCH_INSURANCE_PLANS_FAILED';

export const FETCH_MEDICAL_GROUPS_SUCCESS = 'FETCH_MEDICAL_GROUPS_SUCCESS';
export const FETCH_MEDICAL_GROUPS_FAILED = 'FETCH_MEDICAL_GROUPS_FAILED';

export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS';
export const FETCH_SERVICES_FAILED = 'FETCH_SERVICES_FAILED';

export const BEGIN_FETCH_SERVICE_LINES = 'BEGIN_FETCH_SERVICE_LINES';
export const FETCH_SERVICE_LINES_SUCCESS = 'FETCH_SERVICE_LINES_SUCCESS';
export const FETCH_SERVICE_LINES_FAILED = 'FETCH_SERVICE_LINES_FAILED';

export const FETCH_DISCHARGE_CALLED = 'FETCH_DISCHARGE_CALLED';
export const FETCH_DISCHARGE_SERVICE_LINES_SUCCESS =
  'FETCH_DISCHARGE_SERVICE_LINES_SUCCESS';

// Search related
export const BEGIN_SEARCH = 'BEGIN_SEARCH';
export const END_SEARCH = 'END_SEARCH';
export const CHANGE_SEARCH_TYPE = 'CHANGE_SEARCH_TYPE';
export const FETCH_SEARCH_PROVIDERS_SUCCESS = 'FETCH_SEARCH_PROVIDERS_SUCCESS';
export const FETCH_SEARCH_PROVIDERS_FAILED = 'FETCH_SEARCH_PROVIDERS_FAILED';
export const FETCH_SEARCH_SCHEDULES_SUCCESS = 'FETCH_SEARCH_SCHEDULES_SUCCESS';
export const FETCH_SEARCH_SCHEDULES_FAILED = 'FETCH_SEARCH_SCHEDULES_FAILED';
export const FETCH_AVAILABLE_TIMES_SUCCESS = 'FETCH_AVAILABLE_TIMES_SUCCESS';
export const FETCH_AVAILABLE_TIMES_FAILED = 'FETCH_AVAILABLE_TIMES_FAILED';
export const UPDATE_SEARCH_RESULT = 'UPDATE_SEARCH_RESULT';
export const UPDATE_SEARCH_FILTERS = 'UPDATE_SEARCH_FILTERS';
export const UPDATE_SEARCH_OPTIONS = 'UPDATE_SEARCH_OPTIONS';
export const UPDATE_APPOINTMENT_TYPE = 'UPDATE_APPOINTMENT_TYPE';

// Search filter related
export const FETCH_DOCTOR_SPECIALITY_SUCCESS =
  'FETCH_DOCTOR_SPECIALITY_SUCCESS';
export const FETCH_DOCTOR_SPECIALITY_FAILED = 'FETCH_DOCTOR_SPECIALITY_FAILED';
export const FILTERED_SEARCH_RESULT = 'FILTERED_SEARCH_RESULT';
export const BACK_TO_LANDING_PAGE = 'BACK_TO_LANDING_PAGE';
export const RESET_FILTERS = 'RESET_FILTERS';
export const RESET_SEARCH_RESULT = 'RESET_SEARCH_RESULT';

export const BEGIN_FETCH_VENUES_OF_CARE = 'BEGIN_FETCH_VENUES_OF_CARE';
export const FETCH_VENUES_OF_CARE_SUCCESS = 'FETCH_VENUES_OF_CARE_SUCCESS';
export const FETCH_VENUES_OF_CARE_FAILED = 'FETCH_VENUES_OF_CARE_FAILED';

export const CLEAR_VISIT_SETTINGS = 'CLEAR_VISIT_SETTINGS';
export const BEGIN_FETCH_VISIT_SETTINGS = 'BEGIN_FETCH_VISIT_SETTINGS';
export const FETCH_VISIT_SETTINGS_SUCCESS = 'FETCH_VISIT_SETTINGS_SUCCESS';
export const FETCH_VISIT_SETTINGS_FAILED = 'FETCH_VISIT_SETTINGS_FAILED';

export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const DISPLAY_NOTIFICATION = 'DISPLAY_NOTIFICATION';

export const SET_PROVIDER_SHOW_FORM = 'SET_PROVIDER_SHOW_FORM';
export const SET_PHYSICIAN_SHOW_FORM = 'SET_PHYSICIAN_SHOW_FORM';

export const ADD_HISTORY_LOCATION = 'ADD_HISTORY_LOCATION';
export const CLEAR_HISTORY = 'CLEAR_HISTORY';
export const UPDATE_LOCATION_TITLE = 'UPDATE_LOCATION_TITLE';

export const SET_SETTINGS = 'SET_SETTINGS';
