import { Box, Grid, Typography, Button } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import { ArrowForward, ArrowBack } from "@material-ui/icons";
import { ReactComponent as Preloader } from 'assets/images/icons/preloader.svg';
import BrandingButton from "components/common/BrandingButton";
import { PatientIntakeContext } from "../contexts/PatientIntakeContext";
import "./PatientIntakePage.scss";
import InsuranceUpload from "./InsuranceUpload";
import { getDocuments, fetchDocument, uploadDocuments } from "api/patientIntakeApi";
import AppointmentInfoModal from "./AppointmentInfoModal";
import IntakeHeader from "./IntakeHeader";

const DocsPage = ({name}) => {

    const [t] = useTranslation();
    const intake = useContext(PatientIntakeContext);

    const licenseData = intake?.intakeInfo[name];
    const [isUploading, setIsUploading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showApptInfoModal, setShowApptInfoModal] = useState(false);

    const useStyles = makeStyles((theme) => ({

        uploadButton: {
            paddingInline: "18px",
            float: "right",
            borderRadius: "8px",
            opacity: errorMessage || loading ? 0.6 : 1,
            pointerEvents: errorMessage || loading ? "none" : ""
        },
        arrowBtn: {
            paddingLeft: "5px"
        },
        boxStyle: {
            bgcolor: '#F9F9F9',
            width: '100%',
            aspectRatio: '16/5',
            borderRadius: 3,
            display: 'inline-flex',
            alignItems: "center"
        },
        uploadSections: {
            position: "relative"
        },
        uploadingStyle:{
            borderRadius:1,
            position : "absolute !important",
            left:"0px", right: 1, 
            top:"0px",bottom:'0px',
            backgroundColor: "#969696",
            zIndex:'999',
            bgcolor:'#969696',
            opacity:0.8,
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            gap:1
        },
        errorContainer:{
            borderRadius: "8px !important", 
            backgroundColor:"#EB5757 !important", 
            paddingInline:"10px"
        },
        errorStyle:{
            color:"#FFFFFF", 
            fontSize:"12px !important"
        },
        backButton: {
            paddingInline: "18px",
            textTransform: "none !important",
        },
        backArrowBtn:{
            paddingRight: "5px"
        },
        grid: {
            display: "flex"
        },
    }));

    const classes = useStyles();

    const fetchDocuments = async () => {
            const data = await getDocuments(intake?.secureId, intake?.secureToken); 
            if(data === "PatientIntake.TokenExpired"){
                intake.handleTokenExpiry()
                return;
            }
            if(data?.patientIntakeDocuments?.length > 0){
                intake.setDocs(data?.patientIntakeDocuments);
            }      
    };

    const getDocument = async (doc, side) => {
         await fetchDocument(intake?.secureId, intake?.secureToken, doc?.documentFetchId).then((res)=>{
            if(res === "PatientIntake.TokenExpired"){
                intake.handleTokenExpiry()
                return;
            }
            if(res?.success){
                let fileType = res?.data?.filename?.split(".")?.pop();
                intake.updateInsuranceInfo({[`${side}Image`]: res?.data?.fileData, [`${side}ImageName`]: res?.data?.filename, [`${side}ImageType`]: fileType}, name);
            }
        }).finally(()=>setLoading(false));
    };


    useEffect(() => {
        const getImageData = async () => {
            const frontImageDoc = intake?.docs?.find(doc=> doc?.documentOrientation == ('front' || "none" ) && doc?.documentTypeCode === name)
            if(frontImageDoc){
            setLoading(true)
                await getDocument(frontImageDoc, "Front")
            } 

            const backImageDoc = intake?.docs?.find(doc=> doc?.documentOrientation == ("back" || "none" ) && doc?.documentTypeCode === name)
            if(backImageDoc){
            setLoading(true)
                await getDocument(backImageDoc, "Back")
            } 
        }

        if(intake?.secureToken && !licenseData?.FrontImageName && !licenseData?.BackImageName) getImageData();

      }, [intake?.docs]);

    useEffect(() => {
        if(intake?.secureToken && !intake?.docs) fetchDocuments();      
    }, []);

    const handleClickNext = async () => {
        if((!licenseData?.FrontImageName && !licenseData?.BackImageName) || !licenseData?.ImagesChanged){
            intake.nextPage();
            return;
        }
        if((licenseData?.[`FrontImageType`] && !intake?.validImageTypes?.includes(licenseData?.[`FrontImageType`])) || (licenseData?.[`BackImageType`] && !intake?.validImageTypes?.includes(licenseData?.[`BackImageType`]))){
            setErrorMessage(t(`PatientIntake.InvalidFileTypeError`));
            return;
        }
        if(!licenseData?.ValidFrontImageSize || !licenseData?.ValidBackImageSize){
            setErrorMessage(t(`PatientIntake.BigInsuranceDocSize`));
            return;
        }

        setIsUploading(true); 
        setLoading(true);
        const frontImageObj =   {
            "filename": licenseData?.FrontImageName,
            "document_type": name,
            "document_content": licenseData?.FrontImage,
            "page": 1
        }
        
        const backImageObj =   {
            "filename": licenseData?.BackImageName,
            "document_type": name,
            "document_content": licenseData?.BackImage, 
            "page": 2
        }

        const formData = [];

        if(licenseData?.FrontImageName && !licenseData?.BackImageName){
            formData[0] = frontImageObj;
        }

        if(!licenseData?.FrontImageName && licenseData?.BackImageName){
            formData[0] = backImageObj;
        }

        if(licenseData?.FrontImageName && licenseData?.BackImageName){
            formData[0] = frontImageObj;
            formData[1] = backImageObj;
        }

         try {
            const data = await uploadDocuments(intake?.secureId, intake?.secureToken, formData)
            if(data === "PatientIntake.TokenExpired"){
                intake.handleTokenExpiry()
                setIsUploading(false); 
                setLoading(false)
                return;
            }else
            if(!data?.hasErrors){
                setIsUploading(false); 
                setLoading(false)
                intake.updateInsuranceInfo({ImagesChanged: false}, name);
                intake.nextPage();
            }else{
                setIsUploading(false); 
                setLoading(false)
                setErrorMessage(t('PatientIntake.ScanError'))
            }
          } catch (e) {
            console.log(e);
          } 
    }

    const handleClickBack = () => {
        intake.prevPage();
    }

    useEffect(()=>{
        setErrorMessage(errorMessage)
    }, [errorMessage])

    useEffect(()=>{
        if(name === "drivers-license" && !loading && ((!licenseData?.FrontImageName && licenseData?.BackImageName) || (licenseData?.FrontImageName && !licenseData?.BackImageName))){
            setErrorMessage(t('PatientIntake.OneDocErrorMsg'))
        }else{
            setErrorMessage("")
        }
    }, [licenseData?.FrontImageName, licenseData?.BackImageName])
    
    return (
        <Grid container alignItems="center" justifyContent="center">
            <Box
                component="div"
                sx={{ flexGrow: 1, marginTop: 3 }}
            >
                <Grid container alignItems="center">
                    <IntakeHeader pageName={intake?.requiredSteps?.find(item => item?.documentTypeCode === name )?.documentTypeName} setShowApptInfoModal={setShowApptInfoModal} />
                    <Grid container justifyContent={"center"} alignItems="center" >
                            <Grid item xs={12} >
                                <div className={classes.boxStyle} >
                                    <Grid container rowSpacing={2} justifyContent={"center"} alignItems="center" >
                                        <Grid item xs={10} md={6} className={classes.uploadSections}>
                                            {loading && 
                                                <Box component={"div"} className={classes.uploadingStyle} >
                                                        <Preloader className="rotateLoader"/>
                                                        {isUploading && <Typography sx={{color:'#fff', paddingInline:3}}>{t('PatientIntake.Uploading')}....</Typography>}
                                                </Box>
                                            }
                                            <Grid container spacing={2} justifyContent={"center"} alignItems="center" >
                                                <InsuranceUpload side="Front" setError ={setErrorMessage} page={name} />
                                                <InsuranceUpload side="Back" setError ={setErrorMessage} page={name}/>
                                            {errorMessage &&
                                                <Grid item xs={12} >
                                                    <Box component={"div"} className={classes.errorContainer}>
                                                        <Typography className={classes.errorStyle}>{errorMessage}</Typography>                                      
                                                    </Box>
                                                </Grid>
                                            }
                                            </Grid>
                                        </Grid>
                                       
                                    </Grid>
                                </div>
                                <Grid item xs={11} marginY={4} justifyContent={"space-between"} className={classes.grid}>
                                    <Button
                                        type="button"
                                        className={classes.backButton}
                                        variant="outlined"
                                        onClick={handleClickBack}
                                    >
                                        <ArrowBack fontSize="small" className={classes.backArrowBtn}  /> {t('PatientIntake.Back')}
                                    </Button>
                                    <BrandingButton
                                            type="button"
                                            className={classes.uploadButton}
                                            onClick={handleClickNext}
                                        >          
                                            {t('PatientIntake.Next')}<ArrowForward fontSize="small" className={classes.arrowBtn} />
                                    </BrandingButton>
                                </Grid>
                            </Grid>
                    </Grid> 
                </Grid>
            </Box>
            <AppointmentInfoModal
                show={showApptInfoModal}
                onClose={() => setShowApptInfoModal(false)}
            />
        </Grid>
    )
}

export default DocsPage;