import { loadGeocoding } from 'redux/actions/geocodingActions';
import { getHeaderInfo, getMcaUrl } from './apiUtils';

const handleCreateResponse = async response => {
  const { status } = response;
  const jsonData = response.json();
  if (response.ok) return jsonData;
  if (status === 400 || status === 422) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message,
    // so parse as text instead of json.

    return jsonData;
  }
  throw new Error('Network response was not ok.');
};

export const createFacilityEvent = async (paramsValue, venuOfCare, appointmentTypes, service_id) => {
  const { method } = getHeaderInfo('POST');
  const fixedHeaders = {
    'Content-Type': 'application/vnd.api+json',
    accept: '*/*',
    Origin: window.location.hostname,
    Referer: window.location.hostname,
    'Access-Control-Request-Headers': 'authorization',
    'Access-Control-Request-Method': 'POST',
  };
  const date = new Date();

  const payload = {
    requestParameters: {
      params: {
        filter: {
          venue_of_care:
            typeof venuOfCare === 'string'
              ? venuOfCare
              : venuOfCare.attributes.venueType.split('_').join('-'),
          appointment_type: appointmentTypes,
          service_id: [service_id],
        },
        action: paramsValue.searchType,
      },
      session: sessionStorage.getItem('sessionId'),
      health_system_id: localStorage.getItem('healthSystemId'),
      event_timestamp: date.toISOString(),
    },
  };
  //for testing in QA-test leaving this consolelog remove it later
  console.log(payload);
  const params = {
    method,
    headers: fixedHeaders,
    body: JSON.stringify(payload),
  };
  const urlPath = `${getMcaUrl()}/api/app/event`;
  return fetch(urlPath, params)
    .then(handleCreateResponse)
    .catch(e => {
      console.error(`API call failed. ${e}`);
    });
};

export const createEvent = async (paramsValue, venuOfCare, resultCount) => {
  const { method } = getHeaderInfo('POST');
  const fixedHeaders = {
    'Content-Type': 'application/vnd.api+json',
    accept: '*/*',
    Origin: window.location.hostname,
    Referer: window.location.hostname,
    'Access-Control-Request-Headers': 'authorization',
    'Access-Control-Request-Method': 'POST',
  };
  const date = new Date();

  const payload = {
    requestParameters: {
      params: {
        filter: {
          venue_of_care:
            typeof venuOfCare === 'string'
              ? venuOfCare
              : venuOfCare.attributes.venueType.split('_').join('-'),
          near: paramsValue.near
            ? paramsValue.near
            : loadGeocoding(paramsValue.zip),
          appointment_type: paramsValue.appointmentTypes,
          insurance: paramsValue.insurancePlans,
          accepting_new_patients: paramsValue.accepting_new_patients,
          gender: paramsValue.gender,
          speciality: localStorage.getItem('speciality'),
        },
        action: paramsValue.searchType,
      },
      record_count: resultCount,
      session: sessionStorage.getItem('sessionId'),
      health_system_id: localStorage.getItem('healthSystemId'),
      zip_code: paramsValue.zip,
      event_timestamp: date.toISOString(),
    },
  };
  const params = {
    method,
    headers: fixedHeaders,
    body: JSON.stringify(payload),
  };
  const urlPath = `${getMcaUrl()}/api/app/event`;
  return fetch(urlPath, params)
    .then(handleCreateResponse)
    .catch(e => {
      console.error(`API call failed. ${e}`);
    });
};
