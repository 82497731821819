import React, { useContext, useCallback, useState } from 'react';
import { isEmpty } from 'lodash';
import serviceLineGroupMapping from '../config/serviceLineGroupMappings';
import ProviderAvatar from 'components/provider/ProviderAvatar';
import ProviderAvatarServiceLine from 'components/provider/ProviderAvatarServiceLine';
import { serviceLineIcons } from '../config/serviceLineIcons';

export function setProviderAvatar(provider, location, service, hs, serviceName, facility) {
  let altTag = "[[provider-name]], [[suffix]] - [[service-name]] with [[hs-name]]";
  const correction = {
    "[[provider-name]]": provider?.attributes?.name,
    ", [[suffix]]": provider?.attributes?.suffix === '' || provider?.attributes?.suffix === null ? '' : `, ${provider?.attributes?.suffix}`,
    "[[service-name]]": serviceName ? serviceName : '',
    "[[hs-name]]": hs?.region?.name ? hs?.region?.name : hs?.name ? hs?.name : ''
  };

  const replacedAltTag = () => {
    Object.keys(correction).forEach((key) => {
      altTag = altTag.replace(key, correction[key]);
    });
    return altTag;
  }
  if (provider?.attributes['ui-profile-image'] !== null && provider?.attributes['ui-profile-image'] !== undefined) {
    return <ProviderAvatar alt={replacedAltTag()} src={provider.attributes['ui-profile-image']} />;
  }
  else if (provider?.attributes['uiProfileImage'] !== null && provider?.attributes['uiProfileImage'] !== undefined) {
    return <ProviderAvatar alt={replacedAltTag()} src={provider.attributes['uiProfileImage']} />;
  }
  else if (location !== undefined && location['logo'] !== null && location['logo'] !== undefined) {
    return <ProviderAvatar alt={replacedAltTag()} src={location['logo']} />;
  }
  else if (facility !== null && facility !== undefined && facility['logo-image'] !== null && facility['logo-image'] !== undefined) {
    return <ProviderAvatar alt={replacedAltTag()} src={facility['logo-image']} />;
  }
  else if (service !== undefined) {
    if (!isEmpty(serviceLineIcons.find(i => i.permalink === service.replace('primary-care', 'find-a-doctor')))) {
      return <ProviderAvatarServiceLine service={service} alt={replacedAltTag()} src={serviceLineIcons.find(i => i.permalink === service.replace('primary-care', 'find-a-doctor')).icon} />;
    }
    else return <ProviderAvatarServiceLine service={service} alt={replacedAltTag()} src={serviceLineIcons.find(i => i.permalink === 'no-image').icon} />;
  }
  else return <ProviderAvatarServiceLine service={service} alt={replacedAltTag()} src={serviceLineIcons.find(i => i.permalink === 'no-image').icon} />;
}
